import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import OwlSkeletons from "./owlSkeletons";
import Skeleton from "react-loading-skeleton";
import BrandsSkeletons from "./brandsSkeletons";

export default function Skeletons({ forPage }) {
  const CustomSkeleton = ({ height, width, className, style, circle }) => {
    return (
      <Skeleton
        height={height}
        width={width}
        className={className}
        style={style}
        circle={circle}
      />
    );
  };

  const render = () => {
    switch (forPage) {
      case "owlItems":
        return <OwlSkeletons CustomSkeleton={CustomSkeleton} />;
      case "brand-icons":
        return <BrandsSkeletons CustomSkeleton={CustomSkeleton} />;
    }
  };
  return <>{render()}</>;
}
