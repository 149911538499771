import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { homeOptions, homeSelect } from "../../../data/content";
import { NavLink } from "react-router-dom";
import ListItem from "../ListItem";
import OwlItem from "../OwlItem";
import { useSelector } from "react-redux";
import OwlItemCars from "../OwlItem/owlItemCars";
import { paths } from "../../../utils/paths";
import Skeletons from "../Skeletons";

export default function SelectViewCar({
  rtl,
  responce,
  responceList,
  title,
  path,
  selectBrand,
  handleClick,
  selectedCarType,
  page,
  adsArray,
}) {
  const loading = useSelector((state) => state.searched.loading);
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const { t, i18n } = useTranslation();
  const styles = { display: "none" };

  const getButtonText = () => {
    if (!selectedCarType) return t("View all cars");
    const name = selectedCarType?.name?.en;
    return t(`View all ${name} cars`);
  };
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200, // Scroll left by 100 pixels
        behavior: "smooth", // Add smooth scrolling
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200, // Scroll right by 100 pixels
        behavior: "smooth", // Add smooth scrolling
      });
    }
  };
  return (
    <section class="recently_viewed most_searched">
      <div class="container">
        <h2 class="hed">{title}</h2>
        <div
          className={`owl-nav d-none d-md-flex justify-content-between w-100 bg-transparent gap-2  m-0 ${
            lang !== "en" ? "flex-row-reverse" : ""
          }`}
          style={{ zIndex: 9, position: "relative", bottom: "-85px" }}
        >
          <div className="bg-light rounded-1 d-flex justify-content-between align-items-center">
            <button
              onClick={scrollLeft}
              className="owl-prev"
              style={{ margin: "unset !important", left: '-100px' }}
            ></button>
          </div>
          <div className="bg-light rounded-1 d-flex justify-content-between align-items-center">
            <button
              onClick={scrollRight}
              className="owl-next"
              style={{ margin: "unset !important", right: '-100px' }}
            ></button>
          </div>
        </div>
        <div className="">
          {" "}
          <ul
            ref={scrollContainerRef}
            style={{ flexWrap: "unset", overflowX: "scroll" }}
            class="nav nav-tabs slides d-flex justify-content-start align-items-center searched-section-margin"
            id="myTab"
            role="tablist"
          >
            {responce?.map?.((item, key) => {
              return (
                <ListItem
                  key={key}
                  innerhtmlType={"button"}
                  buttonId={"btn-" + item?.id}
                  style={"nav-item mx-2"}
                  inlineStyle={{
                    // flexWrap: 'unset'
                    width: "200px",
                    display: "inline-block",
                  }}
                  onClick={() => selectBrand("#btn-", item)}
                  buttonStyle={"nav-link sl w-100"}
                  buttonTitle={
                    <div>
                      <img src={item?.image} alt="img" />
                      <span>{item?.name?.[lang]}</span>
                    </div>
                  }
                ></ListItem>
              );
            })}
          </ul>
        </div>
        {/* <OwlCarousel
          className="nav nav-tabs px-0 owl-carousel hat_carousel owl.carousel.min owl-theme owl-loaded owl-drag"
          id="myTab"
          role="tablist"
          // style={styles}
          {...{
            // loop: true,
            // autoplay: true,
            // autoplayTimeout: 3000,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: { items: 3, nav: true },
              576: { items: 3, nav: true },
              768: { items: 4, nav: true },
              992: { items: 6, nav: true },
              1200: { items: 9, nav: true },
            },
          }}
          rtl={rtl}
          margin={10}
        >
          {responce?.map?.((item, key) => {
            return (
              <ListItem
                key={key}
                innerhtmlType={"button"}
                buttonId={"btn-" + item?.id}
                style={"nav-item mx-2"}
                inlineStyle={{
                  // flexWrap: 'unset'
                  width: "100%",
                  display: "inline-block",
                }}
                onClick={() => selectBrand("#btn-", item)}
                buttonStyle={"nav-link sl w-100"}
                buttonTitle={
                  <div>
                    <img src={item?.image} alt="img" />
                    <span>{item?.name?.[lang]}</span>
                  </div>
                }
              ></ListItem>
            );
          })}
        </OwlCarousel> */}
        {!loading > 0 ? (
          <>
            {responceList && responceList.length > 0 ? (
              <>
                <OwlCarousel
                  className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${
                    rtl && "invertButtons"
                  }`}
                  {...homeOptions}
                  rtl={rtl}
                  // margin={10}
                  nav
                >
                  <OwlItemCars
                    cars={responceList}
                    rentType={"daily"}
                    // filters={{ bodyType: [selectedCarType?.name?.en || ""] }}
                    adsArray={adsArray}
                  />
                </OwlCarousel>
                <div class="w-100 float-left text-center">
                  <NavLink
                    className={"viewall"}
                    to={page === "normal" ? paths.buyCar : paths.rentCar}
                    state={{
                      filters: {
                        bodyType: selectedCarType?.name?.["en"]
                          ? [selectedCarType?.name?.["en"]]
                          : "",
                      },
                    }}
                  >
                    {getButtonText()}
                  </NavLink>
                </div>
              </>
            ) : (
              <>
                <p className="fs-5 text-center" style={{ maxWidth: "unset" }}>
                  {t("No cars of this type available")}
                </p>
              </>
            )}
          </>
        ) : (
          <>
            <Skeletons forPage={"owlItems"} />
          </>
        )}
      </div>
    </section>
  );
}
