import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  BenefitsUsedData,
  StockSectionData,
  WhyUsedCarsData,
} from "./data";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PageSection from "../../Tools/PageSection";
import { Icon } from "@iconify/react/dist/iconify.js";
import Stock from "../NewCars/Stock";
import Feature from "../CheapCars/Feature";
import ContentSlider from "../../Tools/ContentSlider";
import FeaturesSection from "../../Tools/FeaturesSection";
import { buySellRent } from "../ElectricCars/data";
import { homeOptions } from "../../../data/content";

import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";

export function WhyUsedCars() {
  // return <Stock content={whyRentData} />;
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  return (
    <PageSection containerClasses={"account-card p-md-4 rounded-2"}>
      <ContentSlider
        content={WhyUsedCarsData?.[lang]}
        renderChild={(item) => {
          return <FeaturesSection content={item} />;
        }}
      />
    </PageSection>
  );
}

export const StockSection = () => {
  return <Stock content={StockSectionData} />;
};

export const BenefitsUsed = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = BenefitsUsedData?.[lang] || [];
  const title = BenefitsUsedData?.title?.[lang] || [];
  const rtl = lang !== "en";
  return <InfoCardsCarousel data={data} title={title} />;
};
