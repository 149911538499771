export const GuideToSellData = {
  title: {
    en: "Guides to selling your car on Ako Sayara",
    ar: "دليل لبيع سيارتك على اكو سيارة",
    ku: "ەی پرۆسەی فرۆشتنی ئۆتۆمبێلەکەت بە ئاسانی و سەرکەوتوو بێت",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/guide-to-sell/right-price.svg",
      icon: "marketeq:work",
      title: "Set the right price",
      description: `Research similar car listings on Ako Sayara to determine a competitive asking price.`,
    },
    {
      id: "2",
      image: "/assets/images/guide-to-sell/highlight.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "Highlight unique features",
      description: `Mention any upgrades, new parts, or unique features that add value to your car.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/clean.svg",
      icon: "marketeq:reward",
      title: "Keep your car clean",
      description: `A clean and well-maintained car attracts more buyers and fetches better offers.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/honest.svg",
      icon: "marketeq:reward",
      title: "Be honest",
      description: `Accurately describe your car’s condition to avoid misunderstandings with buyers.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/promote.svg",
      icon: "marketeq:reward",
      title: "Promote your listing",
      description: `Opt for paid promotional tools on Ako Sayara to ensure your ad reaches more people.`,
    },
  ],
  ar: [
    {
      id: "1",
      image: "/assets/images/guide-to-sell/right-price.svg",
      icon: "marketeq:work",
      title: "حدد السعر المناسب",
      description: `ابحث عن إعلانات السيارات المشابهة على اكو سيارة لتحديد سعر منافس.`,
    },
    {
      id: "2",
      image: "/assets/images/guide-to-sell/highlight.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "أبرز المميزات الفريدة",
      description: `اذكر أي تعديلات أو قطع غيار جديدة أو مواصفات مميزة تضيف قيمة لسيارتك.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/clean.svg",
      icon: "marketeq:reward",
      title: "حافظ على نظافة سيارتك",
      description: `السيارة النظيفة والمعتنى بها تجذب المزيد من المشترين وتحصل على عروض أفضل.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/honest.svg",
      icon: "marketeq:reward",
      title: "كن صادقًا",
      description: `صف حالة سيارتك بدقة لتجنب أي سوء فهم مع المشترين.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/promote.svg",
      icon: "marketeq:reward",
      title: "روّج لإعلانك",
      description: `اختر الأدوات الترويجية المدفوعة على اكو سيارة لضمان وصول إعلانك إلى عدد أكبر من الناس`,
    },
  ],
  ku: [
    {
      id: "1",
      image: "/assets/images/guide-to-sell/right-price.svg",
      icon: "marketeq:work",
      title: "نرخی گونجاو دیاری بکە",
      description: `بەدوای ڕیکلامی هاوشێوەی ئۆتۆمبێلدا بگەڕێ لە ئەکو سەیارە بۆ دیاریکردنی نرخێکی کێبڕکێ.`,
    },
    {
      id: "2",
      image: "/assets/images/guide-to-sell/highlight.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "زۆربەی تایبەتمەندییە ناوازەکان",
      description: `باسی هەر گۆڕانکارییەک، پارچەی یەدەگی نوێ، یان تایبەتمەندییەکی جیاواز بکە کە بەها بۆ ئۆتۆمبێلەکەت زیاد دەکات.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/clean.svg",
      icon: "marketeq:reward",
      title: "ئۆتۆمبێلەکەت پاک و خاوێنی ڕابگرە",
      description: `ئۆتۆمبێلێکی پاک و باش پارێزراو کڕیاری زیاتر بۆ خۆی ڕادەکێشێت و ئۆفەری باشتری دەست دەکەوێت.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/honest.svg",
      icon: "marketeq:reward",
      title: "ڕاستگۆبە",
      description: `بە وردی باسی بارودۆخی ئۆتۆمبێلەکەت بکە بۆ ئەوەی هیچ لێکتێگەیشتنێکی هەڵە لەگەڵ کڕیاران دوور بکەویتەوە.`,
    },
    {
      id: "3",
      image: "/assets/images/guide-to-sell/promote.svg",
      icon: "marketeq:reward",
      title: "بانگەشە بۆ ڕێکلامەکەت بکە",
      description: `ئامرازەکانی بانگەشەکردنی پارەدار لە ئیکۆ کار هەڵبژێرە بۆ ئەوەی دڵنیا بیت لەوەی کە ڕێکلامەکەت دەگاتە دەستی خەڵکی زیاتر.`,
    },
  ],
};
