export const rentPageData = {
  en: [
    {
      title: "Why renting a car in Iraq is the best choice",
      description:
        "Renting a car offers several advantages that make it a preferred option over public transportation or taxis:",
      image: "/assets/images/why-rent-1.png",
      features: [
        {
          title: "Flexibility and convenience",
          description:
            "Renting a car gives you complete control over your travel schedule. You can move freely between cities and explore off-the-beaten-path locations without worrying about public transport schedules.",
        },
        {
          title: "Comfort for travelers",
          description:
            "Whether traveling solo, with family, or in a group, a rented car ensures privacy and comfort throughout your journey.",
        },
        {
          title: "Cost effectiveness",
          description:
            "For extended stays or frequent travel, renting a car is often more economical than relying on taxis or ride-sharing services.",
        },
        {
          title: "Access to diverse locations",
          description:
            "Iraq’s stunning countryside, cultural landmarks, and modern cities are best explored by car. With a rental, you can visit places like the Citadel of Erbil, the marshes of Basra, and Mosul's ancient ruins at your own pace.",
        },
      ],
    },
    {
      title: "Why choose Ako Sayara for car rentals in Iraq?",
      description:
        "Ako Sayara is a trusted name in Iraq’s automotive industry, offering car rental, buying, and selling services. Here’s why it stands out as the ideal platform for car rentals:",
      image: "/assets/images/why-rent-2.png",
      features: [
        {
          title: "Wide range of vehicles",
          description:
            "Ako Sayara provides a diverse selection of vehicles to suit every need. Whether you’re looking for a compact car for city drives, an SUV for rugged terrain, or a luxury car for business trips, you’ll find the perfect option.",
        },
        {
          title: "Transparent and secure process",
          description:
            "The platform ensures a seamless and transparent rental process. Each listing comes with detailed information about the car, rental terms, and pricing, with no hidden fees.",
        },
        {
          title: "Nationwide coverage",
          description:
            "Ako Sayara serves all major cities in Iraq, including Baghdad, Erbil, Basra, Sulaymaniyah, and Mosul, ensuring access to reliable car rentals across the country.",
        },
        {
          title: "Competitive prices",
          description:
            "With competitive pricing and flexible rental plans, Ako Sayara makes car rentals affordable for all budgets.",
        },
        {
          title: "Exceptional customer support",
          description:
            "Ako Sayara’s dedicated team is always available to assist customers, ensuring a hassle-free experience from booking to return.",
        },
      ],
    },
    {
      title: "How to rent a car with Ako Sayara",
      description:
        "Renting a car with Ako Sayara is simple and straightforward. Here’s a step-by-step guide:",
      image: "/assets/images/why-rent-3.png",
      features: [
        {
          title: "Visit the platform",
          description:
            "Go to Ako Sayara’s website or app and navigate to the car rental section.",
        },
        {
          title: "Browse available cars",
          description:
            "Use filters to refine your search by city, car type, price range, and other preferences.",
        },
        {
          title: "Choose your vehicle",
          description:
            "Select a car that suits your needs. Each listing includes specifications, rental terms, and availability.",
        },
        {
          title: "Book your car",
          description:
            "Complete the booking process by entering your details and confirming payment.",
        },
        {
          title: "Pick up or get delivery",
          description:
            "You can pick up your car from the provider’s location or request delivery to your desired address.",
        },
        {
          title: "Return the car",
          description:
            "Once your rental period ends, return the car as per the agreed terms.",
        },
      ],
    },
  ],
  ar: [
    {
      title: "لماذا يعد استئجار سيارة في العراق هو الخيار الأفضل",
      description:
        "Renting a car offers several advantages that make it a preferred option over public transportation or taxis:",
      image: "/assets/images/why-rent-1.png",
      features: [
        {
          title: "المرونة والراحة",
          description:
            "يمنحك استئجار سيارة السيطرة الكاملة على جدول رحلاتك. يمكنك التنقل بحرية بين المدن واستكشاف الأماكن البعيدة عن الطرق المعتادة دون القلق بشأن جداول وسائل النقل العام.",
        },
        {
          title: "الراحة للمسافرين",
          description:
            "سواء كنت تسافر بمفردك أو مع العائلة أو مجموعة، فإن السيارة المستأجرة تضمن الخصوصية والراحة طوال رحلتك.",
        },
        {
          title: "الفعالية من حيث التكلفة",
          description:
            "بالنسبة للإقامات الطويلة أو التنقلات المتكررة، غالبًا ما يكون استئجار سيارة أكثر اقتصادية من الاعتماد على سيارات الأجرة أو خدمات النقل التشاركي.",
        },
        {
          title: "الوصول إلى مواقع متنوعة",
          description:
            "الريف الخلاب في العراق والمعالم الثقافية والمدن الحديثة يتم استكشافها بشكل أفضل بالسيارة. مع الاستئجار، يمكنك زيارة أماكن مثل قلعة أربيل، وأهوار البصرة، وآثار الموصل القديمة بالوتيرة التي تناسبك.",
        },
      ],
    },
    {
      title: "لماذا تختار اكو سيارة لاستئجار السيارات في العراق؟",
      description:
        "اكو سيارة اسم موثوق في صناعة السيارات في العراق، حيث تقدم خدمات استئجار وبيع وشراء السيارات. وفيما يلي أسباب تميزها كمنصة مثالية للاستئجار:",
      image: "/assets/images/why-rent-2.png",
      features: [
        {
          title: "مجموعة واسعة من المركبات",
          description:
            "تقدم اكو سيارة تشكيلة متنوعة من المركبات تناسب جميع الاحتياجات. سواء كنت تبحث عن سيارة صغيرة للقيادة داخل المدن، أو دفع رباعي للمناطق الوعرة، أو سيارة فاخرة لرحلات العمل، ستجد الخيار الأمثل.",
        },
        {
          title: "عملية شفافة وآمنة",
          description:
            "تضمن المنصة عملية إيجار سهلة وشفافة. تتضمن كل قائمة معلومات مفصلة عن السيارة وشروط الإيجار والأسعار، دون أي رسوم خفية.",
        },
        {
          title: "تغطية على مستوى البلاد",
          description:
            "توفر اكو سيارة خدماتها في جميع المدن الكبرى في العراق، بما في ذلك بغداد، أربيل، البصرة، السليمانية، والموصل، مما يضمن الوصول إلى سيارات إيجار موثوقة في جميع أنحاء البلاد.",
        },
        {
          title: "أسعار تنافسية",
          description:
            "مع أسعار تنافسية وخطط إيجار مرنة، تجعل اكو سيارة استئجار السيارات ميسور التكلفة لجميع الميزانيات.",
        },
        {
          title: "دعم عملاء متميز",
          description:
            "فريق اكو سيارة المخصص متاح دائمًا لمساعدة العملاء، مما يضمن تجربة خالية من المتاعب من الحجز حتى الإرجاع.",
        },
      ],
    },
    {
      title: "كيفية استئجار سيارة عبر اكو سيارة",
      description:
        "Renting a car with Ako Sayara is simple and straightforward. Here’s a step-by-step guide:",
      image: "/assets/images/why-rent-3.png",
      features: [
        {
          title: "زيارة المنصة",
          description:
            "انتقل إلى موقع اكو سيارة أو التطبيق، ثم توجه إلى قسم استئجار السيارات.",
        },
        {
          title: "تصفح السيارات المتاحة",
          description:
            "استخدم أدوات التصفية لتحديد البحث حسب المدينة، نوع السيارة، نطاق السعر، والتفضيلات الأخرى.",
        },
        {
          title: "اختر مركبتك",
          description:
            "اختر السيارة المناسبة لاحتياجاتك. تتضمن كل قائمة المواصفات وشروط الإيجار والتوافر.",
        },
        {
          title: "احجز سيارتك",
          description:
            "أكمل عملية الحجز بإدخال بياناتك وتأكيد الدفع.",
        },
        {
          title: "التسلم أو التسليم",
          description:
            "يمكنك استلام سيارتك من موقع المزود أو طلب توصيلها إلى العنوان الذي تريده.",
        },
        {
          title: "إعادة السيارة",
          description:
            "عند انتهاء فترة الإيجار، أعد السيارة وفق الشروط المتفق عليها.",
        },
      ],
    },
  ],
  ku: [
    {
      title: "بۆچی بەکرێ گرتنی ئۆتۆمبێل لە عێراق باشترین هەڵبژاردنە",
      description:
        "بەکرێ گرتنی ئۆتۆمبێل چەندین سوودی هەیە کە وای لێدەکات ببێتە بژاردەیەکی پەسەندکراو لە چاو گواستنەوەی گشتی یان تەکسی:",
      image: "/assets/images/why-rent-1.png",
      features: [
        {
          title: "نەرمی و ئاسانکاری",
          description:
            "بەکرێدانی ئۆتۆمبێل کۆنترۆڵی تەواوت پێدەبەخشێت بەسەر خشتەی گەشتەکەتدا. دەتوانیت بە ئازادی لە نێوان شارەکاندا بجوڵێیت و شوێنە دوور لە ڕێگا لێدراوەکان بگەڕێیت بەبێ ئەوەی نیگەران بیت لە خشتەی گواستنەوەی گشتی",
        },
        {
          title: "ئاسوودەیی بۆ گەشتیاران",
          description:
            "گەشتکردن بە تەنیا بێت، لەگەڵ خێزانەکەت، یان بە گروپ، ئۆتۆمبێلێکی کرێ دڵنیای دەدات لە تایبەتمەندی و ئاسوودەیی بە درێژایی گەشتەکەت.",
        },
        {
          title: "کاریگەری تێچوون",
          description:
            "بۆ مانەوەی درێژخایەن یان گەشتکردنی زۆر، زۆرجار بەکرێدانی ئۆتۆمبێل ئابووریترە لە پشتبەستن بە تەکسی یان خزمەتگوزارییەکانی هاوبەشی سواربوون.",
        },
        {
          title: "دەستڕاگەیشتن بە شوێنە جۆراوجۆرەکان",
          description:
            "لادێ سەرسوڕهێنەرەکانی عێراق و شوێنەوارە کولتوورییەکان و شارە مۆدێرنەکان بە باشترین شێوە بە ئۆتۆمبێل بگەڕێن. بە کرێ، دەتوانیت بە خێرایی خۆت سەردانی شوێنەکانی وەک قەڵای هەولێر و تاڵاوەکانی بەسرە و وێرانە دێرینەکانی موسڵ بکەیت.",
        },
      ],
    },
    {
      title: "بۆچی ئەکو سەیارە هەڵبژێرین بۆ کرێی ئۆتۆمبێل لە عێراق؟",
      description:
        "ئاکۆ سەیارە ناوێکی متمانەپێکراوە لە پیشەسازی ئۆتۆمبێلی عێراقدا، خزمەتگوزاری کرێی ئۆتۆمبێل و کڕین و فرۆشتن پێشکەش دەکات. لێرەدا بۆچی وەک سەکۆیەکی ئایدیاڵ بۆ کرێی ئۆتۆمبێل جیاوازە:",
      image: "/assets/images/why-rent-2.png",
      features: [
        {
          title: "مەودای بەرفراوانی ئۆتۆمبێل",
          description:
            "ئەکو سەیارە هەڵبژاردەیەکی هەمەچەشنی ئۆتۆمبێل دابین دەکات کە لەگەڵ هەموو پێداویستییەکدا بگونجێت. جا تۆ بەدوای ئۆتۆمبێلێکی بچووکدا دەگەڕێیت بۆ لێخوڕینی شار، ئۆتۆمبێلێکی جۆری SUV بۆ زەوییە ناڕێکەکان، یان ئۆتۆمبێلێکی لوکس بۆ گەشتە بازرگانییەکان، بژاردەیەکی تەواو دەدۆزیتەوە.",
        },
        {
          title: "پرۆسەی شەفاف و پارێزراو",
          description:
            "پلاتفۆرمەکە پرۆسەی کرێی بێ کێشە و شەفاف مسۆگەر دەکات. هەر لیستێک زانیاری وردی لەگەڵدایە دەربارەی ئۆتۆمبێلەکە و مەرجەکانی کرێ و نرخەکان، بەبێ هیچ پارەیەکی شاراوە.",
        },
        {
          title: "ڕووماڵکردنی سەرتاسەری وڵات",
          description:
            "ئەکو سەیارە خزمەت بە هەموو شارە گەورەکانی عێراق دەکات، لەوانە بەغدا، هەولێر، بەسرە، سلێمانی، و موسڵ، دەستەبەرکردنی دەستڕاگەیشتن بە کرێی ئۆتۆمبێلی متمانەپێکراو لە سەرانسەری وڵاتدا.",
        },
        {
          title: "نرخە کێبڕکێکارەکان",
          description:
            "بە نرخی کێبڕکێ و پلانی کرێی نەرم و نیان، ئەکو سەیارە کرێی ئۆتۆمبێل بە نرخێکی گونجاو بۆ هەموو بودجەیەک دەکات.",
        },
        {
          title: "پشتگیری ناوازەی کڕیار",
          description:
            "تیمی تایبەت بە ئەکو سەیارە هەمیشە ئامادەیە بۆ یارمەتیدانی کڕیاران، دڵنیابوون لە ئەزموونێکی بێ کێشە لە حجزکردنەوە تا گەڕانەوە.",
        },
      ],
    },
    {
      title: "چۆنیەتی بەکرێدانی ئۆتۆمبێل لەگەڵ ئەکو سەیارە ",
      description:
        "بەکرێدانی ئۆتۆمبێل لەگەڵ ئەکو سەیارە سادە و ئاسانە. لێرەدا ڕێنمایی هەنگاو بە هەنگاو دەخەینەڕوو:",
      image: "/assets/images/why-rent-3.png",
      features: [
        {
          title: "سەردانی پلاتفۆرمەکە بکەن",
          description:
            "بڕۆ بۆ ماڵپەڕ یان ئەپی Ako Sayara’s و بچۆ بۆ بەشی کرێی ئۆتۆمبێل",
        },
        {
          title: "گەڕان بەدوای ئۆتۆمبێلەکان",
          description:
            "فلتەرەکان بەکاربهێنە بۆ وردکردنەوەی گەڕانەکەت بەپێی شار، جۆری ئۆتۆمبێل، مەودای نرخ و هەڵبژاردنەکانی تر.",
        },
        {
          title: "ئۆتۆمبێلەکەت هەڵبژێرە",
          description:
            "ئۆتۆمبێلێک هەڵبژێرە کە لەگەڵ پێداویستییەکانتان بگونجێت. هەر لیستێک تایبەتمەندییەکان، مەرجەکانی کرێ و بەردەستبوونی لەخۆدەگرێت.",
        },
        {
          title: "ئۆتۆمبێلەکەت حجز بکە",
          description:
            "پرۆسەی حجزکردن تەواو بکە بە داخڵکردنی وردەکارییەکانتان و پشتڕاستکردنەوەی پارەدان.",
        },
        {
          title: "وەرگرتن یان گەیاندنی سەیارەکە",
          description:
            "دەتوانیت ئۆتۆمبێلەکەت لە شوێنی دابینکەرەکە وەربگریت یان داوای گەیاندن بکەیت بۆ ناونیشانی دڵخوازت.",
        },
        {
          title: "ئۆتۆمبێلەکە بگەڕێنەرەوە",
          description:
            "کاتێک ماوەی کرێیەکەت کۆتایی هات، ئۆتۆمبێلەکە بگەڕێنەرەوە بەپێی مەرجەکانی ڕێککەوتن.",
        },
      ],
    },
  ],
};

export const belowBannerData = {
  title: {
    English: "Find your perfect rental in Iraq with Ako Sayara",
    Arabic: "اعثر على السيارة المثالية للإيجار في العراق مع اكو سيارة",
    Kurdish: "کرێی تەواوت لە عێراق بدۆزەرەوە لەگەڵ ئەکو سەیارە",
  },
  description: {
    English: `Renting a car in Iraq has become an increasingly popular choice for both residents and travelers. With cities like Baghdad, Basra, Erbil, and Sulaymaniyah offering unique attractions and opportunities, having the flexibility of a rental car can significantly enhance your experience. Whether you're exploring historic landmarks, commuting for work, or embarking on road trips, Ako Sayara stands out as a reliable platform for renting cars in Iraq.`,
    Arabic:
      "أصبح استئجار سيارة في العراق خيارًا شائعًا بشكل متزايد لكل من المقيمين والمسافرين. ومع مدن مثل بغداد، البصرة، أربيل، والسليمانية التي تقدم معالم فريدة وفرص متنوعة، فإن امتلاك مرونة التنقل بسيارة مستأجرة يمكن أن يعزز تجربتك بشكل كبير. سواء كنت تستكشف المواقع التاريخية، أو تتنقل للعمل، أو تنطلق في رحلات برية، تبرز اكو سيارة كمنصة موثوقة لاستئجار السيارات في العراق",
    Kurdish:
      "بەکرێ گرتنی ئۆتۆمبێل لە عێراق تادێت بووەتە هەڵبژاردەیەکی بەناوبانگ هەم بۆ دانیشتوان و هەم بۆ گەشتیاران. بەو پێیەی شارەکانی وەک بەغدا و بەسرە و هەولێر و سلێمانی شوێنی سەرنجڕاکێش و دەرفەتی ناوازە پێشکەش دەکەن، هەبوونی نەرمی ئۆتۆمبێلی کرێ دەتوانێت ئەزموونەکەت بە شێوەیەکی بەرچاو بەرز بکاتەوە. جا تۆ بەدوای شوێنە مێژووییەکان دەگەڕێیت، هاتووچۆ دەکەیت بۆ کار، یان دەست دەکەیت بە گەشتە ڕێگاوبانییەکان، ئەکو سەیارە وەک سەکۆیەکی متمانەپێکراو بۆ بەکرێدانی ئۆتۆمبێل لە عێراقدا جیاوازە.",
  },
  image_text: {
    English: "Rent a car in Iraq",
    Arabic: "استئجار سيارة في العراق",
    Kurdish: "بەکرێ گرتن ئۆتۆمبێل لە عێراق",
  },
  // count: "2500",
  stock_image: "/assets/images/rent-below-banner.png",
};

export const rentBenefitData = {
  title: {
    en: "Benefits of renting a car for different needs",
    ar: "فوائد استئجار سيارة لتلبية مختلف الاحتياجات",
    ku: "سوودەکانی بەکرێ گرتنی ئۆتۆمبێل بۆ پێداویستییە جیاوازەکان",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/rental-benefits/rental-benefit-1.svg",
      icon: "marketeq:work",
      title: "For Business Travelers",
      description:
        "Ako Sayara offers premium and luxury cars, ensuring you travel in style and comfort for meetings and events.",
    },
    {
      id: "2",
      image: "/assets/images/rental-benefits/rental-benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "For tourists",
      description:
        "Compact and economy cars are perfect for tourists looking to explore Iraq’s cities and countryside on a budget.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-3.svg",
      icon: "marketeq:reward",
      title: "For families",
      description:
        "SUVs and larger vehicles are ideal for families who need extra space and comfort.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-4.svg",
      icon: "marketeq:reward",
      title: "For adventure seekers",
      description:
        "For those venturing into rural areas or mountainous regions, 4x4 vehicles are a reliable choice.",
    },
  ],
  ar: [
    {
      id: "1",
      image: "/assets/images/rental-benefits/rental-benefit-1.svg",
      icon: "marketeq:work",
      title: "لرجال الأعمال",
      description:
        "تقدم اكو سيارة سيارات فاخرة وضخمة، مما يضمن التنقل بأسلوب وراحة لحضور الاجتماعات والفعاليات.",
    },
    {
      id: "2",
      image: "/assets/images/rental-benefits/rental-benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "للسياح",
      description:
        "السيارات الصغيرة والاقتصادية مثالية للسياح الذين يرغبون في استكشاف مدن وأرياف العراق بميزانية محدودة.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-3.svg",
      icon: "marketeq:reward",
      title: "للعائلات",
      description:
        "تعد سيارات الدفع الرباعي والمركبات الأكبر خيارًا مثاليًا للعائلات التي تحتاج لمساحة وراحة أكبر.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-4.svg",
      icon: "marketeq:reward",
      title: "لعشاق المغامرة",
      description:
        "لمن يتجهون إلى المناطق الريفية أو الجبلية، فإن سيارات الدفع الرباعي تعد خيارًا موثوقًا.",
    },
  ],
  ku: [
    {
      id: "1",
      image: "/assets/images/rental-benefits/rental-benefit-1.svg",
      icon: "marketeq:work",
      title: "بۆ گەشتیارانی بازرگانی",
      description:
        "ئاکۆ سەیارە ئۆتۆمبێلی پریمیۆم و لوکس پێشکەش دەکات، دڵنیای دەدات لە گەشتکردنت بە شێواز و ئاسوودەیی بۆ کۆبوونەوە و بۆنە و ئاهەنگەکان.",
    },
    {
      id: "2",
      image: "/assets/images/rental-benefits/rental-benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "بۆ گەشتیاران",
      description:
        "ئۆتۆمبێلی کۆمپاکت و ئابووری زۆر گونجاوە بۆ ئەو گەشتیارانەی کە دەیانەوێت بە بودجەیەکی کەم شار و لادێکانی عێراق بگەڕێن.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-3.svg",
      icon: "marketeq:reward",
      title: "بۆ خێزانەکان",
      description:
        "ئۆتۆمبێلی جۆری SUV و ئۆتۆمبێلی گەورەتر گونجاوە بۆ ئەو خێزانانەی کە پێویستیان بە شوێنی زیادە و ئاسوودەیی هەیە.",
    },
    {
      id: "3",
      image: "/assets/images/rental-benefits/rental-benefit-4.svg",
      icon: "marketeq:reward",
      title: "بۆ ئەوانەی بەدوای سەرگەرمیدا دەگەڕێن",
      description:
        "بۆ ئەوانەی کە خۆیان دەخەنە ناوچە گوندنشینەکان یان ناوچە شاخاوییەکان، ئۆتۆمبێلی 4x4 هەڵبژاردەیەکی جێی متمانەیە.",
    },
  ],
};
