import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlItem from "../OwlItem";
import { homeOptions } from "../../../data/content";
import { fetchRecent } from "../../../Reducer/recentSlice";
import OwlItemCars from "../OwlItem/owlItemCars";
import Skeletons from "../Skeletons";
import { fetchRecentData } from "../../../Reducer/api";

export default function RecentlyCarView({
  rtl,
  page,
  title,
  showTextWhenEmpty,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const recent = useSelector((state) => state.recent.recent);
  // const loading = useSelector((state) => state.recent.loading);
  const [loading, setLoading] = useState(true);
  const error = useSelector((state) => state.recent.error);
  const auth = useSelector((state) => state.auth.auth);
  const [recentAds, setRecentAds] = useState([]);
  let data = { id: auth && auth.id ? auth.id : "", page: page };
  const type = page === "normal" ? "Sell" : "Rent";

  const getApiData = async () => {
    // dispatch(fetchRecent(data));
    setLoading(true);
    const apiCall = await fetchRecentData({ page: page }).catch((err) => {
      console.log(err);
    });

    if (apiCall) {
      setRecentAds(apiCall);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApiData();
  }, [page]);

  // useEffect(() => {
  //   if (recent?.data?.length > 0) {
  //     setRecentAds(recent);
  //   } else {
  //     setRecentAds([]);
  //   }
  //   console.log(recent);
  // }, [recent]);

  let responce =
    recentAds?.data && recentAds?.data.length > 0 && page?.length > 0
      ? recentAds?.data.filter((v) => v?.adTypeName?.["en"] === type)
      : recentAds?.data || [];

  if (!loading && !recent) {
    if (showTextWhenEmpty) {
      return (
        <div
          className="my-4 text-center d-flex flex-column justify-content-center "
          style={{ minHeight: "400px" }}
        >
          <div
            className="my-4 fs-4 fw-semibold"
            style={{ color: "var(--text-black-1)" }}
          >
            {t("Your recently viewed adverts")}
          </div>
          <div className=" fs-6">
            {t("Adverts you view will get listed here")}
          </div>
        </div>
      );
    } else {
      return;
    }
  }
  return (
    <section class="recently_viewed">
      <div class="container">
        {responce?.length > 0 && <h2 class="hed">{t(title)}</h2>}
        {loading ? (
          <Skeletons forPage={"owlItems"} />
        ) : (
          <OwlCarousel
            {...homeOptions}
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
              rtl && "invertButtons"
            }`}
            // margin={10}
            rtl={rtl}
          >
            <OwlItemCars
              key={0}
              cars={responce}
              page={"BrandNewSection"}
              rentType={"daily"}
              filters={{ recentAds: true }}
              adsArray={recent?.allAdIds}
            />
          </OwlCarousel>
        )}
      </div>
    </section>
  );
}
