import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "./table";

export default function SellFind({ style, className }) {
  const [selectedOption, setSelectedOption] = useState("email");
  const { t, i18n } = useTranslation();

  return (
    <div className={`find_vin Sellfind_vin bg-white shadow-lg ${className} `} style={style || {}}>
      <span data-bs-toggle="modal" data-bs-target="#exampleModalFindVin">
        <h5 class="modal-title" id="exampleModalLabel">
          {t("How to Find VIN?")}
        </h5>
        <div class="w-100 text-center">
          <img
            class={`img-fluid `}
            style={{ width: "340px" }}
            src="assets/images/singpro.png"
            alt="img"
          />
        </div>
        <ul>
          <li>{t("Behind the windshield from the driver’s side")}</li>
          <li>{t("On the driver’s door frame")}</li>
        </ul>
        {/* <Table /> */}
        <img src="assets/images/find_vin.png" className="img-fluid" alt="" />
      </span>
    </div>
  );
}
