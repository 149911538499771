import React, { useEffect, useState } from "react";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FeatureDetail } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PageSection from "../PageSection";
export default function FeaturesSection({ content }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [accordion, setAccordion] = useState([1]);
  const toggleAccordion = (e) => {
    // if (accordion.includes(e)) {
    //   setAccordion([...accordion.filter((v) => v !== e)]);
    // } else {
    //   setAccordion([...accordion, e]);
    // }
    if (e === accordion[0]) {
      setAccordion([]);
    } else {
      setAccordion([e]);
    }
  };

  const data = content;

  return (
    <PageSection
      className={"feature p-0 m-0 h-100"}
      containerClasses={"p-sm-0"}
      containerStyle={{ height: `calc(100%)` }}
    >
      <div class="set h-100">
        {/* <div className="p-3 pt-lg-0 mb-3 ">
            <h4
              className="text-white fw-semibold text-center"
              style={{ fontSize: `calc(0.8em + 1vw)` }}
            >
              {data?.title || ""}
            </h4>
            <p className="text-white text-center mb-0">
              {data?.description || ""}
            </p>
          </div> */}
        <div class="row h-100">
          <div class="col-lg-7 p-0 d-flex align-items-between">
            <Accordion
              className="m-0 w-100 px-0 px-sm-4"
              open={accordion}
              toggle={toggleAccordion}
            >
              {data?.features &&
                data.features.slice(0, 5)?.map((item, index) => {
                  return (
                    <AccordionItem
                      className={`${
                        index === data?.features?.length - 1 ? "border-0" : ""
                      }`}
                    >
                      <AccordionHeader
                        targetId={index + 1}
                        style={{ width: "100%", borderRadius: "20px" }}
                      >
                        <div className="d-flex justify-content-start align-items-center gap-3">
                          <div
                            style={{ width: "30px", height: "30px" }}
                            className={`plus-icon ${
                              accordion.includes(index + 1) ? "active" : ""
                            }`}
                          ></div>
                          {item.title}
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={index + 1}>
                        <p>{item.description}</p>
                      </AccordionBody>
                    </AccordionItem>
                  );
                })}
            </Accordion>
            {/* </div> */}
          </div>
          <div class="col-lg-5 d-none  d-lg-flex align-items-center  justify-content-center">
            <img
              src={data?.image}
              alt="img"
              style={{
                objectFit: "cover",
                objectPosition: "left",
                maxHeight: 500,
                width: "100%",
                ...data?.imageStyle,
              }}
              className="rounded-2"
            />
          </div>
        </div>
      </div>
    </PageSection>
  );
}
