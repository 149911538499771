import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { buySellRent, evChallengesData, whyElectricData } from "./data";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PageSection from "../../Tools/PageSection";
import { Icon } from "@iconify/react/dist/iconify.js";
import Stock from "../NewCars/Stock";
import ContentSlider from "../../Tools/ContentSlider";
import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";

export function SliderSection() {
  const language = useSelector((state) => state.auth.language);
  const [accordion, setAccordion] = useState([]);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const toggleAccordion = (e) => {
    if (e === accordion[0]) {
      setAccordion([]);
    } else {
      setAccordion([e]);
    }
  };

  const content = buySellRent[lang];

  const [currentIndex, setCurrentIndex] = useState(0);

  const moveSection = (dir) => {
    if (dir === "next" && currentIndex === content.length - 1) return;
    if (dir === "prev" && currentIndex === 0) return;

    if (dir === "next") {
      setCurrentIndex((currentIndex + 1) % 3);
    }
    if (dir === "prev") {
      if (currentIndex === 0) setCurrentIndex(content.length - 1);
      else setCurrentIndex((currentIndex - 1) % 3);
    }
  };

  const renderSubContent = (item) => {
    return item.content.map((item, key) => (
      <div
        className=" set col p-3 mb-2 rounded-2"
        style={{ backgroundColor: "#334660" }}
      >
        <h5 className="text-light">{item?.subTitle}</h5>
        <p className="list-unstyled">
          {item?.subContent.map((i) => {
            return <li className="fs-6 my-3 text-light">{i}</li>;
          })}
        </p>
      </div>
    ));
  };

  return (
    <PageSection containerClasses={"account-card p-md-4 rounded-2"}>
      <div className="d-none d-xl-block overflow-hidden">
        <ContentSlider content={content} renderChild={renderSubContent} />
      </div>
      <div className="d-xl-none">
        <Accordion
          open={accordion}
          toggle={toggleAccordion}
          className=" rounded-2 py-1"
        >
          {content &&
            content?.map((item, index) => {
              return (
                <AccordionItem className="rounded-2 m-2 outline-none">
                  <AccordionHeader
                    targetId={index + 1}
                    className="d-flex justify-content-between"
                    style={{ width: "100%", borderRadius: "20px" }}
                  >
                    <span style={{ fontWeight: 600 }}>{item?.title}</span>
                  </AccordionHeader>
                  <AccordionBody accordionId={index + 1}>
                    {renderSubContent(
                      content.filter((v) => v?.id === currentIndex + 1)?.[0]
                    )}
                  </AccordionBody>
                </AccordionItem>
              );
            })}
        </Accordion>
      </div>
    </PageSection>
  );
}

export function WhyElectric() {
  return <Stock content={whyElectricData} />;
}

export const BenefitsSection = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = evChallengesData?.[lang] || [];
  const title = evChallengesData?.title?.[lang] || [];
  const rtl = lang !== "en";
  return <InfoCardsCarousel data={data} title={title} />;
};
