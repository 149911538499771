import React from "react";
import SellRentIntro from "../SellRentIntro";
import { paths } from "../../../utils/paths";

function RentYourCarPage() {
  return (
    <SellRentIntro
      page={"rental"}
      title={"Rent Your Car"}
      startLink={paths.rentYourCar}
    />
  );
}

export default RentYourCarPage;
