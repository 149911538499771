import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useState } from "react";
import { useEffect } from "react";
export default function Fuel({ fuel_type, title, allFilters, setAllFilters }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const handleChange = (e) => {
    const value = e.target.name;
    if (e.target.checked) {
      setAllFilters({ ...allFilters, fuel: [...allFilters?.fuel, value] });
    } else {
      setAllFilters({
        ...allFilters,
        fuel: allFilters?.fuel?.filter((v) => v !== value),
      });
    }
  };
  return (
    <div class="set fuel1">
      <h4>{title}</h4>
      <ul
        class="fuel-new row"
        style={{ listStyleType: "none", padding: "0px" }}
      >
        {fuel_type &&
          fuel_type.map((item, index) => {
            return (
              <li
                key={index}
                className={"col-md-3 col-6 d-flex align-items-start gap-2 mb-2"}
                style={{ height: "fit-content" }}
              >
                <input
                  type="checkbox"
                  id={`fuelBox${index}`}
                  onChange={handleChange}
                  checked={allFilters?.fuel?.includes(
                    item?.name?.[i18n.language] ||
                      item?.name?.[lang] ||
                      item?.name
                  )}
                  name={
                    item?.name?.[i18n.language] ||
                    item?.name?.[lang] ||
                    item?.name
                  }
                />
                <label htmlFor={`fuelBox${index}`} className="mx-1 mt-0">
                  {t(item?.name?.[i18n.language]) || t(item?.name?.[lang])}
                </label>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
