import React from "react";
import moment from "moment";
import { DateFormet } from "../../../data/content";
import { NavLink } from "react-router-dom";
import { t } from "i18next";
import { paths } from "../../../utils/paths";

const ArticleItem = ({
  style,
  inlineStyle,
  image,
  title,
  time_ago,
  created_by,
  id,
  data,
  lang,
  thumbnailHeight,
}) => {
  const dt = new Date(time_ago * 1000);
  
  return (
    <div class={style} style={inlineStyle}>
      <div
        class="m-1 m-sm-3 p-1 p-sm-3 set"
        style={{ margin: "unset !important" }}
      >
        <NavLink to={`${paths.singleBlog}/${data?.attributes?.name?.en.split(" ").join('-')}`}>
          <div class="img">
            <img
              src={image}
              alt="blog1"
              class="w-100 rounded"
              style={{
                objectFit: "cover",
                height: thumbnailHeight ? thumbnailHeight : "150px",
              }}
            />
          </div>
          <h3 class="title match" data-mh="title">
            {title}
          </h3>
          <p
            class="match2"
            data-mh="desc"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {/* {description} */}
            {data?.attributes?.[`content_${lang}`]}
          </p>
          <div className="row d-flex justify-content-between">
            <h6
              className={`col-sm-6 ${
                lang === "en" ? "text-start" : "text-end"
              }`}
            >
              {time_ago}
            </h6>
            <h6
              className={`col-sm-6 ${
                lang === "en" ? "text-sm-end" : "text-sm-start"
              }`}
            >
              {created_by}
            </h6>
          </div>
        </NavLink>
      </div>
    </div>
  );
};
export default ArticleItem;
