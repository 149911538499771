import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  StepsToBuyNewData,
  StockSectionData,
  TopNewCarsData,
  WhyNewCarsData,
} from "./data";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PageSection from "../../Tools/PageSection";
import { Icon } from "@iconify/react/dist/iconify.js";
import Stock from "../NewCars/Stock";
import Feature from "../CheapCars/Feature";
import ContentSlider from "../../Tools/ContentSlider";
import FeaturesSection from "../../Tools/FeaturesSection";
import { buySellRent } from "../ElectricCars/data";
import { homeOptions } from "../../../data/content";

import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../utils/paths";

export function SliderSection() {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (e === accordion[0]) {
      setAccordion([]);
    } else {
      setAccordion([e]);
    }
  };

  const content = buySellRent[lang];
  const renderSubContent = (item) => {
    return item.content.map((item, key) => (
      <div
        className=" set col p-3 mb-2 rounded-2"
        style={{ backgroundColor: "#334660" }}
      >
        <h5 className="text-light">{item?.subTitle}</h5>
        <p className="list-unstyled">
          {item?.subContent.map((i) => {
            return <li className="fs-6 my-3 text-light">{i}</li>;
          })}
        </p>
      </div>
    ));
  };

  return (
    <PageSection containerClasses={"shadow-sm p-md-4 rounded-2"}>
      <div className="d-none d-xl-block overflow-hidden">
        <ContentSlider content={content} renderChild={renderSubContent} />
      </div>
      <div className="d-xl-none">
        <Accordion
          open={accordion}
          toggle={toggleAccordion}
          className=" rounded-2 py-1"
        >
          {content &&
            content?.map((item, index) => {
              return (
                <AccordionItem className="rounded-2 m-2 outline-none">
                  <AccordionHeader
                    targetId={index + 1}
                    className="d-flex justify-content-between"
                    style={{ width: "100%", borderRadius: "20px" }}
                  >
                    <span style={{ fontWeight: 600 }}>{item?.title}</span>
                  </AccordionHeader>
                  <AccordionBody accordionId={index + 1}>
                    {renderSubContent(
                      content.filter((v) => v?.id === index + 1)?.[0]
                    )}
                  </AccordionBody>
                </AccordionItem>
              );
            })}
        </Accordion>
      </div>
    </PageSection>
  );
}

export function WhyNewCars() {
  // return <Stock content={whyRentData} />;
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  return (
    <PageSection containerClasses={"account-card p-md-4 rounded-2"}>
      <ContentSlider
        content={WhyNewCarsData?.[lang]}
        renderChild={(item) => {
          return <FeaturesSection content={item} />;
        }}
      />
    </PageSection>
  );
}

export const StockSection = () => {
  return <Stock content={StockSectionData} />;
};

export const StepsToBuyNew = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = StepsToBuyNewData?.[lang] || [];
  const title = StepsToBuyNewData?.title?.[lang] || [];
  const rtl = lang !== "en";
  return <InfoCardsCarousel data={data} title={title} />;
};

export const TopNewCars = () => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = TopNewCarsData?.[lang] || [];
  const title = TopNewCarsData?.title?.[lang] || [];
  const rtl = lang !== "en";
  const searchCars = (filters) => {
    console.log(filters);
    navigate(paths.buyCar, {
      state: { filters: { ...filters, page: "normal" } },
    });
  };
  return (
    <section class="text-center">
      <div class="container">
        <h2 class="hed">{title}</h2>
        <OwlCarousel
          {...{
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: { items: 1, nav: true },
              576: { items: 1, nav: true },
              768: { items: 2, nav: true },
              992: { items: 3, nav: true },
              1200: { items: 3, nav: true },
            },
          }}
          className={`owl-carousel benefits-owl owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
            rtl && "invertButtons"
          }`}
          // margin={10}
          rtl={rtl}
        >
          {data
            ? data.map((item, key) => {
                return (
                  <div
                    class="set border h-100 "
                    style={{ minHeight: "400px", cursor: "pointer" }}
                    key={key}
                    onClick={(e) => searchCars(item?.filters)}
                  >
                    <img
                      src={item.image}
                      alt="icon"
                      style={{
                        height: "220px",
                        objectFit: "contain",
                      }}
                      className="mb-2 mt-2"
                    />
                    <div className="d-flex justify-content-center">
                      <div class="car-shadow"></div>
                    </div>
                    <h5 style={{ marginTop: "25px" }}>{item.title ? item.title : ""}</h5>
                    <p style={{ minHeight: "70px" }}>
                      {item.description ? item.description : ""}{" "}
                    </p>
                  </div>
                );
              })
            : ""}
        </OwlCarousel>
      </div>
    </section>
  );
};
