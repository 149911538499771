import React from "react";
import { homeOptions } from "../../../data/content";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function OwlSkeletons({CustomSkeleton}) {
  return (
    <>
      <OwlCarousel
        className={`owl-carousel Recently_carousel owl.carousel.min owl-theme`}
        {...homeOptions}
        // margin={10}
        // rtl={rtl}
        nav
      >
        {Array(3)
          .fill(1)
          ?.map((item, key) => {
            return (
              <div key={"owlSkel" + item} class="item position-relative">
                <div class="set overflow-hidden">
                  <div className="img" style={{ background: "none" }}>
                    <CustomSkeleton height={20} className="h-100" />
                  </div>
                  <span className="d-flex justify-content-between mt-2 mb-3">
                    <div className="col-6">
                      <CustomSkeleton height={20} className="w-50" />
                    </div>
                    <div className="col-2">
                      <CustomSkeleton width={40} className="w-100" />
                    </div>
                  </span>
                  <div style={{ cursor: "pointer" }}>
                    <p>
                      <CustomSkeleton height={20} className="w-100 mb-2" />
                      <CustomSkeleton height={20} className="w-100 mb-2" />
                    </p>
                    <div className="">
                      <CustomSkeleton height={30} className="w-100" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </OwlCarousel>
    </>
  );
}

export default OwlSkeletons;
