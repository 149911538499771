const rtl = localStorage.getItem('cl') !== 'English'

export const WhyNewCarsData = {
  en: [
    {
      title: "Why choose Ako Sayara for new car deals?",
      description:
        "Ako Sayara is the leading platform for car buying, selling, and renting in Iraq. Here’s why it’s the best choice for finding new car deals:",
      image: "/assets/images/new-cars-data/why-new-cars.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        width: "",
      },
      features: [
        {
          title: "Wide selection of brands and model",
          description: `Access to cars from top brands like Toyota, Hyundai, Kia, Nissan, Mitsubishi, and more.
Variety of options, including sedans, SUVs, compact cars, and luxury models.`,
        },
        {
          title: "Competitive pricing",
          description: `Transparent pricing ensures no hidden costs.
Regular promotions and seasonal discounts to make new cars more affordable.`,
        },
        {
          title: "Trusted dealers",
          description: `Verified dealerships across Iraq ensure authenticity and quality.
All new cars come with manufacturer warranties.`,
        },
        {
          title: "Easy search features",
          description: `Filter cars by brand, price, type, and location to find your ideal match.
Detailed listings with specifications and photos for every car.`,
        },
        {
          title: "Financing support",
          description: `Assistance with car loans and payment plans through Ako Sayara’s network of financial partners.`,
        },
      ],
    },
    {
      title: "How to get the best new car deals in Iraq",
      description:
        "Finding the best deal requires some preparation and research. Here are a few tips:",
      image: "/assets/images/new-cars-data/how-buy-new.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        width: "",
      },
      features: [
        {
          title: "Determine your budget",
          description:
            "Include costs like insurance, registration, and taxes in your calculations.",
        },
        {
          title: "Research brands and models",
          description:
            "Compare features, performance, and reviews of different cars to find the best fit for your needs.",
        },
        {
          title: "Watch for promotions",
          description:
            "Check Ako Sayara for seasonal discounts, cashback offers, and dealer promotions.",
        },
        {
          title: "Consider financing options",
          description:
            "Look for flexible payment plans and low-interest rates through Ako Sayara’s financial partners.",
        },
        {
          title: "Negotiate with dealers",
          description:
            "Discuss additional perks like free servicing, extended warranties, or accessories.",
        },
      ],
    },
  ],
  ar: [
    {
      title: "لماذا تختار اكو سيارة للحصول على عروض السيارات الجديدة؟",
      description:
        "اكو سيارة هي المنصة الرائدة في شراء وبيع وتأجير السيارات في العراق. إليك أهم الأسباب لاختيارها عند البحث عن عروض السيارات الجديدة:",
      image: "/assets/images/new-cars-data/why-new-cars.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        width: "",
      },
      features: [
        {
          title: "مجموعة واسعة من العلامات التجارية والموديلات",
          description: `إمكانية الوصول إلى سيارات من علامات تجارية عالمية مثل تويوتا، هيونداي، كيا، نيسان، ميتسوبيشي وغيرها.
خيارات متنوعة تشمل السيدان، الـSUV، السيارات المدمجة، والسيارات الفاخرة.`,
        },
        {
          title: "أسعار تنافسية",
          description: `أسعار شفافة تضمن عدم وجود تكاليف خفية.
عروض ترويجية وخصومات موسمية منتظمة لجعل السيارات الجديدة في متناول الجميع.`,
        },
        {
          title: "وكلاء موثوقون",
          description: `وكلاء معتمدون في جميع أنحاء العراق لضمان المصداقية والجودة.
جميع السيارات الجديدة تأتي مع ضمانات المصنع.`,
        },
        {
          title: "ميزات بحث سهلة",
          description: `تصفية السيارات حسب العلامة التجارية، السعر، النوع، والموقع للعثور على السيارة المثالية.
قوائم تفصيلية تتضمن المواصفات والصور لكل سيارة.`,
        },
        {
          title: "دعم التمويل",
          description: `مساعدة في قروض السيارات وخطط الدفع عبر شبكة من الشركاء الماليين التابعين لاكو سيارة.`,
        },
      ],
    },
    {
      title: "كيفية الحصول على أفضل عروض السيارات الجديدة في العراق",
      description:
        "للوصول إلى أفضل العروض عليك القيام ببعض التحضيرات والبحث. إليك بعض النصائح:",
      image: "/assets/images/new-cars-data/how-buy-new.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        width: "",
      },
      features: [
        {
          title: "تحديد الميزانية",
          description:
            "تأكد من تضمين تكاليف مثل التأمين والتسجيل والضرائب في حساباتك.",
        },
        {
          title: "البحث عن العلامات والموديلات",
          description:
            "قارن الميزات والأداء وآراء المستخدمين للعثور على ما يناسب احتياجاتك.",
        },
        {
          title: "مراقبة العروض الترويجية",
          description:
            "تفقد اكو سيارة بانتظام للاطلاع على الخصومات الموسمية وعروض الكاش باك وترقيات الوكلاء.",
        },
        {
          title: "النظر في خيارات التمويل",
          description:
            "ابحث عن خطط دفع مرنة وأسعار فائدة منخفضة من خلال شركاء اكو سيارة الماليين.",
        },
        {
          title: "التفاوض مع الوكلاء",
          description:
            "ناقش المزايا الإضافية مثل الصيانة المجانية أو الضمانات الممتدة أو الإكسسوارات.",
        },
      ],
    },
  ],
  ku: [
    {
      title:
        "بۆچی ئەکو سەیارە هەڵبژێریت بۆ ئەوەی ئۆفەری ئۆتۆمبێلی نوێ بەدەست بهێنیت؟",
      description:
        "ئەکو سەیارە پلاتفۆرمی پێشەنگی کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێلە لە عێراق. لێرەدا گرنگترین هۆکارەکان دەخەینەڕوو بۆ هەڵبژاردنی لەکاتی گەڕان بەدوای ئۆفەری ئۆتۆمبێلی نوێدا:",
      image: "/assets/images/new-cars-data/why-new-cars.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        width: "",
      },
      features: [
        {
          title: "کۆمەڵێک براند و مۆدێلی بەرفراوان",
          description: `دەستڕاگەیشتن بە ئۆتۆمبێل لە براندە جیهانیەکانی وەک تۆیۆتا، هیۆندا، کیا، نیسان، میتسوبیشی و ئەوانی تر.
بژاردە جیاوازەکان بریتین لە ئۆتۆمبێلی سیدان و SUV و ئۆتۆمبێلی کۆمپاکت و ئۆتۆمبێلی لاکچێری.`,
        },
        {
          title: "نرخە کێبڕکێکارەکان",
          description: `نرخیکی شەفاف دڵنیای دەدات لەوەی هیچ تێچوونێکی شاراوە نییە.
بانگەشە و داشکاندنی وەرزی بەردەوام وا دەکات ئۆتۆمبێلی نوێ بۆ هەمووان گونجاو بێت.`,
        },
        {
          title: "بریکاری متمانەپێکراو",
          description: `بریکاری بڕوانامەدار لە گشت عێراق بۆ دڵنیابوون لە متمانە و کوالیتی.
هەموو ئۆتۆمبێلە نوێیەکان گەرەنتی کارگەیان لەگەڵدایە.`,
        },
        {
          title: "تایبەتمەندی گەڕانی ئاسان",
          description: `فلتەری ئۆتۆمبێلەکان بکە بەپێی مارکە و نرخ و جۆر و شوێن بۆ دۆزینەوەی ئۆتۆمبێلێکی تەواو.
لیستە وردەکان لەوانەش تایبەتمەندی و وێنە بۆ هەر ئۆتۆمبێلێک.`,
        },
        {
          title: "پشتگیری دارایی (تمویل)",
          description: `هاوکاریکردن لە قەرزی ئۆتۆمبێل و پلانی پارەدان لە ڕێگەی تۆڕێک لە هاوبەشە داراییەکانی ئەکو سەیارە.`,
        },
      ],
    },
    {
      title: "How to get the best new car deals in Iraq",
      description:
        "Finding the best deal requires some preparation and research. Here are a few tips:",
      image: "/assets/images/new-cars-data/how-buy-new.png",
      imageStyle: {
        maxHeight: 370,
        marginInlineStart: 80,
        marginInlineStart: 80,
      },
      features: [
        {
          title: "Determine your budget",
          description:
            "Include costs like insurance, registration, and taxes in your calculations.",
        },
        {
          title: "Research brands and models",
          description:
            "Compare features, performance, and reviews of different cars to find the best fit for your needs.",
        },
        {
          title: "Watch for promotions",
          description:
            "Check Ako Sayara for seasonal discounts, cashback offers, and dealer promotions.",
        },
        {
          title: "Consider financing options",
          description:
            "Look for flexible payment plans and low-interest rates through Ako Sayara’s financial partners.",
        },
        {
          title: "Negotiate with dealers",
          description:
            "Discuss additional perks like free servicing, extended warranties, or accessories.",
        },
      ],
    },
  ],
};

export const StockSectionData = {
  title: {
    English: "Discover the best offers with Ako Sayara",
    Arabic: "اكتشف أفضل العروض مع اكو سيارة",
    Kurdish: "باشترین ئۆفەرەکان بدۆزەرەوە لەگەڵ ئەکو سەیارە",
  },
  description: {
    English:
      "Buying a new car is an exciting experience, but finding the best deals can often feel overwhelming. Ako Sayara makes it easier than ever to find the perfect new car at competitive prices in Iraq. Whether you’re in Baghdad, Basra, Erbil, Mosul, or Sulaymaniyah, Ako Sayara connects you with trusted dealers offering a variety of new car models, promotions, and financing options.",
    Arabic:
      "يعد شراء سيارة جديدة تجربة مثيرة، لكن العثور على أفضل العروض قد يبدو أحيانًا مرهقًا. تجعل اكو سيارة هذه المهمة أسهل من أي وقت مضى، حيث تتيح لك إيجاد السيارة الجديدة المثالية بأسعار تنافسية في العراق. سواء كنت في بغداد أو البصرة أو أربيل أو الموصل أو السليمانية، تربطك اكو سيارة بالوكلاء الموثوقين الذين يقدمون مجموعة متنوعة من موديلات السيارات الجديدة والعروض الترويجية وخيارات التمويل.",
    Kurdish:
      "کڕینی ئۆتۆمبێلێکی نوێ ئەزموونێکی سەرنجڕاکێشە، بەڵام دۆزینەوەی باشترین گرێبەستەکان هەندێک جار دەتوانێت شتێکی قورس بێت. ئەکو سەیارە ئەم ئەرکە ئاسانتر دەکات، ڕێگەت پێدەدات ئۆتۆمبێلی نوێی تەواو بە نرخێکی کێبڕکێکار لە عێراق بدۆزیتەوە. جا تۆ لە بەغدا بیت، بەسرە، هەولێر، موسڵ، یان سلێمانی، ئەکو سەیارە دەتبەستێتەوە بە بریکارە متمانەپێکراوەکانەوە کە جۆرەها مۆدێلی نوێی ئۆتۆمبێل و پرۆمۆشن و بژاردەی دارایی پێشکەش دەکەن.",
  },
  image_text: {
    English: "New car deals in Iraq",
    Arabic: "عروض السيارات الجديدة في العراق",
    Kurdish: "ئۆفەری ئۆتۆمبێلی نوێ لە عێراق",
  },
  // count: "2500",
  stock_image: "/assets/images/carimg.png",
  stock_image_style: {
    objectFit: "contain",
    maxHeight: 400,
    // marginInlineStart: 0
  },
  stock_image_className: rtl ? "me-md-5" : "ms-md-5"
};

export const StepsToBuyNewData = {
  title: {
    en: "Steps to buy a new car through Ako Sayara",
    ar: "خطوات شراء سيارة جديدة عبر اكو سيارة",
    ku: "هەنگاوەکانی کڕینی ئۆتۆمبێلێکی نوێ لە ڕێگەی ئەکو سەیارە",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/steps-to-buy-new/step-1.svg",
      icon: "marketeq:work",
      title: "Browse listings",
      description: `Visit Ako Sayara’s website or app.
Use filters like brand, price range, and location to find your ideal car.`,
    },
    {
      id: "2",
      image: "/assets/images/steps-to-buy-new/step-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "Compare options",
      description: `Review detailed specifications and photos for each listing.
Compare prices and deals from different dealers.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-3.svg",
      icon: "marketeq:reward",
      title: "Contact dealers",
      description: `Reach out to verified dealers via Ako Sayara to confirm availability and details.
Schedule a visit to inspect the car or request more information.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-4.svg",
      icon: "marketeq:reward",
      title: "Finalize the purchase",
      description: `Negotiate terms, including warranties and insurance.
Complete the payment or financing arrangements.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-5.svg",
      icon: "marketeq:reward",
      title: "Delivery or pickup",
      description: `Choose between picking up the car from the dealership or arranging home delivery.`,
    },
  ],
  ar: [
    {
      id: "1",
      image: "/assets/images/steps-to-buy-new/step-1.svg",
      icon: "marketeq:work",
      title: "تصفح القوائم",
      description: `زر موقع اكو سيارة أو التطبيق الخاص بهم.
استخدم عوامل التصفية مثل العلامة التجارية، نطاق السعر، والموقع للعثور على سيارتك المثالية.`,
    },
    {
      id: "2",
      image: "/assets/images/steps-to-buy-new/step-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "قارن الخيارات",
      description: `اطلع على المواصفات والصور التفصيلية لكل إعلان.
قارن الأسعار والعروض من مختلف الوكلاء.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-3.svg",
      icon: "marketeq:reward",
      title: "تواصل مع الوكلاء",
      description: `تواصل مع الوكلاء المعتمدين عبر اكو سيارة لتأكيد التوفر والحصول على التفاصيل.
حدد موعدًا لمعاينة السيارة أو اطلب معلومات إضافية.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-4.svg",
      icon: "marketeq:reward",
      title: "أتمم عملية الشراء",
      description: `تفاوض على الشروط بما يشمل الضمانات والتأمين.
أكمل عملية الدفع أو ترتيبات التمويل.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-5.svg",
      icon: "marketeq:reward",
      title: "التسليم أو الاستلام",
      description: `اختر ما بين استلام السيارة من الوكالة أو ترتيب توصيلها إلى المنزل.`,
    },
  ],
  ku: [
    {
      id: "1",
      image: "/assets/images/steps-to-buy-new/step-1.svg",
      icon: "marketeq:work",
      title: "گەڕان بە لیستەکاندا",
      description: `سەردانی ماڵپەڕی ئەکو سەیارە یان ئەپەکەیان بکەن.
فلتەرەکانی وەک مارکە و مەودای نرخ و شوێن بەکاربهێنە بۆ دۆزینەوەی ئۆتۆمبێلە تەواوەکەت.`,
    },
    {
      id: "2",
      image: "/assets/images/steps-to-buy-new/step-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "بژاردەکان بەراورد بکە",
      description: `تایبەتمەندی و وێنەی ورد بۆ هەر ڕێکلامێک ببینە.
بەراوردکردنی نرخ و ئۆفەرەکانی بریکاری جیاواز.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-3.svg",
      icon: "marketeq:reward",
      title: "پەیوەندی بە بریکارەکانەوە بکە",
      description: `بۆ پشتڕاستکردنەوەی بەردەستبوون و بەدەستهێنانی وردەکارییەکان پەیوەندی بە بریکارە ڕێگەپێدراوەکانەوە بکەن لە ڕێگەی ئەکو سەیارە.
کات دیاری بکە بۆ پشکنینی ئۆتۆمبێل یان داوای زانیاری زیاتر بکە.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-4.svg",
      icon: "marketeq:reward",
      title: "کڕینەکەت تەواو بکە",
      description: `دانوستان لەسەر مەرجەکان بکە، لەوانەش گەرەنتی و بیمە.
ڕێکخستنی تەواوکردنی پارەدان یان دارایی.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-5.svg",
      icon: "marketeq:reward",
      title: "گەیاندن یان وەرگرتن",
      description: `لە نێوان کۆکردنەوەی ئۆتۆمبێلەکە لە بریکارەکە یان ڕێکخستنی گەیاندن بۆ ماڵەوە هەڵبژێرە.`,
    },
  ],
};
export const TopNewCarsData = {
  title: {
    en: "Top new car deals in Iraq",
    ar: "أبرز عروض السيارات الجديدة في العراق",
    ku: "هەنگاوەکانی کڕینی ئۆتۆمبێلێکی نوێ لە ڕێگەی ئەکو سەیارە",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/top-new-cars/corolla.png",
      icon: "marketeq:work",
      title: "Toyota corolla",
      filters: {
        make: "Toyota",
        model: "Corolla",
      },
      description: `Known for reliability, fuel efficiency, and a comfortable interior.
Ideal for city driving and long-distance travel.`,
    },
    {
      id: "2",
      image: "/assets/images/top-new-cars/tucson.png",
      icon: "marketeq:mobile-payment-done-2",
      title: "Hyundai tucson",
      filters: {
        make: "Hyundai",
        model: "Tucson",
      },
      description: `A stylish SUV with advanced safety features and spacious interiors.
Perfect for families and off-road enthusiasts.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/sportage.png",
      icon: "marketeq:reward",
      title: "Kia sportage",
      filters: {
        make: "Kia",
        model: "Sportage",
      },
      description: `Compact SUV with modern features and excellent fuel efficiency.
Popular among young professionals and families.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/altima.png",
      icon: "marketeq:reward",
      title: "Nissan altima",
      filters: {
        make: "Nissan",
        model: "Altima",
      },
      description: `A sleek sedan with cutting-edge technology and superior performance.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/pajero.png",
      icon: "marketeq:reward",
      title: "Mitsubishi pajero",
      filters: {
        make: "Mitsubishi",
        model: "Pajero",
      },
      description: `A durable SUV designed for off-road adventures and rugged terrains.`,
    },
  ],
  ar: [
    {
      id: "1",
      image: "/assets/images/top-new-cars/corolla.png",
      icon: "marketeq:work",
      title: "تويوتا كورولا",
      filters: {
        make: "Toyota",
        model: "Corolla",
      },
      description: `معروفة بالاعتمادية والكفاءة في استهلاك الوقود والمقصورة المريحة.
مثالية للقيادة في المدينة والرحلات الطويلة.`,
    },
    {
      id: "2",
      image: "/assets/images/top-new-cars/tucson.png",
      icon: "marketeq:mobile-payment-done-2",
      title: "هيونداي توسان",
      filters: {
        make: "Hyundai",
        model: "Tucson",
      },
      description: `سيارة SUV أنيقة مع ميزات أمان متقدمة ومقصورة واسعة.
مناسبة للعائلات وهواة الطرق الوعرة.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/sportage.png",
      icon: "marketeq:reward",
      title: "كيا سبورتاج",
      filters: {
        make: "Kia",
        model: "Sportage",
      },
      description: `سيارة SUV مدمجة بميزات عصرية وكفاءة ممتازة في استهلاك الوقود.
مفضلة لدى الشباب والعائلات.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/altima.png",
      icon: "marketeq:reward",
      title: "نيسان ألتيما",
      filters: {
        make: "Nissan",
        model: "Altima",
      },
      description: `سيدان أنيقة مع تكنولوجيا متطورة وأداء متميز.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/pajero.png",
      icon: "marketeq:reward",
      title: "ميتسوبيشي باجيرو",
      filters: {
        make: "Mitsubishi",
        model: "Pajero",
      },
      description: `سيارة SUV متينة مصممة للمغامرات على الطرق الوعرة والظروف الصعبة.`,
    },
  ],
  ku: [
    {
      id: "1",
      image: "/assets/images/top-new-cars/corolla.png",
      icon: "marketeq:work",
      title: "تویۆتا کۆرۆلا",
      filters: {
        make: "Toyota",
        model: "Corolla",
      },
      description: `بە متمانەپێکراوی و کارایی سووتەمەنی و کابینەیەکی ئارام ناسراوە.
نمونەیە بۆ لێخورین لە ناو شار و بۆ رێگەی دوور.`,
    },
    {
      id: "2",
      image: "/assets/images/top-new-cars/tucson.png",
      icon: "marketeq:mobile-payment-done-2",
      title: "هیۆندا توسان",
      filters: {
        make: "Hyundai",
        model: "Tucson",
      },
      description: `ئۆتۆمبێلێکی جۆری SUV ی شیک بە تایبەتمەندی سەلامەتی پێشکەوتوو و کابینەیەکی فراوان.
گونجاوە بۆ خێزان و ئارەزومەندانی ئۆفڕۆد.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/sportage.png",
      icon: "marketeq:reward",
      title: "کیا سپۆرتاج",
      filters: {
        make: "Kia",
        model: "Sportage",
      },
      description: `ئۆتۆمبێلێکی جۆری SUV کۆمپاکت بە تایبەتمەندی مۆدێرن و کارایی سووتەمەنی نایاب.
یەکێکە لە دڵخوازەکانی گەنجان و خێزانەکان.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/altima.png",
      icon: "marketeq:reward",
      title: "نیسان ئاڵتیما",
      filters: {
        make: "Nissan",
        model: "Altima",
      },
      description: `ئۆتۆمبێلێکی سیدانێکی ڕەسەن بە تەکنەلۆژیای پێشکەوتوو و کارایییەکی نایاب.`,
    },
    {
      id: "3",
      image: "/assets/images/top-new-cars/pajero.png",
      icon: "marketeq:reward",
      title: "میتسوبیشی پاجیرۆ",
      filters: {
        make: "Mitsubishi",
        model: "Pajero",
      },
      description: `ئۆتۆمبێلێکی جۆری SUV ناڕێک کە بۆ سەرگەرمیەکانی ئۆفڕۆد و بارودۆخی سەخت دیزاین کراوە.`,
    },
  ],
};
