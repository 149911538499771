import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StockDetail } from "../../../Reducer/newcarSlice";
import { toast } from "react-toastify";
export default function Stock({ content }) {
  const { t, i18n } = useTranslation();
  const stockData = useSelector((state) => state.newcar.stockdata);
  const loading = useSelector((state) => state.newcar.loading);
  const error = useSelector((state) => state.newcar.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(StockDetail());
  }, [dispatch]);
  if (error) {
    toast.error(error);
  }
  const data = content || stockData;
  return (
    <div className="d-flex justify-content-center">
      <section
        class="new_car rounded-3 d-flex align-items-center account-card no-shadow-below-sm"
        style={{ borderRadius: "0px", height: 560, maxWidth: "1350px" }}
      >
        {data != "" ? (
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <h2>
                  {data?.count && (
                    <span>
                      {data?.count}+<br />
                    </span>
                  )}
                  <span className="d-block">
                    {data?.image_text ? data?.image_text?.[i18n.language] : ""}
                  </span>
                </h2>
                <img
                  src={data?.stock_image}
                  alt="car"
                  className={`${data?.stock_image_className || ""}`}
                  style={{
                    maxHeight: 510,
                    objectFit: "cover",
                    marginInlineStart: 50,
                    ...data?.stock_image_style || {},
                  }}
                />
              </div>
              <div className="col-sm-6 px-md-5 px-sm-2 d-flex flex-column align-items-center justify-content-center">
                <h4 className="w-100 mx-0 mb-0 px-0">
                  {data?.title ? data?.title?.[i18n.language] : ""}
                </h4>
                <p className="m-0 px-0">
                  {data?.description ? data?.description?.[i18n.language] : ""}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}
