import React from "react";
import { isImageOrVideo } from "../../../utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function SearchCrousel({ images, id }) {
  if (images.length == 0) {
    images = ["/assets/images/notFound.png"];
  }
  return (
    <div class="col-sm-6">
      <div id={`a${id}`} class="carousel slide h-100" data-bs-ride="true">
        <ul class="carousel-indicators">
          {images &&
            images.map((item, index) => {
              return (
                <li
                  data-bs-target={`#a${id}`}
                  data-bs-slide-to={index}
                  aria-label={`Slide ${index}`}
                  className={`${index == 0 ? "active" : ""} w-100 `}
                >
                  {isImageOrVideo(item) === "video" ? (
                    <video className="h-100 w-100">
                      <source src={item} />
                    </video>
                  ) : (
                    // <img
                    //   src={item}
                    //   className="w-100"
                    //   style={{ objectFit: "cover", height: "100% !important" }}
                    //   alt="img"
                    // />
                    <LazyLoadImage src={item} alt={`Image`} effect="opacity" />
                  )}
                </li>
              );
            })}
        </ul>
        <div class="carousel-inner h-100">
          {images &&
            images.map((item, index) => {
              return (
                <div class={`carousel-item${index == 0 ? " active" : ""}`}>
                  {isImageOrVideo(item) === "video" ? (
                    <video
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    >
                      <source src={item} />
                    </video>
                  ) : (
                    // <img
                    //   src={item}
                    //   class="d-block w-100 h-100"
                    //   style={{ objectFit: "cover" }}
                    //   alt="img"
                    // />
                    <LazyLoadImage src={item} alt={`Image`} effect="opacity" />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
