import React, { useEffect, useState } from "react";
import SingleArticle from "./SingleArticle";
import { useTranslation } from "react-i18next";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import { marked } from "marked";
import { fetchHomeArticleData, getArticles } from "../../../Reducer/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export default function ArticleBlog() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }

  const params = useParams();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    // getArticles(params.id).then((res) => {
    //   res.json().then((r) => {
    //     setRawData(r)
    //     console.log(r);
    //     const temp = r?.data?.attributes
    //     setData({
    //       en: temp.content_en,
    //       ar: temp.content_ar,
    //       ku: temp.content_ku
    //     });
    //   });
    // });

    fetchHomeArticleData({ sortBy: "desc" }).then((res) => {
      res
        .json()
        .then((r) => {
          if (r?.data) {
            const name = params.id;
            // setArticles(r.data);
            const curArticle = r?.data?.filter(
              (v) => v?.attributes?.name?.en.split(" ").join("-") === name
            )?.[0];
            const temp = curArticle?.attributes;
            setRawData({ data: curArticle });
            setData({
              en: temp.content_en,
              ar: temp.content_ar,
              ku: temp.content_ku,
            });
            console.log(curArticle);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [params.id]);

  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false,
  });

  console.log(data);

  return (
    <div>
      <SingleArticle
        data={marked.parse(data ? data?.[lang] : t("Please wait..."))}
        rawData={rawData}
      />
      <ArticlesSection rtl={rtl} />
    </div>
  );
}
