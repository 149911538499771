const rtl = localStorage.getItem('cl') !== 'English'

export const buySellRent = {
  en: [
    {
      id: 1,
      title: "Buying Electric Cars in Iraq",
      value: "buy",
      content: [
        {
          subTitle: "Benefits of Buying Electric Cars in Iraq",
          subContent: [
            `Fuel Savings: With rising fuel prices, EVs eliminate the need for petrol or diesel.`,
            `Reduced Maintenance Costs: Electric motors have fewer moving parts, reducing repair and maintenance expenses.`,
            `Energy Independence: EVs can be charged using renewable energy sources, such as solar panels, making them a sustainable option.`,
          ],
        },
        {
          subTitle: "Popular Electric Car Models in Iraq",
          subContent: [
            `Electric cars from global brands like Tesla, Nissan, and Hyundai are gaining popularity. Here are some commonly sought-after models:`,
            `Tesla Model 3: Known for its performance and long range.`,
            `Nissan Leaf: A budget-friendly option with excellent reliability.`,
            `Hyundai Ioniq 5: Combines futuristic design with high performance.`,
            `Chevrolet Bolt EV: Compact and perfect for city driving.`,
          ],
        },
        {
          subTitle: "How Ako Sayara Helps You Buy an Electric Car",
          subContent: [
            `Ako Sayara connects you with verified sellers and dealerships across Iraq. Whether you're looking for a new or used EV, our platform provides:`,
            `Detailed vehicle listings with specifications and price comparisons.`,
            `Flexible payment options and financing solutions.`,
            `Expert advice on choosing the right EV for your needs.`,
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Selling Electric Cars in Iraq",
      value: "sell",
      content: [
        {
          subTitle: "Why Sell Electric Cars in Iraq?",
          subContent: [
            `As demand for eco-friendly vehicles increases, selling an electric car in Iraq is now more lucrative than ever. Potential buyers are actively looking for fuel-efficient and environmentally friendly options.`,
          ],
        },
        {
          subTitle: "How to Sell Your Electric Car with Ako Sayara",
          subContent: [
            `Ako Sayara makes selling EVs in Iraq simple:`,
            `Create a Listing: Upload details about your car, including photos, specifications, and asking price.`,
            `Reach a Wide Audience: Your car will be visible to thousands of potential buyers across Iraq, including Baghdad, Erbil, and Basra.`,
            `Secure Transactions: We ensure safe and transparent dealings between buyers and sellers.`,
          ],
        },
        {
          subTitle: "Tips for Selling Electric Cars in Iraq",
          subContent: [
            `Highlight unique features like battery health, range, and charging time.`,
            `Provide maintenance records and a vehicle inspection report.`,
            `Price your car competitively by researching market trends for similar EV models.`,
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Renting Electric Cars in Iraq",
      value: "rent",
      content: [
        {
          subTitle: "Why Rent an Electric Car in Iraq?",
          subContent: [
            `Renting an EV is an excellent choice for individuals or businesses:`,
            `Test the EV Experience: Renting allows potential buyers to try an EV before committing to a purchase.`,
            `Cost-Effective for Short-Term Use: Save on fuel costs for business trips or vacations.`,
            `Eco-Friendly Transportation: Ideal for environmentally conscious travelers or companies.`,
          ],
        },
        {
          subTitle: "How to Rent Electric Cars in Iraq",
          subContent: [
            `Ako Sayara offers a seamless way to rent electric cars in Iraq:`,
            `Browse our extensive inventory of EVs available for rent in cities like Sulaymaniyah and Mosul.`,
            `Choose from daily, weekly, or monthly rental plans.`,
            `Benefit from competitive rates and flexible rental terms.`,
          ],
        },
        {
          subTitle: "Popular EV Rentals in Iraq",
          subContent: [
            `Electric cars available for rent through Ako Sayara include:`,
            `Tesla Model Y: Perfect for families or group travel.`,
            `BMW i3: Compact and ideal for city driving.`,
            `Kia EV6: A stylish and high-performance option.`,
          ],
        },
      ],
    },
  ],
  ar: [
    {
      id: 1,
      title: "شراء السيارات الكهربائية في العراق",
      value: "buy",
      content: [
        {
          subTitle: "فوائد شراء السيارات الكهربائية في العراق",
          subContent: [
            `توفير الوقود: مع ارتفاع أسعار الوقود، تلغي EVs الحاجة إلى البنزين أو الديزل`,
            `تقليل تكاليف الصيانة: تحتوي المحركات الكهربائية على أجزاء متحركة أقل، مما يقلل من تكاليف الإصلاح والصيانة.`,
            `الاستقلالية في الطاقة: يمكن شحن EVs باستخدام مصادر الطاقة المتجددة، مثل الألواح الشمسية، مما يجعلها خيارًا مستدامًا.`,
          ],
        },
        {
          subTitle: "نماذج السيارات الكهربائية الشهيرة في العراق",
          subContent: [
            `السيارات الكهربائية من العلامات التجارية العالمية مثل تسلا، نيسان، وهيونداي تزداد شعبيتها. إليك بعض النماذج الشائعة:`,
            `تسلا موديل 3: معروفة بأدائها ومدى طويل`,
            `نيسان ليف: خيار اقتصادي مع موثوقية ممتازة`,
            `هيونداي أيونيك 5: تجمع بين تصميم مستقبلي وأداء عالي`,
            `شيفروليه بولت EV: مدمجة ومثالية للقيادة في المدينة.`,
          ],
        },
        {
          subTitle: "كيف تساعدك اكو سيارة في شراء سيارة كهربائية",
          subContent: [
            `تربطك اكو سيارة مع بائعين ووكلاء معتمدين في جميع أنحاء العراق. سواء كنت تبحث عن EV جديدة أو مستعملة، منصتنا توفر:`,
            `قوائم مفصلة للمركبات مع المواصفات ومقارنات الأسعار.`,
            `خيارات دفع مرنة وحلول تمويل.`,
            `نصائح خبراء لاختيار EV المناسبة لاحتياجاتك.`,
          ],
        },
      ],
    },
    {
      id: 2,
      title: "بيع السيارات الكهربائية في العراق",
      value: "sell",
      content: [
        {
          subTitle: "لماذا تبيع السيارات الكهربائية في العراق؟",
          subContent: [
            `مع زيادة الطلب على المركبات الصديقة للبيئة، أصبح بيع سيارة كهربائية في العراق أكثر ربحية من أي وقت مضى. يبحث المشترون المحتملون بنشاط عن خيارات فعالة في استهلاك الوقود وصديقة للبيئة.`,
          ],
        },
        {
          subTitle: "كيفية بيع سيارتك الكهربائية مع اكو سيارة",
          subContent: [
            `تجعل اكو سيارة بيع EVs في العراق بسيطًا:`,
            `إنشاء قائمة: قم بتحميل تفاصيل عن سيارتك، بما في ذلك الصور، المواصفات، والسعر المطلوب.`,
            `الوصول إلى جمهور واسع: ستكون سيارتك مرئية لآلاف المشترين المحتملين عبر العراق، بما في ذلك بغداد، أربيل، والبصرة.`,
            `معاملات آمنة: نضمن تعاملات آمنة وشفافة بين المشترين والبائعين.`,
          ],
        },
        {
          subTitle: "نصائح لبيع السيارات الكهربائية في العراق",
          subContent: [
            `تسليط الضوء على الميزات الفريدة مثل صحة البطارية، المدى، ووقت الشحن.`,
            `تقديم سجلات الصيانة وتقرير فحص المركبة.`,
            `تسعير سيارتك بشكل تنافسي من خلال البحث في اتجاهات السوق لنماذج EV المماثلة.`,
          ],
        },
      ],
    },
    {
      id: 3,
      title: "تأجير السيارات الكهربائية في العراق",
      value: "rent",
      content: [
        {
          subTitle: "لماذا تستأجر سيارة كهربائية في العراق؟",
          subContent: [
            `تأجير EV خيار ممتاز للأفراد أو الشركات:`,
            `تجربة EV: يتيح التأجير للمشترين المحتملين تجربة EV قبل الالتزام بالشراء.`,
            `فعالة من حيث التكلفة للاستخدام القصير المدى: توفير تكاليف الوقود للرحلات التجارية أو العطلات`,
            `نقل صديق للبيئة: مثالي للمسافرين أو الشركات الواعية بالبيئة.`,
          ],
        },
        {
          subTitle: "كيفية تأجير السيارات الكهربائية في العراق",
          subContent: [
            `توفر اكو سيارة طريقة سلسة لتأجير السيارات الكهربائية في العراق:`,
            `تصفح مخزوننا الواسع من EVs المتاحة للتأجير في مدن مثل السليمانية والموصل.`,
            `اختر من خطط التأجير اليومية، الأسبوعية، أو الشهرية.`,
            `استفد من الأسعار التنافسية وشروط التأجير المرنة.`,
          ],
        },
        {
          subTitle: "السيارات الكهربائية الشهيرة للتأجير في العراق",
          subContent: [
            `تشمل السيارات الكهربائية المتاحة للتأجير عبر اكو سيارة:`,
            `تسلا موديل Y: مثالية للعائلات أو السفر الجماعي.`,
            `بي إم دبليو i3: مدمجة ومثالية للقيادة في المدينة.`,
            `كيا EV6: خيار أنيق وعالي الأداء.`,
          ],
        },
      ],
    },
  ],
  ku: [
    {
      id: 1,
      title: "کڕینی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
      value: "buy",
      content: [
        {
          subTitle: "سوودەکانی کڕینی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
          subContent: [
            `پارەکەوتنی سووتەمەنی: لەگەڵ بەرزبوونی نرخەکانی سووتەمەنی، EVکان پێویستیان بە بەنجین یان دیزل نییە.`,
            `کەمکردنەوەی کۆستەکانی پاراستن: مۆتۆرە ئۆلیکتریکییەکان بەشە هەڵکێشانە کەمتر هەیە، کە کۆستەکانی چاککردن و پاراستن کەم دەکات.`,
            `سەربەخۆیی ئەوەرژی: EVکان دەتوانرێن شارژ بکرێن بە بەکارھێنانی سەرچاوەکانی ئەوەرژیی نوێبوونەوە وەکو پانێلە خۆرەییەکان، کە دەکاتە هەڵبژاردنێکی بەردەوام.`,
          ],
        },
        {
          subTitle: "مۆدێلە شەهیری ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
          subContent: [
            `ئۆتۆمبیلە ئۆلیکتریکییەکان لە مارکە جیهانییە وەکو Tesla، Nissan و Hyundai زۆر گرنگ دەبن. ئەمە هەندێک مۆدێلەی زۆر داواکارییە:`,
            `Tesla Model 3: ناسراوە بە پێدانی و مێژووی درێژ.`,
            `Nissan Leaf: هەڵبژاردنێکی بە بەرزترین بەردەوامی.`,
            `Hyundai Ioniq 5: تێکەڵکردنەوەی دیزاینی داهاتوو لەگەڵ پێدانی باڵا.`,
            `Chevrolet Bolt EV: چکۆل و باشە بۆ شەقامگەشتن`,
          ],
        },
        {
          subTitle:
            "چۆن ئەکۆ سەیارە یارمەتی دەدات لە کڕینی ئۆتۆمبیلە ئۆلیکتریکی",
          subContent: [
            `ئەکۆ سەیارە تۆ پەیوەندیدەکات بە فرۆشیارە دەستنیشانکراوەکان و کۆمپانیاکان لە سەرانسەری عێراق. ئەگەر دەتەوێت EVیەکی نوێ یان بەکارھاتوو، پلاتفۆڕمەکەمان دابین دەکات:`,
            `لیستەکانی وردەبینی ئۆتۆمبیلەکان بە ڕەشنووسی و پێشبینینی نرخەکان.`,
            `هەڵبژاردنەکانی پارەدان و چارەسەری پارەدان.`,
            `ئامۆژگاریی پسپۆران لەسەر هەڵبژاردنی EVی گونجاو بۆ پێویستی تۆ.`,
          ],
        },
      ],
    },
    {
      id: 2,
      title: "فرۆشتنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
      value: "sell",
      content: [
        {
          subTitle: "بۆچی فرۆشتنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق؟",
          subContent: [
            `لەگەڵ زیادبوونی داواکاری بۆ ئۆتۆمبیلە جیهانپارێزییەکان، فرۆشتنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق ئێستا زۆر گەورەترە لە هەر کاتێک. کڕیارە پێشبینکراوەکان بە ئاکتیڤی دەگەڕێنەوە بۆ هەڵبژاردنەکانی سوودوەرگرتنی سووتەمەنی و جیهانپارێزی.`,
          ],
        },
        {
          subTitle: "چۆن ئۆتۆمبیلە ئۆلیکتریکی خۆت بفرۆشی لەگەڵ ئەکۆ سەیارە",
          subContent: [
            `ئەکۆ سەیارە فرۆشتنی EVیەکان لە عێراق ئاسان دەکات:`,
            `دروستکردنی لیستە: زانیاریەکان دەربارەی ئۆتۆمبیلەکەت باربکە، لەوانەیە وێنەکان، ڕەشنووسی، و نرخە داواکراو.`,
            `گەیشتن بۆ کڕیارێکی فراوان: ئۆتۆمبیلەکەت دەبینرێت بۆ هەزاران کڕیارە پێشبینکراو لە سەرانسەری عێراق، لەوانەیە بەغدا، ھەولێر، و بەسڕە.`,
            `مامەڵەکانی پارێزراو: ئێمە دڵنیایی دەدەین لە مامەڵەکانی پارێزراو و ڕوون لە نێوان کڕیار و فرۆشیارەکان.`,
          ],
        },
        {
          subTitle: "ئامۆژگارییەکان بۆ فرۆشتنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
          subContent: [
            `بەرزکردنەوەی تایبەتمەندییە تایبەتەکان وەکو تەندروستی باطری، مێژوو، و کاتی شارژ.`,
            `پێشکەشکردنی تۆمارەکانی پاراستن و ڕاپۆرتی تاقیکردنەوەی سەیارە.`,
            `نرخی ئۆتۆمبیلەکەت بە شێوەیەکی کۆمپێتیتڤ دیاریکە لە ڕێگەی لێکۆڵینەوەی ئاڕاستەکانی بازاڕ بۆ مۆدێلە EVی وەکو.`,
          ],
        },
      ],
    },
    {
      id: 3,
      title: "کرێکردنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
      value: "rent",
      content: [
        {
          subTitle: "بۆچی کرێکردنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق؟",
          subContent: [
            `کرێکردنی EV کارەباڵێکی باشە بۆ کەسان یان کۆمپانیاکان:`,
            `تاقیکردنەوەی EV: کرێکردن ڕێگە دەدات بە کڕیارە پێشبینکراوەکان EVیەک تاقی بکەن پێش ئەوەی وەرگرتنەوەکەی بکەن.`,
            `بەکارهێنانی کەمکۆست بۆ کاتی کورت: پارەکەوتن لە کۆستەکانی سووتەمەنی بۆ گەشتە بازرگانی یان پشووی.`,
            `گواستنەوەی جیهانپارێزی: باشە بۆ گەشتەوان یان کۆمپانیاکانی ئاگادار بە جیهانپارێزی.`,
          ],
        },
        {
          subTitle: "چۆن کرێکردنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق",
          subContent: [
            `ئەکۆ سەیارە ڕێگەیەکی ئاسان دابین دەکات بۆ کرێکردنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق:`,
            `گەڕان لە کۆگای فراوانمان لە EVیەکانی بە کرێ بەردەستن لە شارەکانی وەکو سلێمانی و مووسڵ.`,
            `هەڵبژاردن لە پلانی کرێکردنی ڕۆژانە، ھەفتانە، یان مانگانە.`,
            `سوود لە نرخە کۆمپێتیتڤەکان و مەرجەکانی کرێکردنی گەرمی.`,
          ],
        },
        {
          subTitle: "EVیە شەهیرەکان بۆ کرێکردن لە عێراق",
          subContent: [
            `EVیەکانی بە کرێ بەردەستن لە ڕێگەی ئەکۆ سەیارە دەگرنەوە:`,
            `Tesla Model Y: باشە بۆ خێزان یان گەشتە گروپیەکان.`,
            `BMW i3: چکۆل و باشە بۆ شەقامگەشتن.`,
            `Kia EV6: هەڵبژاردنێکی جێگر و پێدانی باڵا`,
          ],
        },
      ],
    },
  ],
};

export const whyElectricData = {
  title: {
    English: "Why Choose Electric Cars in Iraq?",
    Arabic: "لماذا تختار السيارات الكهربائية في العراق؟",
    Kurdish: "بۆچی هەڵبژاردنی ئۆتۆمبیلە ئۆلیکتریکی لە عێراق؟",
  },
  description: {
    English: `As Iraq advances toward modern infrastructure and sustainability, electric cars (EVs) are becoming a practical and eco-friendly choice for transportation. With cities like Baghdad, Basra, Erbil, Mosul, and Sulaymaniyah embracing cleaner technologies, buying, selling, and renting electric cars in Iraq is gaining traction. Ako Sayara offers a one-stop platform for those looking to transition to electric mobility.`,
    Arabic:
      "مع تقدم العراق نحو البنية التحتية الحديثة والاستدامة، أصبحت السيارات الكهربائية (EVs) خيارًا عمليًا وصديقًا للبيئة للنقل. ومع تبني مدن مثل بغداد والبصرة وأربيل والموصل والسليمانية للتقنيات النظيفة، يزداد انتشار شراء وبيع وتأجير السيارات الكهربائية في العراق. توفر منصة اكو سيارة مكانًا واحدًا لأولئك الذين يتطلعون إلى الانتقال إلى التنقل الكهربائي.",
    Kurdish:
      "لەکاتی پێشکەوتنی عێراق بۆ بنیاتنانی ئەندامێکی بەردەوام و ئەستەنبولی، ئۆتۆمبیلە ئۆلیکتریکییەکان (EVs) بۆنەیەکی کارا و جێبەجێ بۆ گواستنەوە. لە شارەکان وەکو بەغدا، بەسڕە، ھەولێر، مووسڵ و سلێمانی کە تێکنەلۆجیاکانی پاک دەگرن بە بەکار، کڕین، فرۆشتن و کرێکردنی ئۆتۆمبیلە ئۆلیکتریکییەکان لە عێراق بە خاوەنی خێرایی دەبن. ئەکۆ سەیارە پلاتفۆڕمەکی یەکگرتوو دابین دەکات بۆ ئەوانەی کە دەیانەوێت بگۆڕنەوە بۆ مۆبایلیتیی ئۆلیکتریکی.",
  },
  image_text: {
    English: "Electric cars",
    Arabic: "السيارات الكهربائية",
    Kurdish: "سەیارەی کارەبایی",
  },
  // count: "2500",
  stock_image: "/assets/images/electric-stock.png",
  stock_image_style: {
    // marginInlineStart: 0,
  },
  stock_image_className: rtl ? "me-md-5 me-sm-4" : "ms-md-5 ms-sm-4",
};

export const evChallengesData = {
  title: {
    en: "Challenges of electric cars in Iraq",
    ar: "خطوات شراء سيارة جديدة عبر اكو سيارة",
    ku: "هەنگاوەکانی کڕینی ئۆتۆمبێلێکی نوێ لە ڕێگەی ئەکو سەیارە",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/steps-to-buy-new/step-1.svg",
      icon: "marketeq:work",
      title: "Browse listings",
      description: `Visit Ako Sayara’s website or app.
Use filters like brand, price range, and location to find your ideal car.`,
    },
    {
      id: "2",
      image: "/assets/images/steps-to-buy-new/step-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "Compare options",
      description: `Review detailed specifications and photos for each listing.
Compare prices and deals from different dealers.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-3.svg",
      icon: "marketeq:reward",
      title: "Contact dealers",
      description: `Reach out to verified dealers via Ako Sayara to confirm availability and details.
Schedule a visit to inspect the car or request more information.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-4.svg",
      icon: "marketeq:reward",
      title: "Finalize the purchase",
      description: `Negotiate terms, including warranties and insurance.
Complete the payment or financing arrangements.`,
    },
    {
      id: "3",
      image: "/assets/images/steps-to-buy-new/step-5.svg",
      icon: "marketeq:reward",
      title: "Delivery or pickup",
      description: `Choose between picking up the car from the dealership or arranging home delivery.`,
    },
  ],
};
