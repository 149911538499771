import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { rentalCarSlice } from "../../../Reducer/rentalCarSlice";
import PopularModal from "../../Tools/PopularModal";
export default function Rentaldeals({ rtl, page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.rentalCar.rentalcar);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page };
  useEffect(() => {
    dispatch(rentalCarSlice(data));
  }, [dispatch]);
  let responce = searched && searched.length > 0 ? searched : [];
  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(rentalCarSlice(data));
    }
  };

  return (
    <PopularModal
      rtl={rtl}
      responce={responce}
      title={t("Rental deals")}
      path={"hatchback_cars"}
      handleClick={handleClick}
      page={page}
      buttonTitle={"View all rental deals"}
    />
  );
}
