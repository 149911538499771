import { useSelector } from "react-redux";
import { GuideToSellData } from "./data";
import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const GuideToSell = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = GuideToSellData?.[lang] || [];
  const title = GuideToSellData?.title?.[lang] || [];
  const rtl = lang !== "en";
  return (
    <section class="benefits text-center">
      <div class="container">
        <h2 class="hed">{title}</h2>
        <OwlCarousel
          {...{
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: { items: 1, nav: true },
              576: { items: 1, nav: true },
              768: { items: 2, nav: true },
              992: { items: 4, nav: true },
              1200: { items: 4, nav: true },
            },
          }}
          className={`owl-carousel benefits-owl owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
            rtl && "invertButtons"
          }`}
          // margin={10}
          rtl={rtl}
        >
          {data
            ? data.map((item, key) => {
                return (
                  <div class="set h-100 p-1" key={key}>
                    <div
                      className="shadow-sm rounded p-3 d-flex flex-column align-items-center"
                      style={{ minHeight: "270px" }}
                    >
                      <img src={item.image} alt="icon" className="mb-2 mt-2" style={{ width: "unset" }} />
                      <h5>{item.title ? item.title : ""}</h5>
                      <p>{item.description ? item.description : ""} </p>
                    </div>
                  </div>
                );
              })
            : ""}
        </OwlCarousel>
      </div>
    </section>
  );
};

export const WhySellOnAko = () => {
  return (
    <div>wow</div>
  )
}