import React from "react";

function PageSection({ children, className, containerClasses, style, containerStyle }) {
  return (
    <section style={style} className={`place_an ${className || ""}`}>
      <div className={`container faq ${containerClasses || ""}`} style={containerStyle}>{children}</div>
    </section>
  );
}

export default PageSection;
