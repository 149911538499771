import React from "react";
import RecentlyCarView from "../../../Tools/RecentCarView";
import { t } from "i18next";
import { useSelector } from "react-redux";

export default function Recent({ rtl }) {
  const udata = useSelector((state) => state.auth);

  // if (!udata.token) {
  //   return (
  //     <div
  //       className="my-4 text-center d-flex flex-column justify-content-center "
  //       style={{ minHeight: "400px" }}
  //     >
  //       <div
  //         className="my-4 fs-4 fw-semibold"
  //         style={{ color: "var(--text-black-1)" }}
  //       >
  //         Your recently viewed adverts
  //       </div>
  //       <div className=" fs-6">
  //         Adverts you view will get listed here
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <>
      <RecentlyCarView
        rtl={rtl}
        title={"Recently viewed car deals"}
        showTextWhenEmpty
      />
    </>
  );
}
