import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";

const CookieConsent = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [moreOptions, setMoreOptions] = useState(false);
  const [modal, setModal] = useState({ show: false });
  const closeModal = () => {
    setModal({ show: false, data: null });
  };
  const openModal = (data = {}) => {
    setModal({ show: true, data: data });
  };
  const [preferences, setPreferences] = useState({
    analytics: true,
    socialMedia: true,
    advertising: true,
  });

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      openModal();
    } else {
      const storedPreferences = JSON.parse(consent);
      setPreferences(storedPreferences);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", JSON.stringify(preferences), { expires: 365 });
    closeModal();
  };
  const handleReject = () => {
    Cookies.set(
      "cookieConsent",
      JSON.stringify({
        analytics: false,
        socialMedia: false,
        advertising: false,
      }),
      { expires: 365 }
    );
    closeModal();
  };

  const preferencesData = [
    {
      name: "analytics",
      title: t("Allow Analytics Cookies"),
      description: t(
        `We may allow third parties to use analytics cookies to understand how you use our websites so we can make them better and the third parties can develop and improve their products, which they may use on websites that are not owned or operated by us.`
      ),
    },
    {
      name: "socialMedia",
      title: t("Allow Social Media Cookies"),
      description: t(
        `We may use social media cookies to show you content based on your social media profiles and activity on our platform. They are used to connect your activity on our website to your social media profiles so the content you see on our website and on social media will better reflect your interests.`
      ),
    },
    {
      name: "advertising",
      title: t("Allow Advertising Cookies"),
      description: t(
        `Enable the use of cookies for making advertising more relevant and to support the sourcing of high-quality content on this site. If you do not allow this use, then ads shown to you may be less relevant.`
      ),
    },
  ];

  return (
    <div>
      <Modal
        isOpen={modal.show}
        // toggle={closeModal}
        size="lg"
        centered
        style={{ position: "relative" }}
      >
        <ModalHeader
          toggle={closeModal}
          className="border-0 px-4 pt-4 pb-0"
          // style={{ alignSelf: "end" }}
          style={{ color: "#324760" }}
        >
          {moreOptions ? (
            <h4 className="d-flex align-items-center gap-3 fs-6">
              <Icon
                role="button"
                onClick={() => setMoreOptions(false)}
                icon="eva:arrow-back-fill"
                height={30}
                style={{
                  color: "#324760",
                  transform: lang === "en" ? "unset" : `rotate(180deg)`,
                }}
              />
              {t("Manage cookie preferences")}
            </h4>
          ) : (
            <img height={35} src={`../assets/images/${t("MainIcon")}`} />
          )}
        </ModalHeader>
        <ModalBody className="p-4" style={{ color: "#324760" }}>
          {moreOptions ? (
            <>
              {preferencesData.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="shadow-sm rounded-1 p-2 mb-2"
                    style={{ minHeight: "120px" }}
                  >
                    <label
                      htmlFor={`switchCookie${item?.name}`}
                      className="d-flex justify-content-between mb-1 fw-semibold fs-6"
                    >
                      <div>{item?.title}</div>
                      <FormGroup
                        switch
                        style={{ paddingInline: lang !== "en" ? "2.5em" : "" }}
                      >
                        <Input
                          id={`switchCookie${item?.name}`}
                          type="switch"
                          onChange={(e) =>
                            setPreferences({
                              ...preferences,
                              [item.name]: !preferences[item.name],
                            })
                          }
                          checked={preferences[item?.name]}
                          role="switch"
                        />
                      </FormGroup>
                    </label>
                    <p className="mb-0">{item?.description}</p>
                  </div>
                );
              })}
              <div className="mt-3 d-flex justify-content-end gap-3">
                <Button
                  className="themeButton"
                  disabled={
                    !preferences.analytics &&
                    !preferences?.advertising &&
                    !preferences?.socialMedia
                  }
                  style={{
                    fontSize: "15px",
                    height: "36px",
                    backgroundColor: "#324760",
                    boxShadow: "1px 1px 2px 1px #324760",
                  }}
                  onClick={() => handleAccept()}
                >
                  {t("Save settings")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <h6 className="mb-4">
                {t(
                  `Our platform uses cookies and similar technologies to deliver, maintain and improve our services and ads. If you agree, we will use this data for ads personalisation and associated analytics.`
                )}
              </h6>
              <h6 className="my-4">
                {t(
                  `You can select 'Accept' to consent to these uses, 'Reject' to decline these uses or click on 'More options' to review your options.`
                )}
              </h6>
              <div className="row m-0">
                <div className="mb-3 col-lg-6 d-flex justify-content-center align-items-center p-0">
                  <h6
                    className=""
                    style={{
                      fontSize: "17px",
                      color: "#4c7ee8",
                      boxShadow: "0px 1px 0px 0px #4c7ee8",
                    }}
                  >
                    <a onClick={(e) => setMoreOptions(true)}>
                      {t("View more options")}
                    </a>
                  </h6>
                </div>
                <div className="mb-3 col-lg-6 d-flex justify-content-end gap-3 ps-lg-0">
                  <div className="col col-lg-4">
                    <Button
                      className="themeButton w-100"
                      style={{
                        fontSize: "15px",
                        height: "36px",
                        backgroundColor: "#fff",
                        boxShadow: "1px 1px 2px 1px #324760",
                        color: "#324760",
                      }}
                      onClick={() => handleReject()}
                    >
                      {t("Reject")}
                    </Button>
                  </div>
                  <div className="col col-lg-4">
                    <Button
                      className="themeButton w-100"
                      style={{
                        fontSize: "15px",
                        height: "36px",
                        backgroundColor: "#324760",
                        boxShadow: "1px 1px 2px 1px #324760",
                      }}
                      onClick={() => handleAccept()}
                    >
                      {t("Accept")}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CookieConsent;
