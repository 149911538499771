import React from "react";
import SellRentIntro from "../SellRentIntro";
import { paths } from "../../../utils/paths";

function SellYourCarPage() {
  return (
    <SellRentIntro
      page={"normal"}
      title={"Sell Your Car"}
      startLink={paths.sellYourCar}
    />
  );
}

export default SellYourCarPage;
