import React, { useEffect, useRef, useState } from "react";
import ListItem from "../../Tools/ListItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NewcarForm from "../NewCars/NewcarForm";
import { Newcars } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import {
  getAdFiltersList1,
  getAllModels,
  getMake,
  getModels,
} from "../../../Reducer/api";
import {
  getAllFiltersData,
  getAllFiltersData1,
  getSelectedFilters,
} from "../../../utils/constants";
import { bannerNew } from "../../../utils/paths";
export default function NewcarBanner({ carTypes, page, filters }) {
  const { t, i18n } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.newcarsdata);
  const loading = useSelector((state) => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Newcars());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    bodyType: [],
    price: {
      min: "",
      max: "",
    },
  };

  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState({
    ...defaultValues,
    ...filters,
  });
  const [selectedFilters, setSelectedFilters] = useState(["fuel"]);
  const filtersRef = useRef(filterData);

  useEffect(() => {
    setModels([]);
    if (filterData.make) {
      getModels({
        makeId: filterData.make,
        live: true,
        ownerId: "",
        page: page,
        filters: filters,
      }).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }
  }, [filterData.make]);

  useEffect(() => {
    console.log(filterData);
    getFilters();
  }, [filterData]);

  const resetFilters = () => {
    setFilterData({
      ...defaultValues,
      ...filters,
    });
    setModels([]);
  };

  const [adFiltersList, setAdFiltersList] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const getFilters = async () => {
    const keys = ["cities", "bodyType", "makes"];
    const selected = getSelectedFilters(
      selectedFilters,
      setSelectedFilters,
      filterData,
      filtersRef
    );
    keys.forEach(async (key) => {
      let response = await getAdFiltersList1(
        page,
        "",
        filterData,
        selected,
        key
      );
      setAdFiltersList((adFiltersList) => ({
        ...adFiltersList,
        ...getAllFiltersData1(response),
      }));
    });
  };

  return (
    <section
      class="banner"
      style={{
        backgroundImage: `url(${
          bannerNew ? bannerNew : "/assets/images/newcar.jpg"
        })`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="setform account-card">
              <h5>{t("Brand new cars")}</h5>
              <div class="tab-content" id="myTabContent">
                <NewcarForm
                  data={detail}
                  carTypes={carTypes}
                  models={models}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  adFiltersList={adFiltersList}
                  loading={filtersLoading}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </section>
  );
}
