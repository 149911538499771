import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CompareBox from "../../../Tools/CompareBox";
import Button from "../../../Tools/Button";
import { fetchSaved } from "../../../../Reducer/savedSlice";
import { homeOptions } from "../../../../data/content";
import OwlItemSaved from "../../../Tools/OwlItem/owlItemSaved";
import ReactSelectCustom from "../../../Tools/ReactSelectCustom";
import sortBy from "../../../../data/sortBy.json";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Skeletons from "../../../Tools/Skeletons";
import { paths } from "../../../../utils/paths";

export default function Adverts({ rtl }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const savedApiData = useSelector((state) => state.saved.saved);
  const loading = useSelector((state) => state.saved.loading);
  const error = useSelector((state) => state.saved.error);
  const udata = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState("");
  const [comp, setcomp] = useState([]);
  const [sortVal, setSortVal] = useState({ sort: "", order: "" });
  const [saved, setSaved] = useState(null);
  useEffect(() => {
    const data = location?.state?.cars;
    if (data && data?.length > 0) {
      console.log(data);
      setcomp(data);
      clearAll(true, "saved");
    }
  }, [location]);

  useEffect(() => {
    if (savedApiData?.ads?.length > 0) {
      setSaved(savedApiData?.ads);
    }
  }, [savedApiData]);

  useEffect(() => {
    dispatch(
      fetchSaved({
        sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
      })
    );
  }, [dispatch, page, sortVal]);

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchSaved());
    }
  };
  const clearAll = (state, page) => {
    setShow(state);
    setPage(page);
    if (!state) {
      setcomp([]);
    }
  };

  useEffect(() => {
    if (saved && saved?.length > 0) {
      const selected = comp.map((v) => v?._id);
      setSaved(saved.filter((v) => !selected?.includes(v?._id)));
    }
  }, [comp]);

  if (!udata.token || !savedApiData) {
    return (
      <div className="my-4 text-center d-flex flex-column justify-content-center " style={{ minHeight: "400px" }}>
        <div
          className="my-4 fs-4 fw-semibold"
          style={{ color: "var(--text-black-1)" }}
        >
          {t('Save your favourite adverts')}
        </div>
        <div className=" fs-6">
         {t('Save an advert, and view across all your devices')}
        </div>
        {!udata.token && (
          <div className="bg-light my-4 pb-4 pt-3 rounded-1">
            <div
              className="mb-4 fs-4 fw-semibold"
              style={{ color: "var(--text-black-1)" }}
            >
              {t('Manage your saved adverts')}
            </div>
            <div className="mb-4 fs-6">
              {t('Simply sign in or register to manage your saved adverts')}
            </div>
            <div className="d-flex justify-content-center">
              <NavLink
                className={"viewall"}
                to={!udata.token ? paths.login : paths.saved}
                state={!udata.token ? { backPage: paths.saved } : null}
              >
                {t("Sign in / Register")}
              </NavLink>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {location?.state?.cars?.length > 0 && (
        <h6 class="back mb-3">
          <a onClick={(e) => navigate(-1)}>
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </a>
        </h6>
      )}
      {show === true && (
        <CompareBox
          data={comp}
          onChange={setcomp}
          mainTitle={t("Compare")}
          subTitle={`${comp.length + " " + t("Vehicles selected")}`}
          buttonTitle={t("Compare now")}
          onClick={() => {
            clearAll(false, "");
          }}
        />
      )}
      <div style={{ minHeight: "100vh" }}>
        <div className="row mb-4">
          <div className="col-sm-7 col-12 mb-2">
            <span className="fs-5 fw-semibold text-center w-100">
              {savedApiData?.totalAds
                ? savedApiData?.totalAds + " " + t("Vehicles saved")
                : ""}
            </span>
            <br />
          </div>
          <div className="col-sm-5 col-12 mb-2">
            <span className="d-flex align-items-center justify-content-end">
              <img
                src="assets/images/sort-descending.png"
                height={18}
                className="px-2"
                alt="icon"
              />{" "}
              <ReactSelectCustom
                values={sortBy.sortData}
                width={"180px"}
                // boxWidth={'140px'}
                placeholder={""}
                selectedOption={t("Relevance")}
                onChange={(e) =>
                  setSortVal({
                    sort: e.item.sort,
                    order: e.item.order,
                  })
                }
              />
            </span>
          </div>
        </div>
        {loading ? (
          // <div
          //   class="d-flex justify-content-center"
          //   style={{ minHeight: "25vh", marginTop: "25vh" }}
          // >
          //   <div class="spinner-grow" role="status">
          //     <span class="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <Skeletons forPage={"owlItems"} />
        ) : (
          <>
            <div className="d-sm-block">
              <OwlCarousel
                className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${
                  rtl && "invertButtons"
                }`}
                {...homeOptions}
                // margin={10}
                rtl={rtl}
                nav
              >
                <OwlItemSaved
                  key={0}
                  checkBoxText={t("Comparing")}
                  iconText={t("heartSaved")}
                  responce={saved?.ads}
                  selectedList={comp}
                  onChange={setcomp}
                  page={page}
                  adsArray={saved?.allSelectedAds}
                />
              </OwlCarousel>
            </div>
            {/* <div className="d-sm-none d-block saved_cr">
        {saved?.length > 0 ? (
          <OwlItemSaved
            key={1}
            iconText={t("Saved")}
            checkBoxText={t("Comparing")}
            responce={saved}
            selectedList={comp}
            onChange={setcomp}
            page={page}
            adsArray={saved?.allSelectedAds}
          />
        ) : (
          loading == true && t("Please wait...")
        )}
      </div> */}

            {saved && (
              <div class="text-center stscrl">
                {show == false ? (
                  <>
                    <Button
                      style="srcbtn"
                      onClick={() => {
                        clearAll(true, "saved");
                      }}
                      title={t("Compare now")}
                    />
                    <br />
                  </>
                ) : (
                  <Button
                    style="reset"
                    onClick={() => {
                      clearAll(false, "");
                    }}
                    title={t("Cancel")}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
