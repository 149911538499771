import React from "react";

function BrandsSkeletons({ CustomSkeleton }) {
  return (
    <>
      <div class="set d-none d-md-flex flex-column">
        <CustomSkeleton circle={true} height={40} width={40} />
        <CustomSkeleton height={15} className={"w-50 mt-3"} />
      </div>
      <div class="set d-flex d-md-none flex-column">
        <CustomSkeleton circle={true} height={25} width={25} />
        <CustomSkeleton height={15} className={"w-50 mt-2"} />
      </div>
    </>
  );
}

export default BrandsSkeletons;
