const rtl = localStorage.getItem('cl') !== 'English'

export const WhyUsedCarsData = {
  en: [
    {
      title: "Why choose used cars?",
      description:
        "Opting for a used car offers several benefits, making it a popular choice among buyers in Iraq:",
      image: "/assets/images/used-cars/why-used-cars.png",
      imageStyle: {
        maxHeight: 400,
        // minHeight: "400px"
        // width: "",
      },
      features: [
        {
          title: "Cost savings",
          description: `Used cars are significantly cheaper than brand-new models, helping you save money upfront.
Lower depreciation rates mean better value for money.`,
        },
        {
          title: "Wide selection",
          description: `Choose from a variety of brands, models, and years to match your budget and preferences.`,
        },
        {
          title: "Lower insurance costs",
          description: `Insurance for used cars typically costs less than for new vehicles, further reducing ownership expenses.`,
        },
        {
          title: "Immediate availability",
          description: `Unlike new cars, which may require waiting periods, used cars are readily available for purchase.`,
        },
      ],
    },
    {
      title: "How to find the best used car deals on Ako Sayara",
      description:
        "Ako Sayara is designed to help you find the perfect used car quickly and easily. Here’s how to make the most of it:",
        image: "/assets/images/used-cars/how-to-used.png",
      imageStyle: {
        maxHeight: 370,
        width: "",
      },
      features: [
        {
          title: "Use advanced search filters",
          description: `Refine your search by brand, model, price range, mileage, condition, and location.
Focus on cars within your budget and preferences to save time.`,
        },
        {
          title: "Compare listings",
          description: `Browse multiple listings to compare prices and features.
Look for detailed descriptions and high-quality photos for better insights.`,
        },
        {
          title: "Contact verified sellers",
          description:
            "Ako Sayara ensures that all sellers on the platform are verified, providing a safe and secure buying experience.",
        },
        {
          title: "Check for special offers",
          description:
            "Some sellers provide promotional discounts or bundled packages, such as free servicing or extended warranties.",
        },
        {
          title: "Schedule test drives",
          description:
            "Always arrange a test drive to inspect the car’s condition and performance before making a purchase.",
        },
      ],
    },
    {
      title: "Tips for getting the best used car deals",
      description:
        "Follow these practical tips to secure the best used car deals on Ako Sayara:",
        image: "/assets/images/used-cars/tips-used-car.png",
      imageStyle: {
        maxHeight: 240,
        width: "",
      },
      features: [
        {
          title: "Research the market",
          description:
            "Compare similar cars to understand the market value and avoid overpaying.",
        },
        {
          title: "Check vehicle history",
          description:
            "Request maintenance records, accident reports, and ownership details to ensure transparency.",
        },
        {
          title: "Inspect the car",
          description: "Examine the car for signs of wear, rust, or damage.",
        },
        {
          title: "Negotiate smartly",
          description:
            "Use market research to negotiate a fair price with the seller.",
        },
        {
          title: "Verify legal documents",
          description:
            "Ensure all registration, tax, and transfer documents are in order before completing the sale.",
        },
      ],
    },
  ],
  ar: [
    {
      title: "لماذا تختار السيارات المستعملة؟",
      description:
        "اختيار السيارات المستعملة يقدم العديد من الفوائد، مما يجعلها خيارًا شائعًا بين المشترين في العراق:",
      image: "/assets/images/used-cars/why-used-cars.png",
      imageStyle: {
        maxHeight: 400,
        // minHeight: "400px"
        // width: "",
      },
      features: [
        {
          title: "1. توفير التكاليف",
          description: `السيارات المستعملة أرخص بكثير من الموديلات الجديدة، مما يساعدك على توفير المال.
معدلات الإهلاك الأقل تعني قيمة أفضل مقابل السعر.`,
        },
        {
          title: "مجموعة واسعة",
          description: `اختر من بين مجموعة متنوعة من العلامات التجارية والموديلات والسنوات لتناسب ميزانيتك وتفضيلاتك.`,
        },
        {
          title: " تكاليف تأمين أقل",
          description: `عادةً ما يكون تأمين السيارات المستعملة أقل تكلفة من السيارات الجديدة، مما يقلل من نفقات الملكية.`,
        },
        {
          title: "التوفر الفوري",
          description: `على عكس السيارات الجديدة التي قد تتطلب فترات انتظار، السيارات المستعملة متوفرة فورًا للشراء.`,
        },
      ],
    },
    {
      title: "كيفية العثور على أفضل عروض السيارات المستعملة على اكو سيارة",
      description:
        "تم تصميم اكو سيارة لمساعدتك في العثور على السيارة المستعملة المثالية بسرعة وسهولة. إليك كيفية تحقيق أقصى استفادة من المنصة:",
        image: "/assets/images/used-cars/how-to-used.png",
      imageStyle: {
        maxHeight: 370,
        width: "",
      },
      features: [
        {
          title: "استخدم مرشحات البحث المتقدمة",
          description: `قم بتحديد بحثك حسب العلامة التجارية، الموديل، نطاق السعر، المسافة المقطوعة، الحالة، والموقع.
ركز على السيارات التي تتوافق مع ميزانيتك و تفضيلاتك لتوفير الوقت.`,
        },
        {
          title: " قارن الإعلانات",
          description: `تصفح قوائم متعددة لمقارنة الأسعار والمواصفات.
ابحث عن أوصاف تفصيلية وصور عالية الجودة للحصول على رؤية أفضل.`,
        },
        {
          title: " تواصل مع البائعين الموثوقين",
          description:
            "تضمن اكو سيارة أن جميع البائعين على المنصة موثقون، مما يوفر تجربة شراء آمنة.",
        },
        {
          title: " تحقق من العروض الخاصة",
          description:
            "بعض البائعين يقدمون خصومات ترويجية أو باقات مميزة مثل الصيانة المجانية أو الضمانات الممتدة.",
        },
        {
          title: "قم بجدولة تجربة قيادة",
          description:
            "احرص دائمًا على ترتيب تجربة قيادة لفحص حالة وأداء السيارة قبل الشراء.",
        },
      ],
    },
    {
      title: "نصائح للحصول على أفضل العروض للسيارات المستعملة",
      description:
        "اتبع هذه النصائح العملية لضمان الحصول على أفضل العروض عبر اكو سيارة:",
        image: "/assets/images/used-cars/tips-used-car.png",
      imageStyle: {
        maxHeight: 240,
        width: "",
      },
      features: [
        {
          title: " ابحث في السوق",
          description:
            "قارن السيارات المشابهة لفهم القيمة السوقية وتجنب الدفع الزائد.",
        },
        {
          title: "تحقق من تاريخ السيارة",
          description:
            "اطلب سجلات الصيانة، تقارير الحوادث، وتفاصيل الملكية لضمان الشفافية.",
        },
        {
          title: " افحص السيارة",
          description: "قم بفحص السيارة للتحقق من وجود علامات تلف أو صدأ.",
        },
        {
          title: " تفاوض بذكاء",
          description:
            "استخدم أبحاث السوق للتفاوض على سعر عادل مع البائع.",
        },
        {
          title: " تحقق من الوثائق القانونية",
          description:
            "تأكد من أن جميع مستندات التسجيل والضرائب والنقل سليمة قبل إتمام البيع.",
        },
      ],
    },
  ],
  ku: [
    {
      title: "بۆچی ئۆتۆمبێلی بەکارهاتوو هەڵبژێرین؟",
      description:
        "هەڵبژاردنی ئۆتۆمبێلی بەکارهاتوو چەندین سوودی هەیە، ئەمەش وایکردووە ببێتە هەڵبژاردەیەکی بەناوبانگ لەنێو کڕیاران لە عێراق:",
      image: "/assets/images/used-cars/why-used-cars.png",
      imageStyle: {
        maxHeight: 400,
        // minHeight: "400px"
        // width: "",
      },
      features: [
        {
          title: ".پاشەکەوتکردنی تێچوون",
          description: `ئۆتۆمبێلی بەکارهاتوو زۆر هەرزانترە لە مۆدێلە نوێیەکان، ئەمەش یارمەتیت دەدات پارە پاشەکەوت بکەیت.
کەمبوونەوەی ڕێژەی بەکاربردن بە واتای بەهای باشتر بۆ پارە دێت.`,
        },
        {
          title: "مەودای فراوان",
          description: `لە نێوان جۆرەها براند و مۆدێل و ساڵێکدا هەڵبژێرە کە لەگەڵ بودجە و ئارەزووەکانتدا بگونجێت.`,
        },
        {
          title: "کەمکردنەوەی تێچووی بیمە",
          description: `بەزۆری بیمەی ئۆتۆمبێلی بەکارهاتوو لە ئۆتۆمبێلە نوێیەکان کەمترە، ئەمەش خەرجییەکانی خاوەندارێتی کەمدەکاتەوە.`,
        },
        {
          title: "پاشەکەوتکردنی خێرا",
          description: `بە پێچەوانەی ئۆتۆمبێلە نوێیەکان کە ڕەنگە ماوەی چاوەڕوانییان هەبێت، ئۆتۆمبێلی بەکارهاتوو دەستبەجێ بەردەستە بۆ کڕین.`,
        },
      ],
    },
    {
      title: "چۆنیەتی دۆزینەوەی باشترین گرێبەستی ئۆتۆمبێلی بەکارهاتوو لە ئەکو سەیارە",
      description:
        "ئەکو سەیارە بە شێوەیەک دروست کراوە کە یارمەتیت بدات ئۆتۆمبێلی بەکارهاتووی تەواو بە خێرایی و ئاسانی بدۆزیتەوە. لێرەدا چۆنێتی بەدەستهێنانی زۆرترین سوود لە پلاتفۆرمەکە دەخەینەڕوو:",
        image: "/assets/images/used-cars/how-to-used.png",
      imageStyle: {
        maxHeight: 370,
        width: "",
      },
      features: [
        {
          title: " فلتەری گەڕانی پێشکەوتوو بەکاربهێنە",
          description: `گەڕانەکەت بەپێی مارکە، مۆدێل، مەودای نرخ، میل، بارودۆخ و شوێن وردتر بکەرەوە.
سەرنجت لەسەر ئەو ئۆتۆمبێلانە بێت کە لەگەڵ بودجە و ئارەزووەکانتدا بگونجێت بۆ ئەوەی کاتت بۆ پاشەکەوت بکەیت.`,
        },
        {
          title: "بەراوردکردنی ڕێکلامەکان",
          description: `بەدوای چەندین لیستدا بگەڕێ بۆ بەراوردکردنی نرخ و تایبەتمەندییەکان.
بەدوای وەسفی ورد و وێنەی کوالێتی بەرزدا بگەڕێ بۆ بینینی باشتر.`,
        },
        {
          title: "پەیوەندی لەگەڵ فرۆشیارە متمانەپێکراوەکان",
          description:
            "ئەکو سەیارە دڵنیای دەدات لەوەی کە هەموو فرۆشیارانی سەر پلاتفۆرمەکە پشتڕاست دەکرێنەوە، ئەزموونێکی سەلامەتی کڕین دابین دەکات.",
        },
        {
          title: "سەیری تایبەتمەندیەکانمان بکەن",
          description:
            "هەندێک لە فرۆشیارەکان داشکاندنی بانگەشە یان پاکێجی تایبەت وەک چاککردنەوەی بێبەرامبەر یان گەرەنتی درێژکراوە پێشکەش دەکەن.",
        },
        {
          title: "خشتەی تاقیکردنەوەیەک دابنێ",
          description:
            "هەمیشە پێش کڕینی ئۆتۆمبێلەکە تاقیکردنەوەیەک ڕێکبخە بۆ پشکنینی بارودۆخ و کارایی ئۆتۆمبێلەکە.",
        },
      ],
    },
    {
      title: "ئامۆژگاری بۆ بەدەستهێنانی باشترین گرێبەست لەسەر ئۆتۆمبێلی بەکارهاتوو",
      description:
        "ئەم ئامۆژگارییە کردارییانە جێبەجێ بکە بۆ ئەوەی دڵنیا بیت کە باشترین گرێبەستەکانت لە ڕێگەی ئەکو سەیارەوە بەدەست دەهێنیت:",
        image: "/assets/images/used-cars/tips-used-car.png",
      imageStyle: {
        maxHeight: 240,
        width: "",
      },
      features: [
        {
          title: "لە بازاڕدا بگەڕێ",
          description:
            "بەراوردکردنی ئۆتۆمبێلی هاوشێوە بۆ تێگەیشتن لە بەهای بازاڕ و دوورکەوتنەوە لە زیادەڕۆیی لە پارەدان.",
        },
        {
          title: "مێژووی ئۆتۆمبێلەکە بپشکنە",
          description:
            "داوای تۆماری چاککردنەوە، ڕاپۆرتی ڕووداوەکان، و وردەکارییەکانی خاوەندارێتی بکە بۆ دڵنیابوون لە شەفافیەت.",
        },
        {
          title: "ئۆتۆمبێلەکە بپشکنە",
          description: "پشکنینی ئۆتۆمبێلەکە بکە بۆ زانینی نیشانەکانی تێکچوون یان ژەنگ.",
        },
        {
          title: "دانوستان بە زیرەکی",
          description:
            "توێژینەوەی بازاڕ بەکاربهێنە بۆ دانوستان لەسەر نرخێکی دادپەروەرانە لەگەڵ فرۆشیار.",
        },
        {
          title: "بەڵگەنامە یاساییەکان بپشکنە",
          description:
            "پێش تەواوکردنی فرۆشتنەکە دڵنیابە لە ڕێکخستنی هەموو بەڵگەنامەکانی تۆمارکردن و باج و گواستنەوە.",
        },
      ],
    },
  ],
};

export const StockSectionData = {
  title: {
    English: "How to get the best offers with Ako Sayara",
    Arabic: "كيف تحصل على أفضل العروض مع اكو سيارة",
    Kurdish: "چۆن باشترین گرێبەستەکان لەگەڵ ئەکو سەیارە",
  },
  description: {
    English:
      "Finding the perfect used car deal in Iraq can be a rewarding experience when you know where to look. Ako Sayara, Iraq’s leading platform for car buying, selling, and renting, makes it easy to find high-quality used cars at competitive prices. Whether you're in Baghdad, Erbil, Basra, Mosul, or Sulaymaniyah, Ako Sayara connects you with thousands of reliable sellers and great deals.",
    Arabic:
      "البحث عن السيارة المستعملة المثالية في العراق يمكن أن يكون تجربة مجزية عندما تعرف أين تبحث. اكو سيارة، المنصة الرائدة في العراق لشراء، بيع، وتأجير السيارات، تسهل عليك العثور على سيارات مستعملة عالية الجودة بأسعار تنافسية. سواء كنت في بغداد، أربيل، البصرة، الموصل، أو السليمانية، اكو سيارة تربطك بآلاف البائعين الموثوقين وأفضل العروض.",
    Kurdish:
      "دۆزینەوەی ئۆتۆمبێلی بەکارهاتووی نمونەیەکەت لە عێراقدا دەتوانێت ئەزموونێکی پاداشت بەخش بێت کاتێک دەزانیت لە کوێدا بگەڕێیت. ئەکو سەیارە، پلاتفۆرمی پێشەنگی عێراق بۆ کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل، ئاسانکاریت بۆ دەکات بۆ دۆزینەوەی ئۆتۆمبێلی بەکارهاتووی کوالێتی بەرز بە نرخێکی کێبڕکێکار. جا لە بەغدا بیت، هەولێر، بەسرە، موسڵ، یان سلێمانی، ئەکو سەیارە بە هەزاران فرۆشیاری متمانەپێکراو و باشترین مامەڵەکانەوە دەتبەستێتەوە.",
  },
  image_text: {
    English: "Used car deals in Iraq",
    Arabic: "أفضل عروض السيارات المستعملة في العراق",
    Kurdish: "باشترین گرێبەستی ئۆتۆمبێلی بەکارهێنراو لە عێراق",
  },
  // count: "2500",
  stock_image: "/assets/images/used-car-below-banner.png",
  stock_image_className: rtl ? "me-md-5 me-sm-4" : "ms-md-5 ms-sm-4",
  stock_image_style: {
    // marginInlineStart: 0
  }
};

export const BenefitsUsedData = {
  title: {
    en: "Advantages of buying through Ako Sayara",
    ar: "مزايا الشراء عبر اكو سيارة",
    ku: "سوودەکانی کڕین لە ڕێگەی ئەکو سەیارەوە",
  },
  en: [
    {
      id: "1",
      image: "/assets/images/benefit-used/benefit-1.svg",
      icon: "marketeq:work",
      title: "Extensive listings",
      description: `Access thousands of used cars from private sellers and trusted dealers across Iraq.`,
    },
    {
      id: "2",
      image: "/assets/images/benefit-used/benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "Verified sellers",
      description: `Every listing is vetted, ensuring buyers connect only with genuine and reliable sellers.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-3.svg",
      icon: "marketeq:reward",
      title: "Comprehensive details",
      description: `Listings include car specifications, photos, mileage, and seller contact information.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-4.svg",
      icon: "marketeq:reward",
      title: "Seamless communication",
      description: `Contact sellers directly through the platform to negotiate prices and finalize deals.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-5.svg",
      icon: "marketeq:reward",
      title: "Buyer support",
      description: `Ako Sayara offers 24/7 customer support to assist with queries and transactions.`,
    },
  ],
  ar: [
    {
      id: "1",
      image: "/assets/images/benefit-used/benefit-1.svg",
      icon: "marketeq:work",
      title: "قوائم واسعة",
      description: `الوصول إلى آلاف السيارات المستعملة من البائعين الأفراد والوكلاء الموثوقين في جميع أنحاء العراق.`,
    },
    {
      id: "2",
      image: "/assets/images/benefit-used/benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "بائعون موثوقون",
      description: `يتم فحص جميع القوائم للتأكد من أن المشترين يتواصلون فقط مع البائعين الحقيقيين والموثوقين.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-3.svg",
      icon: "marketeq:reward",
      title: "تفاصيل شاملة",
      description: `تحتوي القوائم على مواصفات السيارة، صور، المسافة المقطوعة، ومعلومات الاتصال بالبائع.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-4.svg",
      icon: "marketeq:reward",
      title: "تواصل سلس",
      description: `تواصل مع البائعين مباشرة عبر المنصة للتفاوض وإتمام الصفقات.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-5.svg",
      icon: "marketeq:reward",
      title: "دعم العملاء",
      description: `توفر اكو سيارة دعم العملاء على مدار الساعة للمساعدة في الاستفسارات والمعاملات.`,
    },
  ],
  ku: [
    {
      id: "1",
      image: "/assets/images/benefit-used/benefit-1.svg",
      icon: "marketeq:work",
      title: "لیستە بەرفراوانەکان",
      description: `دەستت بگات بە هەزاران ئۆتۆمبێلی بەکارهاتوو لە تاکە فرۆشیارەکان و بریکارە متمانەپێکراوەکان لە سەرانسەری عێراق.`,
    },
    {
      id: "2",
      image: "/assets/images/benefit-used/benefit-2.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "فرۆشیارانی متمانەپێکراو",
      description: `هەموو لیستەکان سکرین دەکرێن بۆ ئەوەی دڵنیابن لەوەی کڕیاران تەنها پەیوەندی لەگەڵ فرۆشیارە ڕاستەقینە و متمانەپێکراوەکاندا دەکەن.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-3.svg",
      icon: "marketeq:reward",
      title: "وردەکاری گشتگیر",
      description: `لیستەکان تایبەتمەندی ئۆتۆمبێل، وێنە، میل و زانیاری پەیوەندی فرۆشیار لەخۆدەگرن.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-4.svg",
      icon: "marketeq:reward",
      title: "پەیوەندییەکی نەرم و نیان",
      description: `ڕاستەوخۆ لە ڕێگەی پلاتفۆرمەکەوە پەیوەندی لەگەڵ فرۆشیارەکان بکە بۆ دانوستاندن و داخستنی گرێبەستەکان.`,
    },
    {
      id: "3",
      image: "/assets/images/benefit-used/benefit-5.svg",
      icon: "marketeq:reward",
      title: "پشتگیری کڕیار",
      description: `ئەکو سەیارە ٢٤/٧ پشتگیری کڕیاران پێشکەش دەکات بۆ یارمەتیدان لە پرسیار و مامەڵەکان.`,
    },
  ],
};
