import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CheckMyCar from "./CheckMyCar";
import { clearStatus, fromChacie } from "../../../Reducer/chaiceSlice";
import { cities, errorSms, title } from "../../../data/content";
import { toast } from "react-toastify";
import { dataInFormatCheck } from "./dataFormatUtil";
import { beginWithImage, getAllFiltersData1 } from "../../../utils/constants";
import withoutVin from "../../../data/withoutVin.json";
import { paths } from "../../../utils/paths";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import { getAdFiltersList1 } from "../../../Reducer/api";
export default function CheckCar() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.chacie.submitting);
  const chasis = useSelector((state) => state.chacie.chacie);
  const error = useSelector((state) => state.chacie.error);
  const success = useSelector((state) => state.chacie.success);
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [vin, setVin] = useState(null);
  const [carData, setCarData] = useState({});
  // const [startup, setStart] = useState("FirstChildVIn");
  // const [details, setDetails] = useState({});

  // const onPaymentComplete = (data) => {
  //   console.log(vin);
  //   dispatch(fromChacie({ chasis: vin, type: "check" }));
  //   // setCarData(findMyCarDataStatic)
  //   // setStep(3)
  // };

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      dispatch(fromChacie({ chasis: location.state, type: "check" }));
    }
    testing();
  }, [location]);

  const testing = async () => {
    let allFiltersList = null;
    const keys = [
      "cities",
      "condition",
      "paint",
      "colour",
      "regional",
      "gearbox",
      "fuel",
      "bodyType",
      "makes",
      "yearsList",
    ];
    keys.forEach(async (key) => {
      const response = await getAdFiltersList1("normal", "", {}, "", key);
      console.log(response);
    });
    // const allApiData = await Promise.all([
    //   getAdFiltersList1("normal", "", {}, "", "").then(
    //     (res) => (allFiltersList = res)
    //   ),
    // ]);
    // allFiltersList = allApiData[0];
    // const data = getAllFiltersData1(allFiltersList);
    // console.log(allFiltersList, data);
  };

  const onError = (errors) => {
    console.log(errors);
  };

  if (success == true) {
    const result = chasis?.result?.report;
    let updatedResult = dataInFormatCheck(result);
    // setCarData(updatedResult);
    // setStep(3);
    // if (result) {
    //   navigate(paths.singleCarCheck, { state: { data: updatedResult } });
    // }
    dispatch(clearStatus());
  }

  if (error) {
    const err = error;
    dispatch(clearStatus());
    navigate(paths.singleCarCheck, {
      state: { data: { found: false, msg: err } },
    });
  }

  if (location.state) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <CheckMyCar step={step} setStep={setStep} setVin={setVin} vin={vin} />
      <JsonLDSchemaHandler data={ldSchemas?.checkYourCars} />
    </>
  );
}
