import { t } from "i18next";
import React from "react";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceSection from "../Home/PlaceSection";
import { NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import FaqSection from "../Home/FaqSection";
import { faq, faq_rent, faq_Sell_your_car } from "../../../data/faqContent";
import Follow from "../Home/Follow";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
import { GuideToSell, WhySellOnAko } from "./SellRentIntroContent";

function SellRentIntro({ page, title, startLink }) {
  const udata = useSelector((state) => state.auth);
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }

  const sellFastData = [
    {
      id: "1",
      image: "/assets/images/benefit-cam.svg",
      icon: "marketeq:action-camera",
      title: "Take great photos",
      description:
        "Taking good quality photos means that the buyers have an accurate image of the car and there wont be any issues after the sale",
    },
    {
      id: "2",
      image: "/assets/images/benefit-pay.svg",
      icon: "marketeq:mobile-payment-done-2",
      title: "Keep it snappy",
      description:
        "There are a few documents youll need to make sure you have before you sell your car",
    },
    {
      id: "3",
      image: "/assets/images/benefit-reward.svg",
      icon: "marketeq:reward",
      title: "Be honest",
      description:
        "Make sure your vehicle description is accurate Mention any faults like scratches so buyers know what they re getting ahead of time",
    },
  ];

  return (
    <section className="sell-rent-intro">
      <div className="container px-0 sell-your">
        <h2 className="text-center my-4 my-md-5">{t(title)}</h2>
        <div
          className="rounded-1 bg-light d-flex flex-column align-items-center"
          style={{ marginBottom: "120px" }}
        >
          <h2
            className="text-center py-4"
            style={{ color: `var(--text-black-2)` }}
          >
            {t("Advertise on Ako Sayara")}
          </h2>
          <ul
            className="d-flex flex-column gap-2 list-unstyled p-3 mx-2"
            style={{
              maxWidth: "700px",
            }}
          >
            <li className="d-flex align-items-start gap-2">
              <div>
                <Icon icon="ic:baseline-check" color="green" width={15} />{" "}
              </div>
              <div>
                {page === "normal"
                  ? t(`Maximise your selling price`)
                  : t(`Maximise your renting price`)}
              </div>
            </li>
            <li className="d-flex align-items-start gap-2">
              <div>
                <Icon icon="ic:baseline-check" color="green" width={15} />{" "}
              </div>
              <div>
                {t(
                  "Advertise to over 10 million people each month 4x more than any other site"
                )}
              </div>
            </li>
            <li className="d-flex align-items-start gap-2">
              <div>
                <Icon icon="ic:baseline-check" color="green" width={15} />{" "}
              </div>
              <div>
                {t(
                  "Your sale your terms Sell when you re happy with the offer"
                )}
              </div>
            </li>
          </ul>
          <div className="d-flex justify-content-center most_searched my-4">
            <NavLink
              className={"viewall"}
              to={!udata.token ? paths.login : startLink}
              state={!udata.token ? { backPage: startLink } : null}
            >
              {t("Start an advart")}
            </NavLink>
          </div>
          <LazyLoadImage
            wrapperClassName="d-flex justify-content-center big-img"
            className="img"
            style={{
              maxWidth: "100%",
              overflow: "hidden",
              width: "100%",
              height: "auto",
            }}
            src={"../assets/images/sell-intro.png"}
            alt="img"
            effect="opacity"
          />
        </div>

        <section class="benefits new_carbf text-center">
          <div class="container">
            <h2 class="hed" style={{ marginBottom: "60px" }}>
              {t("How to sell your car fast")}
            </h2>
            <div class="row">
              {sellFastData
                ? sellFastData.map((item, key) => {
                    return (
                      <div class="col-sm-4 mb-2" key={key}>
                        <div class="set border h-100 ">
                          <img
                            src={item.image}
                            alt="icon"
                            className="mb-2 mt-2"
                          />
                          <h5>{item.title ? t(item.title) : ""}</h5>
                          <p>{item.description ? t(item.description) : ""} </p>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </section>

        <PlaceSection page={page} />
        {page === "normal" && <GuideToSell />}
        
        {page === "normal" && <FaqSection faq={faq_Sell_your_car} type={"sell_your_car"} />}
        {page === "rental" && <FaqSection faq={faq_rent} type={"rent"} />}
        {/* <WhySellOnAko /> */}
        <Follow />
      </div>
    </section>
  );
}

export default SellRentIntro;
