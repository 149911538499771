export const page_titles = {
    home: {
      English: "Used cars, New cars, Rent cars, Sell cars in Iraq - Ako Sayaya",
      Arabic: "سيارات مستعملة، سيارات جديدة، تأجير سيارات، بيع سيارات في العراق - اكو سيارة",
      Kurdish: "سەیارەکانە هەڵواسە، سەیارەکانە نوێ، کرێی سەیارەکان، فرۆشتنی سەیارەکان لە عێراق - ئەکۆ سەیارە"
    },
    rentCar: {
      English: "Rent a car in Iraq | Find your perfect Rental In Iraq",
      Arabic: "تأجير سيارات في العراق | اعثر على سيارتك المثالية للإيجار",
      Kurdish: "کرێی ئۆتۆمبێل لە عێراق | ئۆتۆمبێلی کرێی تەواوت بدۆزەرەوە"
    },
    checkYourCars: {
      English: "Check your car In Iraq",
      Arabic: "تحقق من سيارتك في العراق",
      Kurdish: "ئۆتۆمبێلەکەت بپشکنە لە عێراق"
    },
    sellYourCar: {
      English: "Sell your car in Iraq | Sell car in Iraq | Ako Sayara",
      Arabic: "بيع سيارتك في العراق | بيع سيارة في العراق - اكو سيارة",
      Kurdish: "فرۆشتنی سەیارەکەت لە عێراق | فرۆشتنی سەیارە لە عێراق - ئەکۆ سەیارە"
    },
    sellYourCarPage: {
      English: "Sell your car in Iraq | Sell car in Iraq | Ako Sayara",
      Arabic: "بيع سيارتك في العراق | بيع سيارة في العراق - اكو سيارة",
      Kurdish: "فرۆشتنی سەیارەکەت لە عێراق | فرۆشتنی سەیارە لە عێراق - ئەکۆ سەیارە"
    },
    rentYourCar: {
      English: "Rent your car In Iraq | Rent a car in Iraq | Ako Sayara",
      Arabic: "أجر سيارتك في العراق | أجر سيارة في العراق - اكو سيارة",
      Kurdish: "کرێی سەیارەکەت لە عێراق | کرێی سەیارە لە عێراق - ئەکۆ سەیارە"
    },
    rentYourCarPage: {
      English: "Rent your car In Iraq | Rent a car in Iraq | Ako Sayara",
      Arabic: "أجر سيارتك في العراق | أجر سيارة في العراق - اكو سيارة",
      Kurdish: "کرێی سەیارەکەت لە عێراق | کرێی سەیارە لە عێراق - ئەکۆ سەیارە"
    },
    aboutSayara: {
      English: "About us for Sell, Rent, Buy a car - Ako Sayara",
      Arabic: "معلومات عنا لبيع، تأجير، وشراء السيارات - اكو سيارة",
      Kurdish: "دەربارەی ئێمە بۆ فرۆشتن، کرێکردن، و کڕینی سەیارە - ئەکۆ سەیارە"
    },
    contact: {
      English: "Contact us for Sell, Rent, Buy a car - Ako Sayara",
      Arabic: "اتصل بنا لبيع، تأجير، وشراء السيارات - اكو سيارة",
      Kurdish: "پەیوەندیمان پێوە بکە بۆ فرۆشتن، کرێکردن، و کڕینی سەیارە - ئەکۆ سەیارە"
    },
    closeAccount: {
      English: "Close my account - Ako Sayara",
      Arabic: "إغلاق حسابي - اكو سيارة",
      Kurdish: "ئەکاونتەکەم دابخە - ئەکۆ سەیارە"
    },
    careers: {
      English: "Careers - Ako Sayara",
      Arabic: "وظائف - اكو سيارة",
      Kurdish: "پیشەکان - ئەکۆ سەیارە"
    },
    policies: {
      English: "Privacy policy - Ako Sayara",
      Arabic: "سياسة الخصوصية - اكو سيارة",
      Kurdish: "سیاسەتی پاراستنی نهێنی - ئەکۆ سەیارە"
    },
    conditions: {
      English: "Terms and conditions - Ako Sayara",
      Arabic: "الأحكام والشروط - اكو سيارة",
      Kurdish: "بار و دۆخ و مەرجەکان - ئەکۆ سەیارە"
    },
    cookies: {
      English: "Cookies - Ako Sayara",
      Arabic: "ملفات تعريف الارتباط - اكو سيارة",
      Kurdish: "کوکیز - ئەکۆ سەیارە"
    },
    newCarDeals: {
      English: "New car deals in Iraq - Ako Sayara",
      Arabic: "عروض سيارات جديدة - اكو سيارة",
      Kurdish: "پێشکەشکردنی سەیارە نوێکان - ئەکۆ سەیارە"
    },
    classicCars: {
      English: "Classic cars in Iraq - Ako Sayara",
      Arabic: "السيارات الكلاسيكية في العراق - اكو سيارة",
      Kurdish: "سەیارە کلاسیکییەکان لە عێراق - ئەکۆ سەیارە"
    },
    electricCars: {
      English: "Buy, Sell and Rent Electric cars in Iraq",
      Arabic: "بيع وشراء وتأجير السيارات الكهربائية في العراق",
      Kurdish: "فرۆشتن، کڕین و کرێکردنی سەیارە ئۆتۆمبیلێتیەکان لە عێراق - ئەکۆ سەیارە"
    },
    rentPerfectCars: {
      English: "Rent your perfect car in Iraq - Ako Sayara",
      Arabic: "استأجر سيارتك المثالية في العراق - اكو سيارة",
      Kurdish: "کرێی سەیارەی پەرفێکتەکەت لە عێراق بکە - ئەکۆ سەیارە"
    },
    articles: {
      English: "Our Articles & News - Ako Sayara",
      Arabic: "مقالاتنا وأخبارنا - اكو سيارة",
      Kurdish: "وتارەکان و هەواڵەکانمان - ئەکۆ سەیارە"
    },
    advancedSearch: {
      English: "Advanced search - Ako Sayara",
      Arabic: "البحث المتقدم - اكو سيارة",
      Kurdish: "گەڕانی پێشکەوتوو - ئەکۆ سەیارە"
    },
    buyCar: {
      English: "Buy cars in Iraq, Purchase cars in Iraq - Ako Sayara",
      Arabic: "شراء سيارات في العراق، شراء سيارات في العراق - اكو سيارة",
      Kurdish: "کڕینی سەیارەکان لە عێراق، کڕینی سەیارەکان لە عێراق - ئەکۆ سەیارە"
    },
    rentalSearchResult: {
      English: "Search Rental cars in Iraq - Ako Sayara",
      Arabic: "ابحث عن سيارات للإيجار في العراق - اكو سيارة",
      Kurdish: "بگەڕێ بۆ سەیارە بۆ کرێ لە عێراق - ئەکۆ سەیارە"
    },
  
    dealerRental: {
      English: "Dealer rentals",
      Arabic: "سيارات للأيجار",
      Kurdish: "ئۆتۆمبێل بۆ کرێ"
    },
    dealerCarView: {
      English: "Dealer car view",
      Arabic: "عرض سيارة التاجر",
      Kurdish: "دیمەنی ئۆتۆمبێلی بریکار"
    },
    searchResult: {
      English: "Search results",
      Arabic: "نتائج البحث",
      Kurdish: "ئەنجامی توێژینەوەکان"
    },
    dealerSearchResults: {
      English: "Dealer search result",
      Arabic: "نتيجة البحث عن تاجر",
      Kurdish: "ئەنجامی گەڕانی بازرگان"
    },
    brandSearchResults: {
      English: "Brand search result",
      Arabic: "نتيجة البحث عن وكالة",
      Kurdish: "ئەنجامی گەڕان بۆ بریکارەکە"
    },
    enquiry: {
      English: "Enquire now",
      Arabic: "استفسر الآن",
      Kurdish: "ئێستا پرسیار بکە"
    },
    whyAkoSayara: {
      English: "Why Ako Sayara",
      Arabic: "لماذا اكو سيارة",
      Kurdish: "بۆچی ئەکۆ سەیارە"
    },
    dealershipDocument: {
      English: "Dealership documents",
      Arabic: "وثائق التاجر",
      Kurdish: "بەڵگەنامەکانی بازرگان"
    },
    myAccount: {
      English: "My account",
      Arabic: "حسابي",
      Kurdish: "ئەکاونتی من"
    },
    myVehicles: {
      English: "Vehicles - My account",
      Arabic: "السيارات - حسابي",
      Kurdish: "ئۆتۆمبێل - ئەکاونتی من"
    },
    myTermsConditions: {
      English: "Terms & Conditions - My account",
      Arabic: "الشروط والأحكام - حسابي",
      Kurdish: "مەرج و ڕێساکان - ئەکاونتی من"
    },
    myWhyAkoSayara: {
      English: "Why Ako Sayara - My account",
      Arabic: "لماذا اكو سيارة - حسابي",
      Kurdish: "بۆچی ئەکۆ سەیارە - ئەکاونتی من"
    },
    myPrivacyPolicies: {
      English: "Privacy policy - My account",
      Arabic: "سياسة الخصوصية - حسابي",
      Kurdish: "سیاسەتی پاراستنی نهێنی - ئەکاونتی من"
    },
    myContactUs: {
      English: "Contact us - My account",
      Arabic: "اتصل بنا - حسابي",
      Kurdish: "پەیوەندیمان پێوە بکەن - ئەکاونتی من"
    },
    myCloseAccount: {
      English: "Close my account - My account",
      Arabic: "إغلاق حسابي - حسابي",
      Kurdish: "ئەکاونتەکەم دابخە - ئەکاونتەکەم"
    },
    editCar: {
      English: "Edit car - My account",
      Arabic: "تعديل السيارة - حسابي",
      Kurdish: "دەستکاری ئۆتۆمبێل - ئەکاونتی من"
    },
    editProfile: {
      English: "Edit profile - My account",
      Arabic: "تعديل الملف الشخصي - حسابي",
      Kurdish: "دەستکاریکردنی پڕۆفایلی - ئەکاونتی من"
    },
    security: {
      English: "Security - My account",
      Arabic: "الأمان - حسابي",
      Kurdish: "ئاسایش - ئەکاونتی من"
    },
    editSecurity: {
      English: "Edit security - My account",
      Arabic: "تعديل الأمان - حسابي",
      Kurdish: "دەستکاری ئاسایش - ئەکاونتی من"
    },
    transactions: {
      English: "Transactions - My account",
      Arabic: "النشاط التجاري - حسابي",
      Kurdish: "بازرگانییەکان - ئەکاونتی من"
    },
    messages: {
      English: "Messages - My account",
      Arabic: "الرسائل - حسابي",
      Kurdish: "پەیامەکان - ئەکاونتی من"
    },
    performance: {
      English: "Performance - My account",
      Arabic: "الأداء - حسابي",
      Kurdish: "ئەدای کارکردن - ئەکاونتی من"
    },
    myProfile: {
      English: "My profile - My account",
      Arabic: "ملفي الشخصي - حسابي",
      Kurdish: "پڕۆفایلی من - ئەکاونتی من"
    },
    editWorkHours: {
      English: "Edit work hours - My account",
      Arabic: "تعديل ساعات العمل - حسابي",
      Kurdish: "دەستکاری کاتی کارکردن - ئەکاونتی من"
    },
    myCookies: {
      English: "Cookies - My account",
      Arabic: "ملفات تعريف الارتباط - حسابي",
      Kurdish: "کووکی - ئەکاونتی من"
    },
    login: {
      English: "Login",
      Arabic: "تسجيل الدخول",
      Kurdish: "چوونەژوورەوە"
    },
    forgotPassword: {
      English: "Forgot password?",
      Arabic: "هل نسيت كلمة المرور؟",
      Kurdish: "پاسووردەکەت بیرچۆتەوە؟"
    },
    enterOTP: {
      English: "Forgot password?",
      Arabic: "هل نسيت كلمة المرور؟",
      Kurdish: "پاسووردەکەت بیرچۆتەوە؟"
    },
    signup: {
      English: "Sign up",
      Arabic: "قم بالتسجيل",
      Kurdish: "تۆمارکە"
    },
    basicDetails: {
      English: "Basic details",
      Arabic: "التفاصيل الأساسية",
      Kurdish: "وردەکاری بنچینەی"
    },
    saved: {
      English: "Saved",
      Arabic: "المحفوظة",
      Kurdish: "کۆکردن"
    },
    comparing: {
      English: "Comparing",
      Arabic: "مقارنة",
      Kurdish: "بەراوردکردن"
    },
    viewImage: {
      English: "View image",
      Arabic: "عرض الصورة",
      Kurdish: "بینینی وێنە"
    },
    plan: {
      English: "Ad plan",
      Arabic: "نوع الإعلان",
      Kurdish: "جۆری ڕێکلام"
    },
    renew: {
      English: "Renew / Upgrade",
      Arabic: "تجديد/ تحديث",
      Kurdish: "تازەکردنەوە / نوێکردنەوە"
    },
    payment: {
      English: "Payment",
      Arabic: "الدفع",
      Kurdish: "پارەدان"
    },
    viewMap: {
      English: "View on map",
      Arabic: "عرض على الخريطة",
      Kurdish: "لەسەر نەخشە نیشان بدە"
    },
    cheapCars: {
      English: "Cheap cars",
      Arabic: "سيارات رخيصة",
      Kurdish: "ئۆتۆمبێلی هەرزان"
    },
    singleCar: {
      English: "Single car - Ako Sayara",
      Arabic: "سيارة - اكو سيارة",
      Kurdish: "ئۆتۆمبێل - ئەکۆ سەیارە"
    },
    singleRental: {
      English: "Single car - Ako Sayara",
      Arabic: "سيارة - اكو سيارة",
      Kurdish: "ئۆتۆمبێل - ئەکۆ سەیارە"
    },
    search: {
      English: "Search",
      Arabic: "البحث",
      Kurdish: "گەڕان"
    },
    rentalSearch: {
      English: "Rental search",
      Arabic: "البحث عن سيارات للإيجار",
      Kurdish: "ئۆتۆمبێلێک بدۆزەرەوە بۆ کرێ"
    },
    singleCarCheck: {
      English: "Single car check - Ako Sayara",
      Arabic: "التحقق من السيارة - اكو سيارة",
      Kurdish: "ئۆتۆمبێلەکە بپشکنە - ئەکۆ سەیارە"
    },
    searchResultPage: {
      English: "Dealer Details",
      Arabic: "تفاصيل التاجر",
      Kurdish: "وردەکارییەکانی بازرگان"
    },
  
    singleBrand: {
      English: "Brand details",
      Arabic: "تفاصيل الوكالة",
      Kurdish: "وردەکارییەکانی بریکارەکە"
    },
    perfomance: {
      English: "Performance - My account",
      Arabic: "الأداء - حسابي",
      Kurdish: "نمایشەکە - ئەکاونتی من"
    },

    usedCarDeals: {
      English: "Used cars",
      Arabic: "سيارة مستعملة",
      Kurdish: "ئۆتۆمبێلی بەکارهاتوو"
    },
  }
  
  export const guide_titles = {
    1: {
      English: "Buying guide",
      Arabic: "دليل الشراء",
      Kurdish: "ڕێنمایی کڕین",
    },
    2: {
      English: "Selling guide",
      Arabic: "دليل البيع",
      Kurdish: "ڕێنمایی فرۆشتن",
    },
    3: {
      English: "Renting guide",
      Arabic: "دليل التأجير",
      Kurdish: "ڕێنمایی كڕێ",
    },
    4: {
      English: "Rent your car guide",
      Arabic: "دليل تأجير السيارات",
      Kurdish: "ڕێنمایی کرێی ئۆتۆمبێل",
    },
  };
  
  export const meta_des_all = {
    home: {
      English: `**Ako Sayara** is Iraq's newest platform for buying, selling, and renting cars. Discover a wide range of vehicles, from economy to luxury models, with seamless transactions and reliable customer service.`,
      Arabic: `**اكو سيارة** هي أحدث منصة في العراق لبيع وشراء وتأجير السيارات. اكتشف مجموعة واسعة من السيارات الاقتصادية إلى الفاخرة، مع معاملات سلسة وخدمة عملاء موثوقة.`,
      Kurdish: `**ئەکۆ سەیارە** نوێترین پلاتفۆرمی عێراقە بۆ کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل. کۆمەڵێک ئابووری تا ئۆتۆمبێلی لوکس بدۆزەرەوە، لەگەڵ مامەڵەی بێ کێشە و خزمەتگوزاری متمانەپێکراو بۆ کڕیاران.`,
    },
    rentPerfectCars: {
      English: `Looking to **Rent your perfect car** in Iraq? **Ako Sayara** offers a wide variety of rental cars to suit every need, from economy to luxury models. Whether it's for a business trip, family outing, or special occasion, find the ideal vehicle with ease. With flexible options and reliable service, Ako Sayara makes car rentals simple and convenient. Rent your perfect car today!`,
      Arabic: `تبحث عن **استئجار سيارتك المثالية** في العراق؟ تقدم **اكو سيارة** مجموعة واسعة من السيارات للإيجار لتناسب كل الاحتياجات، من السيارات الاقتصادية إلى السيارات الفاخرة. سواء كان ذلك لرحلة عمل، نزهة عائلية، أو مناسبة خاصة، اعثر على السيارة المثالية بسهولة. مع خيارات مرنة وخدمة موثوقة، تجعل اكو سيارة استئجار السيارات بسيطًا ومريحًا. استأجر سيارتك المثالية اليوم!`,
      Kurdish: `لە بەدوای کرێکردنی سەیارەی پەرفێکتەکەت لە عێراق دەگەڕێیت؟ ئەکۆ سەیارە ڕەگەزێکی گەورەی سەیارە کرێیەکان پێشکەش دەکات بۆ هەموو پێویستیەکان، لە سەیارەی ئابووری تا سەیارەی لاکچەری. ئەگەر بۆ سفرێکی بازرگانی، چۆلەوەیەکی عائلی، یان ڕووداوێکی تایبەتی، بە ئاسانترین ڕێگە سەیارەی وەرگرتنی پەرفێکتەکەت بۆ بینە. بە هەڵبژاردنە مرنە و خزمەتی بەبەستی بەکاردەهێنەوە، ئەکۆ سەیارە کرێکردنی سەیارە دەکات بە ئاسانە و ئەرکەوە. ئەمڕۆ سەیارەی پەرفێکتەکەت کرێ بکە!`,
    },
    rentCar: {
      English: `Find your perfect rental in Iraq with our easy to use platform. Explore a wide range of vehicles to suit your needs and budget. Book online for convenient, reliable, and affordable car rentals today!`,
      Arabic: `ابحث عن سيارتك المثالية للإيجار في العراق من خلال منصتنا السهلة الاستخدام. استكشف مجموعة واسعة من المركبات التي تناسب احتياجاتك وميزانيتك. احجز عبر الإنترنت اليوم لتأجير سيارات مريحة وموثوقة وبأسعار معقولة!`,
      Kurdish: `ئۆتۆمبێلێکی تەواو بۆ کرێ لە عێراق بدۆزەرەوە لەگەڵ پلاتفۆرمی ئاسان بۆ بەکارهێنانمان. بەدوای کۆمەڵێک ئۆتۆمبێلدا بگەڕێ کە لەگەڵ پێداویستی و بودجەی خۆتدا بگونجێت. ئەمڕۆ بە شێوەی ئۆنلاین حجز بکە بۆ کرێی ئۆتۆمبێل بە ئاسوودە و متمانەپێکراو و گونجاو!`,
    },
    sellYourCar: {
      English: `Sell your car quickly and easily in Iraq with our trusted platform. Connect with potential buyers, set your price hassle-free. Start selling your car today!`,
      Arabic: `بيع سيارتك بسرعة وسهولة في العراق من خلال منصتنا الموثوقة. تواصل مع المشترين المحتملين، وحدد سعر سيارتك دون أي متاعب. ابدأ ببيع سيارتك اليوم!`,
      Kurdish: `ئۆتۆمبێلەکەت بە خێرایی و بە ئاسانی لە عێراق بفرۆشە لە ڕێگەی پلاتفۆرمی متمانەپێکراوەکەمانەوە. پەیوەندی لەگەڵ کڕیارە ئەگەرییەکان بکە و نرخی ئۆتۆمبێلەکەت بەبێ هیچ کێشەیەک. ئەمڕۆ دەست بکە بە فرۆشتنی ئۆتۆمبێلەکەت!`,
    },
    rentYourCar: {
      English: `Sell your car quickly and easily in Iraq with our trusted platform. Connect with potential buyers, set your price hassle-free. Start selling your car today!`,
      Arabic: `قم بتأجير سيارتك في العراق بكل سهولة من خلال منصتنا الآمنة. احصل على دخل إضافي من خلال إدراج سيارتك وتحديد شروطك والتواصل مع مستأجرين موثوقين. ابدأ بتأجير سيارتك اليوم!`,
      Kurdish: `ئۆتۆمبێلەکەت لە عێراق بە کرێ بگرە بە ئاسانی لە ڕێگەی پلاتفۆرمە پارێزراوەکەمانەوە. داهاتی زیادە بەدەستبهێنە بە لیستکردنی ئۆتۆمبێلەکەت و دانانی مەرجەکانت و پەیوەندیکردن لەگەڵ کرێچییە متمانەپێکراوەکان. ئەمڕۆ دەست بکە بە بەکرێدانی ئۆتۆمبێلەکەت!`,
    },
    checkYourCars: {
      English: `Check your car's history and details with our VIN number lookup service in Iraq. Get comprehensive reports on accidents, mileage and more to make informed decisions before buying or selling.`,
      Arabic: `تحقق من تاريخ سيارتك وتفاصيلها من خلال خدمة البحث عن رقم شاصي السيارة (VIN) في العراق. احصل على تقارير شاملة عن الحوادث والمسافة المقطوعة والمزيد لاتخاذ قرارات مستنيرة قبل الشراء أو البيع.`,
      Kurdish: `لە ڕێگەی خزمەتگوزاری گەڕانی ژمارەی شاسی ئۆتۆمبێل (VIN) لە عێراق مێژوو و وردەکاری ئۆتۆمبێلەکەت بزانە. ڕاپۆرتی گشتگیر لەسەر ڕووداوەکان، مایل و زۆر شتی تر بەدەست بهێنە بۆ ئەوەی پێش کڕین یان فرۆشتن بڕیاری ئاگادارانە بدەیت.`,
    },
    aboutSayara: {
      English: `**Ako Sayara** is Iraq's newest automotive marketplace for buying, selling, renting, and checking cars. We offer a seamless online experience for car shoppers and retailers, promoting eco-friendly choices and inclusivity.`,
      Arabic: `**اكو سيارة**  هي أحدث سوق رقمي للسيارات في العراق، حيث يمكن شراء، بيع، تأجير، وفحص السيارات. نحن نقدم تجربة سلسة عبر الإنترنت لمتسوقي السيارات وتجارها، ونعزز الخيارات الصديقة للبيئة والشمولية.`,
      Kurdish: `**ئەکو سەیارە** نوێترین بازاڕی کەیڤی مووتورەکانە لە عێراق بۆ کڕین، فرۆشتن، کرێکردن و پشکنینی مووتورەکان. ئێمە کارەبەریەکی سەهل بۆ کڕیارانی مووتور و فرۆشیارەکان بە شێوەی ئۆنلاین دابین دەكەین، بە پشتگیری هەلبژاردنە جینگپەروەرانە و چەند جۆرەکانی تر.`,
    },
    contact: {
      English: `If you have any questions or need any assistance, the **Ako Sayara** team is here to help! Whether you're looking to buy, sell, or hire a car, our customer service team is ready to provide support. Contact us by phone, email, or through our online contact form, and we’ll respond promptly. Your satisfaction is our priority!`,
      Arabic: `إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، فإن فريق **اكو سيارة** هنا لمساعدتك! سواء كنت تبحث عن شراء أو بيع أو استئجار سيارة، فإن خدمة العملاء لدينا جاهزة لتقديم الدعم. تواصل معنا عبر الهاتف أو البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت، وسنرد عليك بسرعة. رضاك هو أولويتنا!`,
      Kurdish: `ئەگەر پرسیارێکت هەیە یان پێویستت بە یارمەتییە، تیمی **ئەکۆ سەیارە** لە ئێنجاست بۆ یارمەتی بەردەستە! گەر لەگەڕاندن بۆ کڕین، فرۆشتن، یان کرێکردنی ئۆتۆمبیل بیت، تیمی خزمەتگوزارییەکانمان ئامادەیە بۆ پشتیوانییە. پەیوەندیمان پێوە بکە بە ڕێگای تەلەفۆن، ئیمەیڵ، یان فۆڕمی پەیوەندیی ئۆنلاین، وە پێنجەرەیەکمان بە ژمارە بەری خێرا وەڵام بدەیت. رەزامەندییەکانتان باشترین خاڵە!`,
    },
    myContactUs: {
      English: `If you have any questions or need any assistance, the **Ako Sayara** team is here to help! Whether you're looking to buy, sell, or hire a car, our customer service team is ready to provide support. Contact us by phone, email, or through our online contact form, and we’ll respond promptly. Your satisfaction is our priority!`,
      Arabic: `إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، فإن فريق **اكو سيارة** هنا لمساعدتك! سواء كنت تبحث عن شراء أو بيع أو استئجار سيارة، فإن خدمة العملاء لدينا جاهزة لتقديم الدعم. تواصل معنا عبر الهاتف أو البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت، وسنرد عليك بسرعة. رضاك هو أولويتنا!`,
      Kurdish: `ئەگەر پرسیارێکت هەیە یان پێویستت بە یارمەتییە، تیمی **ئەکۆ سەیارە** لە ئێنجاست بۆ یارمەتی بەردەستە! گەر لەگەڕاندن بۆ کڕین، فرۆشتن، یان کرێکردنی ئۆتۆمبیل بیت، تیمی خزمەتگوزارییەکانمان ئامادەیە بۆ پشتیوانییە. پەیوەندیمان پێوە بکە بە ڕێگای تەلەفۆن، ئیمەیڵ، یان فۆڕمی پەیوەندیی ئۆنلاین، وە پێنجەرەیەکمان بە ژمارە بەری خێرا وەڵام بدەیت. رەزامەندییەکانتان باشترین خاڵە!`,
    },
    closeAccount: {
      English: `If you wish to close your **Ako Sayara** account, we are here to assist you. Simply contact our support team via email or through our online contact form with your account details, and we will guide you through the process. Please note that once your account is closed, all your saved data, listings, and preferences will be permanently deleted.`,
      Arabic: `إذا كنت ترغب في إغلاق حسابك في **اكو سيارة**، نحن هنا لمساعدتك. ببساطة، اتصل بفريق الدعم لدينا عبر البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت مع تفاصيل حسابك، وسنقوم بإرشادك خلال العملية. يُرجى ملاحظة أنه بمجرد إغلاق حسابك، سيتم حذف جميع بياناتك المحفوظة والإعلانات والتفضيلات بشكل دائم.`,
      Kurdish: `ئەگەر دەتەوێت ئەکاونتەکەت لە **ئەکۆ سەیارە** داخەیت، ئێمە لە ئێنجا بۆ یارمەتی تۆ هەین. بە سادەیی، پەیوەندیمان پێوە بکە بە ڕێگای ئیمەیڵ یان فۆڕمی پەیوەندیی ئۆنلاین، وە وردەکارییەکانی ئەکاونتەکەت بنووسە، و ئێمە ڕێنماییت دەکەین بۆ ئەنجامدانی ئەم پرۆسەیە. تکایە وریابە کە کاتێک ئەکاونتەکەت داخرا، هەموو داتاکانت، ڕیکلامەکان و پەسەندەکانت بە بەردەوامی بەتاڵ دەکرێنەوە.`,
    },
    myCloseAccount: {
      English: `If you wish to close your **Ako Sayara** account, we are here to assist you. Simply contact our support team via email or through our online contact form with your account details, and we will guide you through the process. Please note that once your account is closed, all your saved data, listings, and preferences will be permanently deleted.`,
      Arabic: `إذا كنت ترغب في إغلاق حسابك في **اكو سيارة**، نحن هنا لمساعدتك. ببساطة، اتصل بفريق الدعم لدينا عبر البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت مع تفاصيل حسابك، وسنقوم بإرشادك خلال العملية. يُرجى ملاحظة أنه بمجرد إغلاق حسابك، سيتم حذف جميع بياناتك المحفوظة والإعلانات والتفضيلات بشكل دائم.`,
      Kurdish: `ئەگەر دەتەوێت ئەکاونتەکەت لە **ئەکۆ سەیارە** داخەیت، ئێمە لە ئێنجا بۆ یارمەتی تۆ هەین. بە سادەیی، پەیوەندیمان پێوە بکە بە ڕێگای ئیمەیڵ یان فۆڕمی پەیوەندیی ئۆنلاین، وە وردەکارییەکانی ئەکاونتەکەت بنووسە، و ئێمە ڕێنماییت دەکەین بۆ ئەنجامدانی ئەم پرۆسەیە. تکایە وریابە کە کاتێک ئەکاونتەکەت داخرا، هەموو داتاکانت، ڕیکلامەکان و پەسەندەکانت بە بەردەوامی بەتاڵ دەکرێنەوە.`,
    },
    careers: {
      English: `Join our dynamic team at **Ako Sayara** and build your career in the automotive industry! We are always looking for passionate individuals who are keen to contribute to our mission of revolutionising the car buying, selling, and hiring experience. Explore exciting opportunities across various departments, from customer service to tech development. At Ako Sayara, you can grow professionally while making a difference in the automotive world.`,
      Arabic: `انضم إلى فريقنا الديناميكي في **اكو سيارة** وابنِ مستقبلك المهني في صناعة السيارات! نحن دائماً نبحث عن أفراد شغوفين يرغبون في المساهمة في مهمتنا لتغيير تجربة شراء وبيع واستئجار السيارات. استكشف فرصًا مثيرة في مختلف الأقسام، من خدمة العملاء إلى تطوير التكنولوجيا. في اكو سيارة، يمكنك أن تنمو مهنيًا بينما تحدث فرقًا في عالم السيارات.`,
      Kurdish: `بەشداربە لە تیمی جۆربەجۆرەمان لە **ئەکۆ سەیارە** و یارمەتی بەرزبوونەوەی کارەبەستەکەت لە بواری گەشەی ئۆتۆمبیلدایە! هەمیشە بەدوای کەسانی برەوپیشبردنیان وەک ئامانجێکی پارێزگای بەرزەسەیریی کڕین، فرۆشتن و کرێی ئۆتۆمبیل دەگەڕێین. دەست پێ بکە بە دەستپێدان لە بەشەکانى خزمەتگوزاریی کەسایەتییەکانە تا پەرەپێدانى تایبەتیەکانە بە تیکنۆلۆجیدا. لە **ئەکۆ سەیارە**، ئەتوانیت پەیوەندیدا بەرزبوونی تایبەتییەکەت تێڕا لە جیهانی ئۆتۆمبیلدا!`,
    },
    policies: {
      English: `At **Ako Sayara**, your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform. We are committed to ensuring that your data is secure and used responsibly, in compliance with industry standards. By using our services, you agree to the terms outlined in this policy, which are designed to safeguard your privacy and enhance your experience.`,
      Arabic: `في **اكو سيارة**، خصوصيتك تهمنا. توضح سياسة الخصوصية الخاصة بنا كيفية جمع واستخدام وحماية معلوماتك الشخصية عند استخدامك لمنصتنا. نحن ملتزمون بضمان أمان بياناتك واستخدامها بمسؤولية، وفقًا للمعايير الصناعية. باستخدامك لخدماتنا، فإنك توافق على الشروط الموضحة في هذه السياسة، المصممة لحماية خصوصيتك وتحسين تجربتك.`,
      Kurdish: `لە **ئەکۆ سەیارە**، پاراستنی تایبەتییەکەت بۆ ئێمە گرنگە. سیاسەتی پاراستنی تایبەتییەکەمان دیاری دەکات چۆن زانیاری تایبەتیەکانت کۆ دەکەین، بەکار دەهێنین و پاراستن دەکەین کاتێک پلاتفۆڕمەکەمان بەکار دەهێنی. ئێمە بە دەوامی یەکەین لە پاراستنی زانیاریەکانت و بەردەوامی بەرپرسانە بەکاربردنیان، لە گەلی مییاری پیشەییەکاندا. بە بەکارهێنانی خزمەتگوزاریەکانمان، ئێوە ڕەزامەندی خۆتان پێشکەش دەکەین بۆ ئەم سیاسەتە، کە بەرزکردنی پاراستنی تایبەتییەکانت و باشترکردنی ئەزموونەکە پێشەنگ دەکات.`,
    },
    myPrivacyPolicies: {
      English: `At **Ako Sayara**, your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform. We are committed to ensuring that your data is secure and used responsibly, in compliance with industry standards. By using our services, you agree to the terms outlined in this policy, which are designed to safeguard your privacy and enhance your experience.`,
      Arabic: `في **اكو سيارة**، خصوصيتك تهمنا. توضح سياسة الخصوصية الخاصة بنا كيفية جمع واستخدام وحماية معلوماتك الشخصية عند استخدامك لمنصتنا. نحن ملتزمون بضمان أمان بياناتك واستخدامها بمسؤولية، وفقًا للمعايير الصناعية. باستخدامك لخدماتنا، فإنك توافق على الشروط الموضحة في هذه السياسة، المصممة لحماية خصوصيتك وتحسين تجربتك.`,
      Kurdish: `لە **ئەکۆ سەیارە**، پاراستنی تایبەتییەکەت بۆ ئێمە گرنگە. سیاسەتی پاراستنی تایبەتییەکەمان دیاری دەکات چۆن زانیاری تایبەتیەکانت کۆ دەکەین، بەکار دەهێنین و پاراستن دەکەین کاتێک پلاتفۆڕمەکەمان بەکار دەهێنی. ئێمە بە دەوامی یەکەین لە پاراستنی زانیاریەکانت و بەردەوامی بەرپرسانە بەکاربردنیان، لە گەلی مییاری پیشەییەکاندا. بە بەکارهێنانی خزمەتگوزاریەکانمان، ئێوە ڕەزامەندی خۆتان پێشکەش دەکەین بۆ ئەم سیاسەتە، کە بەرزکردنی پاراستنی تایبەتییەکانت و باشترکردنی ئەزموونەکە پێشەنگ دەکات.`,
    },
    conditions: {
      English: `The Terms & Conditions of **Ako Sayara** outline the rules and guidelines for using our platform. By accessing and using our services, you agree to these terms, which cover account usage, transactions, privacy, and other important aspects of our website. These terms are designed to ensure a safe, fair, and transparent experience for all users, whether you are buying, selling, renting your car, renting a car, or checking your car.`,
      Arabic: `تحدد الشروط والأحكام الخاصة بـ **اكو سيارة** القواعد والإرشادات لاستخدام منصتنا. من خلال الوصول إلى خدماتنا واستخدامها، فإنك توافق على هذه الشروط التي تغطي استخدام الحساب، والمعاملات، والخصوصية، والجوانب المهمة الأخرى لموقعنا. تم تصميم هذه الشروط لضمان تجربة آمنة وعادلة وشفافة لجميع المستخدمين، سواء كنت تشتري، تبيع، تؤجر سيارتك، تستأجر سيارة، أو تفحص سيارتك.`,
      Kurdish: `مەرج و شەرطەکانی **ئەکۆ سەیارە** ڕێنووسەکان و رێنماییەکان بۆ بەکارهێنانی پلاتفۆڕمەکەمان دیاری دەکات. بە داخڵبوونەوە و بەکارهێنانی خزمەتگوزاریەکانمان، رادەبەزین بەو مەرجەکانە، کە بەشەکانى بەکارهێنانی ئەکاونت، مامەڵەکان، پاراستنی تایبەتی و هەندێک لاپەڕەی گرنگ بۆ ماڵپەڕەکەمان بە ناوە. ئەم مەرجەکان بۆ دڵنیایی بەرەوپیشبردنی ئەزموونێکی پاراستن، دادپەروەری و ڕوونەوە بۆ هەموو بەکارهێنەرانە، ئەگەر دەستی لە کڕین، فرۆشتن، کرێکردنی ئۆتۆمبیل، کرێدانەوەی ئۆتۆمبیل یان تەواوکردنی ئۆتۆمبیلت بگریت!`,
    },
    myTermsConditions: {
      English: `The Terms & Conditions of **Ako Sayara** outline the rules and guidelines for using our platform. By accessing and using our services, you agree to these terms, which cover account usage, transactions, privacy, and other important aspects of our website. These terms are designed to ensure a safe, fair, and transparent experience for all users, whether you are buying, selling, renting your car, renting a car, or checking your car.`,
      Arabic: `تحدد الشروط والأحكام الخاصة بـ **اكو سيارة** القواعد والإرشادات لاستخدام منصتنا. من خلال الوصول إلى خدماتنا واستخدامها، فإنك توافق على هذه الشروط التي تغطي استخدام الحساب، والمعاملات، والخصوصية، والجوانب المهمة الأخرى لموقعنا. تم تصميم هذه الشروط لضمان تجربة آمنة وعادلة وشفافة لجميع المستخدمين، سواء كنت تشتري، تبيع، تؤجر سيارتك، تستأجر سيارة، أو تفحص سيارتك.`,
      Kurdish: `مەرج و شەرطەکانی **ئەکۆ سەیارە** ڕێنووسەکان و رێنماییەکان بۆ بەکارهێنانی پلاتفۆڕمەکەمان دیاری دەکات. بە داخڵبوونەوە و بەکارهێنانی خزمەتگوزاریەکانمان، رادەبەزین بەو مەرجەکانە، کە بەشەکانى بەکارهێنانی ئەکاونت، مامەڵەکان، پاراستنی تایبەتی و هەندێک لاپەڕەی گرنگ بۆ ماڵپەڕەکەمان بە ناوە. ئەم مەرجەکان بۆ دڵنیایی بەرەوپیشبردنی ئەزموونێکی پاراستن، دادپەروەری و ڕوونەوە بۆ هەموو بەکارهێنەرانە، ئەگەر دەستی لە کڕین، فرۆشتن، کرێکردنی ئۆتۆمبیل، کرێدانەوەی ئۆتۆمبیل یان تەواوکردنی ئۆتۆمبیلت بگریت!`,
    },
    cookies: {
      English: `At **Ako Sayara**, we use cookies to enhance your experience of buying, selling, renting, and checking cars. Cookies help personalise content and ads and enable key features. By using our site, you consent to our cookie policy.`,
      Arabic: `في **اكو سيارة** نستخدم ملفات تعريف الارتباط لتحسين تجربتك في شراء، بيع، تأجير، وفحص السيارات. تساعد ملفات تعريف الارتباط على تخصيص المحتوى والإعلانات وتفعيل الميزات الأساسية. باستخدام موقعنا، فإنك توافق على سياسة ملفات تعريف الارتباط الخاصة بنا.`,
      Kurdish: `لە **ئەکۆ سەیارە**، ئێمە کوکیەکان بەکاردەهێنین بۆ باشترکردنی ئەزموونی کڕین، فرۆشتن، کرێکردن و پشکنینی مووتورەکان. کوکیەکان یارمەتی دەدەن بۆ کەسییەتی کردنی ناوەڕۆک و ڕیکلامەکان و چالاککردنی تایبەتمەندیە گرنگەکان. بە بەکارهێنانی ماڵپەڕەکەمان، ئێوە پەیوەندیتان بە سیاستی کوکیەکانمان دەربڕین.`,
    },
    myCookies: {
      English: `At **Ako Sayara**, we use cookies to enhance your experience of buying, selling, renting, and checking cars. Cookies help personalise content and ads and enable key features. By using our site, you consent to our cookie policy.`,
      Arabic: `في **اكو سيارة** نستخدم ملفات تعريف الارتباط لتحسين تجربتك في شراء، بيع، تأجير، وفحص السيارات. تساعد ملفات تعريف الارتباط على تخصيص المحتوى والإعلانات وتفعيل الميزات الأساسية. باستخدام موقعنا، فإنك توافق على سياسة ملفات تعريف الارتباط الخاصة بنا.`,
      Kurdish: `لە **ئەکۆ سەیارە**، ئێمە کوکیەکان بەکاردەهێنین بۆ باشترکردنی ئەزموونی کڕین، فرۆشتن، کرێکردن و پشکنینی مووتورەکان. کوکیەکان یارمەتی دەدەن بۆ کەسییەتی کردنی ناوەڕۆک و ڕیکلامەکان و چالاککردنی تایبەتمەندیە گرنگەکان. بە بەکارهێنانی ماڵپەڕەکەمان، ئێوە پەیوەندیتان بە سیاستی کوکیەکانمان دەربڕین.`,
    },
    newCarDeals: {
      English: `Discover the latest **New car deals** at **Ako Sayara**! We offer exclusive discounts and promotions on a wide range of brand-new vehicles, from economy to luxury models. Whether you're looking for a sleek saloon or a family SUV, our deals help you find the perfect car at unbeatable prices. Don't miss out—explore our new car deals today!`,
      Arabic: `اكتشف أحدث **عروض السيارات الجديدة** في **اكو سيارة**! نحن نقدم خصومات وعروض حصرية على مجموعة واسعة من السيارات الجديدة، من السيارات الاقتصادية إلى السيارات الفاخرة. سواء كنت تبحث عن سيارة سيدان أنيقة أو سيارة SUV عائلية، تساعدك عروضنا في العثور على السيارة المثالية بأسعار لا تقبل المنافسة. لا تفوت الفرصة – استكشف عروض السيارات الجديدة لدينا اليوم!`,
      Kurdish: ` بەدەست بهێنە دوا پێشکەشەکان بۆ سەیارە نوێکان لە ئەکۆ سەیارە! ئێمە داواکارییە تایبەتییەکان و داشکاندنەکان پێشکەش دەکەین لەسەر ڕەگەزێکی فراوان لە سەیارە نوێکان، لە سەیارە کەمزۆرەکانەوە تا مودێلە ڕەقەبەکانی ئاشکرا. ئەگەر بەدوای سەیارەیەکی سەلۆنی نوێ یان SUV عائلی بگەڕێیت، پێشکەشەکانمان یارمەتی دەدەن بۆ دۆزینەوەی ئەو سەیارەی پەرفێکتە بەپێنجەدارترین نرخ. هەستیار مەبە – پێشکەشە نوێکانمان لە سەیارە نوێکان بزانە!`,
    },
    classicCars: {
      English: `Explore a unique selection of **Classic Cars** in Iraq at **Ako Sayara**. Whether you're a collector or simply a fan of vintage automobiles, we offer a range of timeless classics that embody elegance and history. From restored models to rare finds, our collection provides a glimpse into automotive heritage. Discover and own a piece of history today!`,
      Arabic: `استكشف مجموعة فريدة من **السيارات الكلاسيكية** في العراق لدى **اكو سيارة**. سواء كنت جامعًا للسيارات أو مجرد معجب بالسيارات القديمة، نقدم لك مجموعة من السيارات الكلاسيكية الخالدة التي تجسد الأناقة والتاريخ. من النماذج المجددة إلى الاكتشافات النادرة، توفر مجموعتنا لمحة عن التراث السياراتي. اكتشف وامتلك قطعة من التاريخ اليوم!`,
      Kurdish: `بینەری هەڵبژاردەیەکی تایبەتیی سەیارە کلاسیکیەکان لە عێراق لە ئەکۆ سەیارە! ئەگەر کۆکردنەوەی سەیارە تەواوە یان تەنیا بەڵەمواڵی ئۆتۆمبیلە کلاسیکیەکانت هەیە، ئێمە ڕەگەزێکی سەیارە کلاسیکیەکان پێشکەش دەکەین کە شێوەکاری و مێژوویان ئەنجام دەدات. لە نمونەیە نوێکرایەوەکان تا دۆزینەوە نایابەکان، کۆبەرەکانمان ڕووناکی دەخاتەسەر کەلتوری ئۆتۆمبیل. ئەمڕۆ شتەکی مێژووی بگەڕێ و بەخاوەنە!`,
    },
    electricCars: {
      English: `At **Ako Sayara**, you can easily **buy, sell, or rent electric cars** in Iraq. As the demand for eco-friendly vehicles grows, we offer a wide range of electric cars that combine cutting-edge technology with sustainability. Whether you're looking to purchase, list your electric car for sale, or rent one for short-term use, our platform provides a seamless experience. Join the green revolution and explore electric car options today!`,
      Arabic: `في **اكو سيارة**، يمكنك بسهولة **بيع, شراء أو تأجير السيارات الكهربائية** في العراق. مع تزايد الطلب على المركبات الصديقة للبيئة، نقدم مجموعة واسعة من السيارات الكهربائية التي تجمع بين التكنولوجيا المتقدمة والاستدامة. سواء كنت تبحث عن شراء، عرض سيارتك الكهربائية للبيع، أو استئجار واحدة للاستخدام القصير الأمد، فإن منصتنا توفر تجربة سلسة. انضم إلى الثورة الخضراء واستكشف خيارات السيارات الكهربائية اليوم!`,
      Kurdish: `لە ئەکۆ سەیارە، ئاسانە کە بتوانیت کڕین، فرۆشتن، یان کرێکردنی سەیارە ئۆتۆمبیلیەکان لە عێراق بکەیت. بە پەیوەست بەو پەیوەندیدانی زۆرە لەسەر ئوتومبیلیە سەوزەکان، ئێمە ڕەگەزێکی گەورەی سەیارە ئۆتۆمبیلیەکان پێشکەش دەکەین کە تکنەلۆژیا تازە بەلەکاردهێنەوە. ئەگەر بەدوای کڕین، دابینکردنی سەیارەکەت بۆ فرۆشتن، یان کرێکردن بۆ کاتی کورتدا، مەڕەکەکەمان بەخێڕاییەکە ئەنجام دەدات. بەشداربە لە شۆڕشی سەوزە و دۆزینەوەی هەڵبژاردنی سەیارە ئۆتۆمبیلیەکان ئەمڕۆ!`,
    },
    articles: {
      English: `Stay informed with **Our Articles & News** at **Ako Sayara**. Explore the latest updates on the automotive industry, car maintenance tips, market trends, and exclusive insights into buying, selling, and renting cars in Iraq and the UAE. Our expert-written articles keep you up-to-date and help you make informed decisions for all your automotive needs.`,
      Arabic: `ابقَ على اطلاع مع **مقالاتنا وأخبارنا** في **اكو سيارة**. استكشف آخر التحديثات حول صناعة السيارات، نصائح صيانة السيارات، اتجاهات السوق، ورؤى حصرية حول شراء، بيع، وتأجير السيارات في العراق والإمارات. مقالاتنا المكتوبة من قبل خبراء تبقيك على اطلاع دائم وتساعدك في اتخاذ قرارات مستنيرة لجميع احتياجاتك المتعلقة بالسيارات.`,
      Kurdish: `بە ئاغادەبوون بمەنی وتارەکان و هەواڵەکانمان لە ئەکۆ سەیارە. دۆزینەوەی نوێترین نوێکارییەکان لە فەرمیندانی ئۆتۆمبیل، پەندەکان بۆ چارەسەرکردنی سەیارە، بڕیارە بازاڕەکان، و ئاگادارییە تایبەتییەکان بۆ کڕین، فرۆشتن، و کرێکردنی سەیارە لە عێراق و ئیمارات. وتارەکانی ئاهەنگارە ئێکەمان بەتایبەتی بۆ تۆ چەندە ئاگرکراوەتەوە، تۆش ڕووناک دەکەن و چاکەچینی بۆ پێویستەکانی سەیارەکانت ئاسان دەکەن.`,
    },
    advancedSearch: {
      English: `Use the **Advanced Search** feature on **Ako Sayara** to find your ideal car quickly and easily. Filter results by make, model, price, year, location, and more to customise your search and discover the perfect vehicle for buying, selling, or renting. Our advanced tools help you navigate through a wide selection of cars to match your specific needs effortlessly.`,
      Arabic: `استخدم ميزة **البحث المتقدم** في **اكو سيارة** للعثور على سيارتك المثالية بسرعة وسهولة. قم بتصفية النتائج حسب النوع، الطراز، السعر، السنة، الموقع، والمزيد لتخصيص بحثك واكتشاف السيارة المثالية للشراء، البيع أو التأجير. تساعدك أدواتنا المتقدمة في التنقل بين مجموعة واسعة من السيارات لتلبية احتياجاتك الخاصة بكل سهولة.`,
      Kurdish: `بەکارهێنانی تایبەتمەندی گەڕانی پێشکەوتوو لە ئەکۆ سەیارە، سەیارەی پەرفێکتەکەت بەخێڕایی و ئاسان دەدۆزیەوە. دەرئەنجامەکان بەپێی جۆر، مۆدێل، نرخ، ساڵ، شوێن، و زۆرتر ڕێکبخە بۆ گەڕانێکی تایبەتی و دۆزینەوەی سەیارەی وەرزبوونەوە بۆ کڕین، فرۆشتن، یان کرێکردن. ئەم ئامرازە پێشکەوتوانەمان یارمەتی دەدەن بە ئاسانترین ڕێگە بەرەو ڕەگەزێکی فراوان بەرەو پێویستە تایبەتیەکانت.`,
    },
    buyCar: {
      English: `Looking to **Buy cars** in Iraq? **Ako Sayara** offers a vast selection of vehicles to suit every budget and preference, from economy models to luxury cars. Our user-friendly platform makes it easy to browse listings, compare options, and find the perfect car. Whether you're searching for a brand-new vehicle or a reliable used car, Ako Sayara provides a seamless buying experience. Start your search today!`,
      Arabic: `تبحث عن **شراء سيارات** في العراق؟ يقدم **اكو سيارة** مجموعة واسعة من السيارات لتناسب كل ميزانية وتفضيل، من النماذج الاقتصادية إلى السيارات الفاخرة. تسهل منصتنا سهلة الاستخدام تصفح القوائم، مقارنة الخيارات، والعثور على السيارة المثالية. سواء كنت تبحث عن سيارة جديدة تمامًا أو سيارة مستعملة موثوقة، يوفر اكو سيارة تجربة شراء سلسة. ابدأ البحث اليوم!`,
      Kurdish: `بەدوای کڕینی سەیارەکان لە عێراق دەگەڕێیت؟ ئەکۆ سەیارە ڕەگەزێکی گەورەی سەیارە پێشکەش دەکات بۆ هەموو بڕە پارە و دڵخواز، لە مۆدێلە ئابووریەکانەوە تا سەیارەکان لاکچەری. مەڕەکەمان بەکارهێنانەکە ئاسان دەکات بۆ گەڕان، هەڵبژاردنەکان پێکەوە بنەما، و دۆزینەوەی سەیارەی پەرفێکتە. ئەگەر بەدوای سەیارەی نوێیت یان سەیارەیەکی بەهێز و کارامەند بگەڕێیت، ئەکۆ سەیارە ئەنجامی کڕینی ئاسانە پێشکەش دەدات. ئەمڕۆ گەڕانت دەست پێبکە!`,
    },
    rentalSearch: {
      English: `Find the best **Rental cars** in Iraq with **Ako Sayara**. Whether you need a car for a short trip, business, or a special occasion, our platform offers a variety of rental options to suit your needs. From budget-friendly cars to luxury vehicles, Ako Sayara makes renting a car easy, reliable, and convenient. Book your rental car today!`,
      Arabic: `اعثر على أفضل **سيارات للإيجار** في العراق مع **اكو سيارة**. سواء كنت تحتاج إلى سيارة لرحلة قصيرة، عمل، أو مناسبة خاصة، توفر منصتنا مجموعة متنوعة من خيارات الإيجار لتناسب احتياجاتك. من السيارات الاقتصادية إلى السيارات الفاخرة، تجعل اكو سيارة استئجار سيارة أمرًا سهلًا، موثوقًا ومريحًا. احجز سيارتك للإيجار اليوم!`,
      Kurdish: `بگەڕێ بۆ باشترین سەیارە کرێیەکان لە عێراق لەگەڵ ئەکۆ سەیارە. ئەگەر بەدەستت پێویستە بۆ گەشتێکی کورت، بازرگانی، یان ڕووداوێکی تایبەتی، مەڕەکەمان ڕەگەزیەکانی کرێکردنە سەیارەکانت پێشکەش دەکات بۆ پێویستەکانت. لە سەیارەی ئابووری تا سەیارەی لاکچەری، ئەکۆ سەیارە کرێکردنی سەیارە دەکات بە ئاسان، بەبەست و ئاسودە. ئەمڕۆ سەیارەی کرێیەکەت بەردەوام بکە!`,
    },
    whyAkoSayara: {
      English: `**Ako Sayara** is Iraq's trusted digital automotive marketplace for buying, selling, renting, and checking cars. We combine data-driven insights with modern agility to provide an exceptional car shopping experience.`,
      Arabic: `**اكو سيارة** هو السوق الرقمي الموثوق للسيارات في العراق لشراء، بيع، تأجير، وفحص السيارات. نحن نجمع بين الرؤى المستندة إلى البيانات والمرونة الحديثة لتقديم تجربة تسوق سيارات استثنائية.`,
      Kurdish: `**ئەکۆ سەیارە** بازاڕی کەیڤی مووتورەکانەی باوەڕپەکراوی عێراقە بۆ کڕین، فرۆشتن، کرێکردن و پشکنینی مووتورەکان. ئێمە زانیاریەکان و چالاکییە نوێیەکان بەیەکەوە دایەوین بۆ دابینکردنی ئەزموونی کڕینێکی مووتورەکان کە پەرەیەک نایە.`,
    },
    myWhyAkoSayara: {
      English: `**Ako Sayara** is Iraq's trusted digital automotive marketplace for buying, selling, renting, and checking cars. We combine data-driven insights with modern agility to provide an exceptional car shopping experience.`,
      Arabic: `**اكو سيارة** هو السوق الرقمي الموثوق للسيارات في العراق لشراء، بيع، تأجير، وفحص السيارات. نحن نجمع بين الرؤى المستندة إلى البيانات والمرونة الحديثة لتقديم تجربة تسوق سيارات استثنائية.`,
      Kurdish: `**ئەکۆ سەیارە** بازاڕی کەیڤی مووتورەکانەی باوەڕپەکراوی عێراقە بۆ کڕین، فرۆشتن، کرێکردن و پشکنینی مووتورەکان. ئێمە زانیاریەکان و چالاکییە نوێیەکان بەیەکەوە دایەوین بۆ دابینکردنی ئەزموونی کڕینێکی مووتورەکان کە پەرەیەک نایە.`,
    },
    singleCar: {
      English: `Discover all the details of your desired vehicle with Single Car listings on Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.`,
      Arabic: `اكتشف جميع تفاصيل سيارتك المطلوبة مع قوائم السيارات الفردية في اكو سيارة. توفر كل صفحة سيارة معلومات شاملة، بما في ذلك الميزات، المواصفات، السعر، وصور عالية الجودة، مما يساعدك على اتخاذ قرار مستنير. سواء كنت تبحث عن الشراء، البيع، أو التأجير، استكشف كل جانب من جوانب السيارة في مكان واحد مريح.`,
      Kurdish: `هەموو وردەکارییەکان ببینە لەبارەی سەیارەی دڵخوازەکەت بە لیستکردنی سەیارەی تاکی لە ئەکۆ سەیارە. هەر لاپەڕەیەک بۆ سەیارە زانیاری بە تەواوی پێشکەش دەکات، وەکوو تایبەتمەندیەکان، وەسفەکان، نرخ، و وێنەی بەرز رەزوورە، کە یارمەتی دەدات بۆ بڕیارێکی ئاغادارەوە. ئەگەر بەدوای کڕین، فرۆشتن، یان کرێکردن، هەموو بەشی سەیارەکە لە شوێنێکی گونجاو بەدوای بکه.`,
    },
    singleRental: {
      English: `Discover all the details of your desired vehicle with Single Car listings on Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.`,
      Arabic: `اكتشف جميع تفاصيل سيارتك المطلوبة مع قوائم السيارات الفردية في اكو سيارة. توفر كل صفحة سيارة معلومات شاملة، بما في ذلك الميزات، المواصفات، السعر، وصور عالية الجودة، مما يساعدك على اتخاذ قرار مستنير. سواء كنت تبحث عن الشراء، البيع، أو التأجير، استكشف كل جانب من جوانب السيارة في مكان واحد مريح.`,
      Kurdish: `هەموو وردەکارییەکان ببینە لەبارەی سەیارەی دڵخوازەکەت بە لیستکردنی سەیارەی تاکی لە ئەکۆ سەیارە. هەر لاپەڕەیەک بۆ سەیارە زانیاری بە تەواوی پێشکەش دەکات، وەکوو تایبەتمەندیەکان، وەسفەکان، نرخ، و وێنەی بەرز رەزوورە، کە یارمەتی دەدات بۆ بڕیارێکی ئاغادارەوە. ئەگەر بەدوای کڕین، فرۆشتن، یان کرێکردن، هەموو بەشی سەیارەکە لە شوێنێکی گونجاو بەدوای بکه.`,
    },
    singleCarCheck: {
      English: `With Single Car Check on Ako Sayara, you can verify important details about any car before buying or renting. Access key information such as the car's history, condition, mileage, and more to ensure you're making a confident and informed decision. Get peace of mind with our reliable car check service.`,
      Arabic: `مع فحص السيارة الفردية على اكو سيارة، يمكنك التحقق من التفاصيل المهمة لأي سيارة قبل الشراء أو التأجير. احصل على معلومات رئيسية مثل تاريخ السيارة، حالتها، الأميال، والمزيد لضمان اتخاذ قرار واثق ومستنير. احصل على راحة البال مع خدمة فحص السيارات الموثوقة لدينا.`,
      Kurdish: `لەگەڵ پشکنینی سەیارەی تاک لە ئەکۆ سەیارە، دەتوانیت زانیارییە گرنگەکان بەپشکنینی پەیوەست بە هەر سەیارەیەک بگرێیتەوە پێش کڕین یان کرێکردن. دەتوانیت بەزانیارییە گرنگەکان وەکوو مێژووی سەیارەکە، بارودۆخی، ماوەی مۆتۆر، و زانیارییە زۆرتر بگەیتەوە بۆ بڕیاردانی وەرچێکی سەلامەت و ئاغادار. بەئارامی و هەستیار بێت بە بەکارهێنانی خزمەتی پشکنینی سەیارەی بەبەستی.`,
    }
  };
  
  // remaining
  
  // {
  //   login: `/Login`,
  //   forgotPassword: `/Forgot-password`,
  //   enterOTP: `/Forgot-password/Enter-otp`,
  //   signup: `/Sign-up`,
  //   basicDetails: `/Basic-details`,
  //   saved: `/Saved`,
  //   comparing: `/Comparing`,
  //   viewImage: `/View-image`,
  //   plan: `/Ad-plan`,
  //   renew: `/Renew-ad`,
  //   payment: `/Payment`,
  //   viewMap: `/View-on-map`,
  //   cheapCars: `/Cheap-cars`,
  //   singleBlog: `/singleBlog`,
  //   singleGuide: `/singleGuide`,
  //   dealershipDocument: `/Dealership-document`,
  //   search: `/Search`,
  //   rentalSearch: `/Rental-search`,
  //   singleCarCheck: `/Single-car-check`,
  //   searchResult: `/Search-results`,
  //   rentalSearchResult: `/RentalSearchResult`,
  //   dealerRental: `/Dealer-rental`,
  //   dealerCarView: `/Dealer-cars`,
  //   searchResultPage: `/Dealer-results`,
  //   dealerSearchResults: `/Dealer-search-results`,
  //   enquiry: `/Enquiry`,
  //   myAccount: `/My-account`,
  //   myVehicles: `/My-account/Vehicles`,
  //   editCar: `/My-account/Edit-car`,
  //   editProfile: `/My-account/Edit-profile`,
  //   security: `/My-account/Security`,
  //   editSecurity: `/My-account/Edit-security`,
  //   transactions: `/My-account/Transactions`,
  //   messages: `/My-account/Messages`,
  //   perfomance: `/My-account/Perfomance`,
  //   myProfile: `/My-account/My-profile`,
  //   myProfile1: `/My-account`,
  //   editWorkHour: `/My-account/Edit-work-hours`,
  // };
  