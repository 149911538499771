export const faq = [
  {
    id: 1,
    que: {
      en: "How can Ako Sayara help me buy a used car in Iraq?",
      ar: "كيف يمكنني شراء سيارة مستعملة في العراق من خلال اكو سيارة؟",
      ku: "چۆن دەتوانم ئۆتۆمبێلی بەکارهاتوو لە عێراق لە ڕێگەی ئەکو سەیارە بکڕم؟",
    },
    ans: {
      en: "Ako Sayara connects buyers with verified sellers, offering a wide selection of used cars in cities like Baghdad, Erbil, and Basra. Our platform ensures a smooth and secure transaction process, helping you find the right car at the right price.",
      ar: "يربط اكو سيارة المشترين بالبائعين الموثوقين، ويوفر مجموعة واسعة من السيارات المستعملة في مدن مثل بغداد، أربيل، والبصرة. تضمن منصتنا عملية سلسة وآمنة لمساعدتك في العثور على السيارة المناسبة بالسعر المناسب.",
      ku: "ئەکو سەیارە کڕیاران بە فرۆشیارانی متمانەپێکراوەوە دەبەستێتەوە، هەروەها کۆمەڵێک ئۆتۆمبێلی بەکارهێنراوی بەرفراوان لە شارەکانی وەک بەغدا، هەولێر، و بەسرە دابین دەکات. پلاتفۆرمەکەمان پرۆسەیەکی نەرم و پارێزراو مسۆگەر دەکات بۆ ئەوەی یارمەتیت بدات لە دۆزینەوەی ئۆتۆمبێلی گونجاو بە نرخێکی گونجاو.",
    },
  },
  {
    id: 2,
    que: {
      en: "How do I sell a used car in Iraq through Ako Sayara?",
      ar: "كيف أبيع سيارة مستعملة في العراق عبر اكو سيارة؟",
      ku: "چۆن ئۆتۆمبێلی بەکارهاتوو لە عێراق لە ڕێگەی ئەکو سەیارە بفرۆشم؟",
    },
    ans: {
      en: "Selling your used car with Ako Sayara is simple. List your car on our platform with detailed information and photos. We ensure your listing reaches potential buyers across Iraq, including major cities like Sulaymaniyah and Mosul.",
      ar: "بيع سيارتك المستعملة عبر اكو سيارة بسيط جدًا. قم بإدراج سيارتك على منصتنا مع معلومات وصور تفصيلية. نحن نضمن أن إعلانك يصل إلى المشترين المحتملين في جميع أنحاء العراق، بما في ذلك المدن الكبرى مثل السليمانية والموصل.",
      ku: "فرۆشتنی ئۆتۆمبێلە بەکارهاتووەکەت لە ڕێگەی ئەکو سەیارە زۆر ئاسانە. ئۆتۆمبێلەکەت لە پلاتفۆرمی ئێمەدا بنووسە لەگەڵ زانیاری و وێنەی ورد. ئێمە دڵنیا دەبینەوە لەوەی ڕیکلامەکەت دەگاتە دەستی کڕیارە بەتواناکان لە سەرتاسەری عێراق، بە شارە گەورەکانی وەک سلێمانی و موسڵیشەوە.",
    },
  },
  {
    id: 3,
    que: {
      en: "Can I rent a car in Iraq for short or long-term use?",
      ar: "هل يمكنني استئجار سيارة في العراق للاستخدام القصير أو الطويل؟",
      ku: "دەتوانم ئۆتۆمبێل لە عێراق بەکرێ بگرم بۆ بەکارهێنانی کورت یان درێژ؟",
    },
    ans: {
      en: "Yes, Ako Sayara offers flexible car rental options for short-term or long-term use. Whether you’re visiting Erbil for a weekend or need a car in Basra for a month, we have a variety of vehicles to suit your needs.",
      ar: "نعم، يوفر اكو سيارة خيارات مرنة لاستئجار السيارات لفترات قصيرة أو طويلة. سواء كنت تزور أربيل لعطلة نهاية الأسبوع أو تحتاج إلى سيارة في البصرة لمدة شهر، لدينا مجموعة متنوعة من السيارات لتلبية احتياجاتك.",
      ku: "بەڵێ، ئیکۆ کار بژاردەی نەرم و نیان بۆ بەکرێدانی ئۆتۆمبێل بۆ ماوەیەکی کورت یان درێژ دابین دەکات. جا بۆ کۆتایی هەفتە سەردانی هەولێر بکەیت یان بۆ ماوەی مانگێک پێویستت بە ئۆتۆمبێل بێت لە بەسرە، ئۆتۆمبێلی جۆراوجۆرمان هەیە بۆ دابینکردنی پێداویستییەکانتان.",
    },
  },
  {
    id: 4,
    que: {
      en: "Is it possible to buy a new car in Iraq through Ako Sayara?",
      ar: "هل يمكنني شراء سيارة جديدة في العراق عبر اكو سيارة؟",
      ku: "دەتوانم لە ڕێگەی ئەکو سایەرە ئۆتۆمبێلێکی نوێ لە عێراق بکڕم؟",
    },
    ans: {
      en: "Absolutely! Ako Sayara works with trusted dealerships to offer new cars for sale in Iraq. Browse our platform to explore the latest models available in cities like Baghdad, Basra, and Kurdistan Region.",
      ar: "بالتأكيد! يعمل اكو سيارة مع وكلاء معتمدين لتوفير سيارات جديدة للبيع في العراق. استعرض منصتنا لاستكشاف أحدث الموديلات المتوفرة في مدن مثل بغداد، البصرة، وإقليم كردستان.",
      ku: "دڵنیابە! کۆمپانیای ئەکو سەیارە لەگەڵ بریکارە ڕێگەپێدراوەکان کاردەکات بۆ دابینکردنی ئۆتۆمبێلی نوێ بۆ فرۆشتن لە عێراق. لە پلاتفۆرمەکەماندا بگەڕێ بۆ دۆزینەوەی نوێترین مۆدێلەکان کە لە شارەکانی وەک بەغدا و بەسرە و هەرێمی کوردستان بەردەستن.",
    },
  },
  {
    id: 5,
    que: {
      en: "How do I sell my car in Iraq quickly?",
      ar: "كيف أبيع سيارتي في العراق بسرعة؟",
      ku: "چۆن ئۆتۆمبێلەکەم لە عێراق بە خێرایی بفرۆشم؟",
    },
    ans: {
      en: "To sell your car in Iraq quickly, list it on Ako Sayara with accurate details and clear photos. Our platform attracts buyers from across Iraq, increasing your chances of a fast and hassle-free sale.",
      ar: "لبيع سيارتك في العراق بسرعة، قم بإدراجها على اكو سيارة مع تفاصيل دقيقة وصور واضحة. منصتنا تجذب المشترين من جميع أنحاء العراق، مما يزيد من فرص البيع السريع والخالي من المتاعب.",
      ku: "بۆ ئەوەی ئۆتۆمبێلەکەت لە عێراق بە خێرایی بفرۆشیت، لە ئەکو سەیارە بینووسە بە وردەکاریی ورد و وێنەی ڕوون. پلاتفۆرمەکەمان کڕیاران لە سەرتاسەری عێراقەوە بۆ لای خۆی ڕادەکێشێت، ئەمەش چانسی فرۆشتنی خێرا و بێ کێشە زیاد دەکات.",
    },
  },
  {
    id: 6,
    que: {
      en: "Can I rent my car in Iraq through Ako Sayara?",
      ar: "هل يمكنني تأجير سيارتي في العراق عبر اكو سيارة؟",
      ku: "دەتوانم لە ڕێگەی ئەکو سەیارە ئۆتۆمبێلەکەم لە عێراق بەکرێ بگرم؟",
    },
    ans: {
      en: "Yes, Ako Sayara allows car owners to rent their vehicles to others. List your car on our platform to earn income by renting it out to trusted renters in cities like Baghdad, Basra, Erbil, Sulaymaniyah, and beyond.",
      ar: "نعم، يتيح اكو سيارة لأصحاب السيارات تأجير مركباتهم للآخرين. قم بإدراج سيارتك على منصتنا لتحقيق دخل إضافي من خلال تأجيرها لمستأجرين موثوقين في مدن مثل بغداد، البصرة، أربيل، السليمانية وغيرها.",
      ku: "بەڵێ ئەکو سەیارە ڕێگە بە خاوەن ئۆتۆمبێلەکان دەدات ئۆتۆمبێلەکانیان بەکرێ بگرن بە کەسانی تر. ئۆتۆمبێلەکەت لە پلاتفۆرمی ئێمەدا بنووسە بۆ ئەوەی داهاتی زیادە بەدەست بهێنیت بە کرێدانی بە کرێچی باوەڕپێکراو لە شارەکانی وەک بەغدا و بەسرە و هەولێر و سلێمانی و ئەوانی تر.",
    },
  },
  {
    id: 7,
    que: {
      en: "What types of cars can I rent through Ako Sayara?",
      ar: "ما أنواع السيارات التي يمكنني استئجارها عبر اكو سيارة؟",
      ku: "چ جۆرە ئۆتۆمبێلێک دەتوانم لە ڕێگەی ئیکۆسیارا بەکرێ بگرم؟",
    },
    ans: {
      en: "Ako Sayara offers a variety of cars for rent in Iraq, including Saloons, SUVs, luxury cars, and more. Whether you need a budget-friendly compact car or a high-end vehicle, we have options to meet your requirements.",
      ar: "يوفر اكو سيارة مجموعة متنوعة من السيارات للإيجار في العراق، بما في ذلك سيارات السيدان، سيارات الدفع الرباعي، السيارات الفاخرة، وغيرها. سواء كنت بحاجة إلى سيارة اقتصادية مدمجة أو مركبة فاخرة، لدينا خيارات تناسب متطلباتك.",
      ku: "ئیکۆ کار جۆرەها ئۆتۆمبێلی بۆ کرێ لە عێراق دابین دەکات، لەوانە ئۆتۆمبێلی سیدان و ئێس یو ڤی و ئۆتۆمبێلی لوکس و ئەوانی تر. جا پێویستت بە ئۆتۆمبێلێکی ئابووری کۆمپاکت بێت یان ئۆتۆمبێلێکی لوکس، ئێمە بژاردەمان هەیە کە لەگەڵ پێداویستییەکانتان بگونجێت.",
    },
  },
  {
    id: 8,
    que: {
      en: "How do I ensure I’m getting a good deal when I buy a used car in Iraq?",
      ar: "كيف أضمن أنني أحصل على صفقة جيدة عند شراء سيارة مستعملة في العراق؟",
      ku: "چۆن دڵنیا بم لەوەی کە گرێبەستێکی باشم دەست دەکەوێت لەکاتی کڕینی ئۆتۆمبێلی بەکارهاتوو لە عێراق؟",
    },
    ans: {
      en: "Ako Sayara provides detailed car listings with comprehensive information, vehicle history reports, and inspection options. This ensures you’re making an informed decision when buying a used car in Iraq.",
      ar: "يقدم اكو سيارة قوائم سيارات تفصيلية تحتوي على معلومات شاملة، تقارير تاريخ السيارة، وخيارات الفحص. يضمن ذلك اتخاذ قرار مستنير عند شراء سيارة مستعملة في العراق.",
      ku: "ئەکو سەیارە لیستی وردی ئۆتۆمبێلەکان دەدات کە زانیاری گشتگیر و ڕاپۆرتی مێژووی ئۆتۆمبێل و بژاردەی پشکنین لەخۆدەگرێت. ئەمەش دڵنیای دەدات لەوەی کە لە کاتی کڕینی ئۆتۆمبێلی بەکارهاتوو لە عێراق بڕیارێکی ئاگادارانە دەدەیت.",
    },
  },
  {
    id: 9,
    que: {
      en: "Are there specific requirements for renting a car in Iraq?",
      ar: "هل هناك متطلبات معينة لاستئجار سيارة في العراق؟",
      ku: "ئایا مەرجی تایبەت هەیە بۆ بەکرێدانی ئۆتۆمبێل لە عێراق؟",
    },
    ans: {
      en: "To rent a car in Iraq, you need a valid driver’s license, a government-issued ID or passport, and a payment method such as a credit or debit card. Ako Sayara works with reliable rental providers to make the process easy.",
      ar: "لاستئجار سيارة في العراق، تحتاج إلى رخصة قيادة سارية، هوية حكومية أو جواز سفر، وطريقة دفع مثل بطاقة ائتمان أو خصم. يعمل اكو سيارة مع مزودي تأجير موثوقين لتسهيل العملية.",
      ku: "بۆ بەکرێگرتنی ئۆتۆمبێل لە عێراق پێویستت بە مۆڵەتی شۆفێری کارا و ناسنامەی حکومی یان پاسپۆرت و شێوازی پارەدان وەک کارتی بانکیی یان بانکی پێویستە. ئەکو سەیارە لەگەڵ دابینکەرانی کرێی متمانەپێکراو کاردەکات بۆ ئەوەی پرۆسەکە ئاسانتر بێت.",
    },
  },
  {
    id: 10,
    que: {
      en: "Why should I choose Ako Sayara to buy, sell, or rent cars in Iraq?",
      ar: "لماذا يجب علي اختيار اكو سيارة لشراء، بيع، أو استئجار السيارات في العراق؟",
      ku: "بۆچی ئەکو سەیارە هەڵبژێرم بۆ کڕین، فرۆشتن، یان بەکرێدانی ئۆتۆمبێل لە عێراق؟",
    },
    ans: {
      en: "Ako Sayara is a trusted platform that simplifies car buying, selling, and renting in Iraq. Whether you want to buy a new car in Iraq, sell your car in Iraq, or rent a car in Iraq, we offer a seamless experience with competitive prices and reliable service.",
      ar: "يعتبر اكو سيارة منصة موثوقة تسهل عملية شراء، بيع، وتأجير السيارات في العراق. سواء كنت ترغب في شراء سيارة جديدة في العراق، بيع سيارتك، أو استئجار سيارة، نقدم لك تجربة سلسة بأسعار تنافسية وخدمة موثوقة.",
      ku: "ئەکو سەیارە بە پلاتفۆرمێکی متمانەپێکراوە کە کارەکانی کڕین ، فرۆشتن و یابەکرێی ئۆتۆمبێل کورت دەکات جا کە کڕینی ئۆتۆمبێلێکی نوێت سەیارە بەپێی نرخە ئاسانتره‌.",
    },
  },
];

export const faq_rent = [
  {
    id: 1,
    que: {
      en: "What documents do I need to rent a car with Ako Sayara?",
      ar: "ما المستندات المطلوبة لاستئجار سيارة من اكو سيارة؟",
      ku: "چ بەڵگەنامەیەکم پێویستە بۆ بەکرێدانی ئۆتۆمبێل لەگەڵ ئاکۆ سەیارە ؟",
    },
    ans: {
      en: "You’ll need a valid driver’s license, a government-issued ID or passport, and a payment method for the rental.",
      ar: "ستحتاج إلى رخصة قيادة سارية المفعول وبطاقة هوية حكومية أو جواز سفر وطريقة دفع.",
      ku: "پێویستت بە مۆڵەتی شۆفێری کارا دەبێت، ناسنامە یان پاسپۆرتێک کە لەلایەن حکومەتەوە دەرچووە، و شێوازی پارەدان بۆ کرێ.",
    },
  },
  {
    id: 2,
    que: {
      en: "Does Ako Sayara provide insurance with car rentals?",
      ar: "هل توفر اكو سيارة التأمين مع سيارات الإيجار؟",
      ku: "ئایا ئاکۆ سەیارە  بیمە لەگەڵ کرێی ئۆتۆمبێل دابین دەکات؟",
    },
    ans: {
      en: "Yes, most car rentals on Ako Sayara include basic insurance. Additional coverage options may also be available.",
      ar: "نعم، معظم إيجارات السيارات عبر اكو سيارة تشمل تأمينًا أساسيًا، مع توفر خيارات تغطية إضافية.",
      ku: "بەڵێ زۆربەی کرێی ئۆتۆمبێل لەسەر ئاکۆ سایارە بیمەی بنەڕەتی لەخۆدەگرێت. هەروەها ڕەنگە بژاردەی داپۆشینی زیادە بەردەست بێت.",
    },
  },
  {
    id: 3,
    que: {
      en: "Can I rent a car in Iraq for long-term use?",
      ar: "هل يمكنني استئجار سيارة لمدة طويلة في العراق؟",
      ku: "ئایا دەتوانم لە عێراق ئۆتۆمبێل بە کرێ بگرم بۆ بەکارهێنانی درێژخایەن؟",
    },
    ans: {
      en: "Yes, Ako Sayara offers flexible rental plans for both short-term and long-term rentals.",
      ar: "نعم، تقدم اكو سيارة خطط إيجار مرنة تناسب المدد القصيرة والطويلة.",
      ku: "بەڵێ، ئاکۆ سەیارە پلانی کرێی نەرم و نیان پێشکەش دەکات بۆ هەردوو کرێی کورتخایەن و درێژخایەن",
    },
  },
  {
    id: 4,
    que: {
      en: "Are electric cars available for rent on Ako Sayara?",
      ar: "هل تتوفر سيارات كهربائية للإيجار عبر اكو سيارة؟",
      ku: "ئایا ئۆتۆمبێلی کارەبایی بۆ کرێ لە ئاکۆ سایارە بەردەستە؟",
    },
    ans: {
      en: "Yes, Ako Sayara provides a selection of electric and hybrid vehicles for eco-conscious renters.",
      ar: "نعم، توفر اكو سيارة مجموعة من السيارات الكهربائية والهجينة للمستأجرين المهتمين بالبيئة.",
      ku: "بەڵێ، ئاکۆ سایارە هەڵبژاردنی ئۆتۆمبێلی کارەبایی و هایبرید بۆ کرێچیانی هۆشیاری ژینگەیی دابین دەکات.",
    },
  },
  {
    id: 5,
    que: {
      en: "What is the cost of renting a car with Ako Sayara?",
      ar: "ما تكلفة استئجار سيارة من اكو سيارة؟",
      ku: "تێچووی بەکرێدانی ئۆتۆمبێل لەگەڵ ئاکۆ سایارە چەندە؟",
    },
    ans: {
      en: "Rental costs vary depending on the car type, duration, and location. Economy cars typically start at IQD 50,000 per day, while luxury options may cost IQD 150,000 or more per day.",
      ar: "تختلف التكاليف وفقًا لنوع السيارة ومدة الإيجار والموقع. تبدأ سيارات الاقتصاد من حوالي 50,000 دينار عراقي في اليوم، بينما قد تصل السيارات الفاخرة إلى 150,000 دينار أو أكثر يومياً",
      ku: "تێچووی کرێ بەپێی جۆری ئۆتۆمبێل و ماوەی ئۆتۆمبێل و شوێنەکە دەگۆڕێت. ئۆتۆمبێلە ئابوورییەکان بە شێوەیەکی گشتی لە ٥٠ هەزار دینارەوە دەست پێدەکەن بۆ ڕۆژێک، لەکاتێکدا بژاردەی لوکس ڕەنگە ١٥٠ هەزار دینارە یان زیاتر لە ڕۆژێکدا تێبچێت.",
    },
  },
];

export const faq_newcars = [
  {
    id: 1,
    que: {
      en: "What brands are available on Ako Sayara for new cars?",
      ar: "ما العلامات التجارية المتوفرة على اكو سيارة للسيارات الجديدة؟",
      ku: "چ مارکەیەک لە ئەکو سەیارە بەردەستە بۆ ئۆتۆمبێلی نوێ؟",
    },
    ans: {
      en: "You can find leading brands like Toyota, Hyundai, Kia, Nissan, Mitsubishi, and more.",
      ar: "يمكنك العثور على علامات رائدة مثل تويوتا، هيونداي، كيا، نيسان، ميتسوبيشي وغيرها.",
      ku: "دەتوانیت براندە پێشەنگەکانی وەک تۆیۆتا، هیۆندا، کیا، نیسان، میتسوبیشی و ئەوانی تر بدۆزیتەوە.",
    },
  },
  {
    id: 2,
    que: {
      en: "Are there financing options available?",
      ar: "هل تتوفر خيارات تمويل؟",
      ku: "ئایا هەڵبژاردنی دارایی (تمویل) بەردەستە؟",
    },
    ans: {
      en: "Yes, Ako Sayara partners with financial institutions to offer flexible loan and payment plans.",
      ar: "نعم، تتعاون اكو سيارة مع مؤسسات مالية لتقديم قروض وخطط دفع مرنة.",
      ku: "بەڵێ، ئەکو سەیارە هاوکاری دامەزراوە داراییەکان دەکات بۆ پێشکەشکردنی قەرز و پلانی پارەدانی نەرم و نیان.",
    },
  },
  {
    id: 3,
    que: {
      en: "Do new cars come with warranties?",
      ar: "هل تأتي السيارات الجديدة مع ضمانات؟",
      ku: "ئایا ئۆتۆمبێلی نوێ گەرەنتی لەگەڵدایە؟",
    },
    ans: {
      en: "Yes, all new cars listed on Ako Sayara include manufacturer warranties.",
      ar: "نعم، جميع السيارات الجديدة المدرجة في اكو سيارة تشمل ضمانات المصنع.",
      ku: "بەڵێ، هەموو ئەو ئۆتۆمبێلە نوێیانەی کە لە ئەکو سەیارە هاتووە، گەرەنتی کارگەیان تێدایە.",
    },
  },
  {
    id: 4,
    que: {
      en: "Can I customize my new car?",
      ar: "هل يمكنني تخصيص سيارتي الجديدة؟",
      ku: "ئایا دەتوانم ئۆتۆمبیلی نوێکەم بەپێی مەبەستی خۆم چاک بکەم؟",
    },
    ans: {
      en: "Customization options such as paint colors, interiors, and features are available through some dealerships.",
      ar: "تتوفر خيارات تخصيص مثل ألوان الطلاء والديكورات الداخلية والميزات الإضافية لدى بعض الوكلاء.",
      ku: "بژاردەی خۆکارکردن وەک ڕەنگی بۆیاخ و ڕوپۆشکردنی ناوەوە و تایبەتمەندی زیادە لە هەندێک لە بریکارەکاندا بەردەستە.",
    },
  },
  {
    id: 5,
    que: {
      en: "How do I find the best deals on Ako Sayara?",
      ar: "كيف أجد أفضل العروض على اكو سيارة؟",
      ku: "چۆن باشترین ئۆفەرەکان لە ئەکو سەیارە بدۆزمەوە؟",
    },
    ans: {
      en: "Check the platform regularly for promotions, compare listings, and contact dealers for the latest offers.",
      ar: "تفقد المنصة بانتظام للاطلاع على العروض الترويجية، وقارن الإعلانات، وتواصل مع الوكلاء للحصول على أحدث العروض.",
      ku: "بە بەردەوامی پلاتفۆرمەکە بپشکنە بۆ بینینی پرۆمۆشنەکان، بەراوردکردنی ڕێکلامەکان و پەیوەندیکردن بە بریکارەکانەوە بۆ نوێترین ئۆفەرەکان.",
    },
  },
];

export const faq_Sell_your_car = [
  {
    id: 1,
    que: {
      en: "How do I list my car on Ako Sayara?",
      ar: "كيف يمكنني إدراج سيارتي على اكو سيارة؟",
      ku: "چۆن دەتوانم ئۆتۆمبیلەکەم لە ئەکو سەیارە تۆمار بکەم؟",
    },
    ans: {
      en: "Simply sign up, create a profile, and upload your car’s details and photos.",
      ar: "ببساطة أنشئ حسابًا، ثم أنشئ ملفًا شخصيًا وارفع تفاصيل سيارتك وصورها.",
      ku: "تەنها ئەکاونتێک دروست بکە و پڕۆفایلی خۆت دروست بکە و وردەکاری ئۆتۆمبێلەکەت و وێنەکانت باربکە.",
    },
  },
  {
    id: 2,
    que: {
      en: "Does Ako Sayara charge for listings?",
      ar: "هل تفرض اكو سيارة رسومًا على الإعلانات؟",
      ku: "ئایا ئەکو سەیارە پارەی ڕیکلام وەردەگرێت؟",
    },
    ans: {
      en: "You can choose between free listings or paid promotions for more visibility.",
      ar: "يمكنك الاختيار بين الإعلانات المجانية أو العروض الترويجية المدفوعة للحصول على ظهور أكبر.",
      ku: "دەتوانیت لە نێوان ڕیکلامی بێبەرامبەر یان پرۆمۆشنی پارەداردا هەڵبژێریت بۆ ئەوەی زیاتر بەرکەوتنت هەبێت.",
    },
  },
  {
    id: 3,
    que: {
      en: "How long does it take to sell a car on Ako Sayara?",
      ar: "كم من الوقت يستغرق بيع سيارة على اكو سيارة؟",
      ku: "چەند کات دەخایەنێت بۆ فرۆشتنی ئۆتۆمبێل لە ئەکو سەیارە؟",
    },
    ans: {
      en: "The time varies, but competitive pricing and a well-presented ad often lead to quick sales.",
      ar: "يختلف الوقت، ولكن التسعير المنافس والإعلان الجيد يؤديان عادةً إلى بيع سريع.",
      ku: "کات دەگۆڕێت، بەڵام نرخی کێبڕکێ و ڕیکلامی باش بەزۆری دەبێتە هۆی فرۆشتنی خێرا.",
    },
  },
  {
    id: 4,
    que: {
      en: "Can I edit my car listing after posting?",
      ar: "هل يمكنني تعديل إعلان سيارتي بعد نشره؟",
      ku: "ئایا دەتوانم دوای بڵاوبوونەوەی ڕیکلامی ئۆتۆمبێلەکەم دەستکاری بکەم؟",
    },
    ans: {
      en: "Yes, you can update your listing anytime to make changes or add new details.",
      ar: "نعم، يمكنك تحديث إعلانك في أي وقت لتعديل المعلومات أو إضافة تفاصيل جديدة.",
      ku: "بەڵێ دەتوانیت لە هەر کاتێکدا ڕێکلامەکەت نوێ بکەیتەوە بۆ دەستکاریکردنی زانیارییەکان یان زیادکردنی وردەکاری نوێ.",
    },
  },
  {
    id: 5,
    que: {
      en: "Is my car’s information secure on Ako Sayara?",
      ar: "هل معلومات سيارتي آمنة على اكو سيارة؟",
      ku: "ئایا زانیاری ئۆتۆمبیلەکەم لە ئەکو سەیارە سەلامەتە؟",
    },
    ans: {
      en: "Yes, Ako Sayara ensures the privacy and security of your data.",
      ar: "نعم، تضمن اكو سيارة خصوصية وأمان بياناتك.",
      ku: "بەڵێ، ئەکو سەیارە گەرەنتی پاراستنی نهێنی و ئاسایشی داتاکانتان دەکات.",
    },
  },
  {
    id: 6,
    que: {
      en: "Can I sell both new and used cars on Ako Sayara?",
      ar: "هل يمكنني بيع سيارات جديدة ومستعملة على اكو سيارة؟",
      ku: "ئایا دەتوانم ئۆتۆمبێلی نوێ و بەکارهاتوو لە ئەکو سەیارە بفرۆشم؟",
    },
    ans: {
      en: "Yes, Ako Sayara supports the sale of both new and used cars.",
      ar: "نعم، تدعم اكو سيارة بيع كل من السيارات الجديدة والمستعملة.",
      ku: "بەڵێ، ئەکو سەیارە پشتگیری لە فرۆشتنی هەردوو ئۆتۆمبێلی نوێ و بەکارهاتوو دەکات.",
    },
  },
  {
    id: 7,
    que: {
      en: "What’s the best way to attract buyers?",
      ar: "ما أفضل طريقة لجذب المشترين؟",
      ku: "باشترین ڕێگا بۆ ڕاکێشانی کڕیاران چییە؟",
    },
    ans: {
      en: "Use clear photos, detailed descriptions, and set a competitive price to attract buyers.",
      ar: "استخدم صور واضحة ووصفًا مفصلًا، وحدد سعرًا منافسًا لجذب المزيد من المشترين.",
      ku: "وێنەی ڕوون و وەسفی ورد بەکاربهێنە، و نرخێکی کێبڕکێ دابنێ بۆ ئەوەی کڕیاری زیاتر ڕابکێشیت.",
    },
  },
  {
    id: 8,
    que: {
      en: "Can Ako Sayara help with pricing my car?",
      ar: "هل تساعدني اكو سيارة في تسعير سيارتي؟",
      ku: "ئایا ئەکو سەیارە یارمەتیم دەدات بۆ نرخکردنی ئۆتۆمبێلەکەم؟",
    },
    ans: {
      en: "While Ako Sayara doesn’t directly set prices, you can compare similar listings to determine a fair price.",
      ar: "لا تحدد اكو سيارة الأسعار مباشرةً، ولكن يمكنك مقارنة الإعلانات المشابهة لتحديد سعر مناسب.",
      ku: "ئەکو سەیارە ڕاستەوخۆ نرخەکان دانانێت، بەڵام دەتوانیت ڕیکلامە هاوشێوەکان بەراورد بکەیت بۆ دیاریکردنی نرخێکی گونجاو.",
    },
  },
  {
    id: 9,
    que: {
      en: "Are there any guarantees for selling my car?",
      ar: "هل هناك أي ضمانات لبيع سيارتي؟",
      ku: "ئایا هیچ گەرەنتییەک هەیە لەسەر فرۆشتنی ئۆتۆمبێلەکەم؟",
    },
    ans: {
      en: "Ako Sayara provides a robust platform, but the final sale depends on buyer interest and your car’s value.",
      ar: "توفر اكو سيارة منصة قوية، لكن البيع النهائي يعتمد على اهتمام المشترين وقيمة سيارتك.",
      ku: "ئەکو سەیارە پلاتفۆرمێکی بەهێز دابین دەکات، بەڵام فرۆشتنی کۆتایی پەیوەستە بە بەرژەوەندی کڕیاران و بەهای ئۆتۆمبێلەکەتەوە.",
    },
  },
  {
    id: 10,
    que: {
      en: "Does Ako Sayara offer any post-sale support?",
      ar: "هل توفر اكو سيارة أي دعم بعد البيع؟",
      ku: "ئایا هیچ پشتگیرییەک لە دوای فرۆشتن پێشکەش دەکات؟",
    },
    ans: {
      en: "Yes, Ako Sayara’s team can assist with finalizing transactions and other inquiries.",
      ar: "نعم، يمكن لفريق اكو سيارة مساعدتك في إتمام المعاملات والإجابة على أي استفسارات أخرى.",
      ku: "بەڵێ، تیمی ئەکو سەیارە دەتوانێت یارمەتیت بدات لە تەواوکردنی مامەڵەکان و وەڵامدانەوەی هەر پرسیارێکی تر.",
    },
  },
];
export const faq_used_cars = [
  {
    id: 1,
    que: {
      en: "How do I find the best used car deals on Ako Sayara?",
      ar: "كيف يمكنني العثور على أفضل العروض على السيارات المستعملة في اكو سيارة؟",
      ku: "چۆن بتوانم باشترین ئۆفەرەکان لەسەر ئۆتۆمبێلی بەکارهاتوو لە ئاکو سەیارە بدۆزمەوە؟",
    },
    ans: {
      en: "Use Ako Sayara’s filters for brand, price, mileage, and location to browse listings and compare options.",
      ar: "استخدم فلاتر اكو سيارة للشركة، السعر، المسافة المقطوعة، والموقع لاستعراض الإعلانات ومقارنة الخيارات.",
      ku: "فلتەرەکانی ئاکو سەیارە بەکاربهێنە بۆ کۆمپانیا، نرخ، میل و شوێن بۆ گەڕان بە ڕێکلامەکان و بەراوردکردنی بژاردەکان.",
    },
  },
  {
    id: 2,
    que: {
      en: "Are the used cars on Ako Sayara inspected?",
      ar: "هل السيارات المستعملة على اكو سيارة تخضع للفحص؟",
      ku: "ئایا ئۆتۆمبێلی بەکارهاتوو لەسەر ئاکو سەیارە پشکنینیان بۆ ئەکرێت؟",
    },
    ans: {
      en: "Many listings include inspection details, but buyers should independently verify the car’s condition before purchasing.",
      ar: "تتضمّن العديد من الإعلانات تفاصيل الفحص، لكن يُنصَح المشترون بالتحقّق شخصيًا من حالة السيارة قبل الشراء.",
      ku: "زۆرێک لە ڕیکلامەکان وردەکاری پشکنینی ئۆتۆمبێلەکە لەخۆدەگرن، بەڵام کڕیاران پێویستە بە شێوەیەکی سەربەخۆ پێش کڕینی دۆخی ئۆتۆمبێلەکە پشتڕاست بکەنەوە.",
    },
  },
  {
    id: 1,
    que: {
      en: "Can I negotiate the price of a used car on Ako Sayara?",
      ar: "هل يمكنني التفاوض على سعر السيارة المستعملة على اكو سيارة؟",
      ku: "ئایا دەتوانم دانوستان لەسەر نرخی ئۆتۆمبێلی بەکارهاتوو لە ئاکو سەیارە بکەم؟",
    },
    ans: {
      en: "Yes, Ako Sayara allows direct communication with sellers, enabling you to negotiate and finalize a deal.",
      ar: "نعم، يتيح لك اكو سيارة التواصل المباشر مع البائعين، مما يمكّنك من التفاوض والوصول إلى اتفاق مناسب.",
      ku: "بەڵێ، ئاکو سەیارە ڕێگەت پێدەدات ڕاستەوخۆ پەیوەندی لەگەڵ فرۆشیارەکان بکەیت، ئەمەش ڕێگەت پێدەدات دانوستان بکەیت و بگەیتە ڕێککەوتنێکی گونجاو.",
    },
  },
  {
    id: 4,
    que: {
      en: "What documents are needed to buy a used car in Iraq?",
      ar: "ما الوثائق المطلوبة لشراء سيارة مستعملة في العراق؟",
      ku: "چ بەڵگەنامەیەک پێویستە بۆ کڕینی ئۆتۆمبێلی بەکارهاتوو لە عێراق؟",
    },
    ans: {
      en: "You’ll need the car’s ownership certificate, maintenance records, and official transfer documents for a smooth transaction.",
      ar: "ستحتاج إلى شهادة ملكية السيارة، وسجلات الصيانة، والمستندات الرسمية لنقل الملكية لضمان عملية سلسة.",
      ku: "بۆ گواستنەوەی خاوەندارێتی ئۆتۆمبێلەکە پێویستت بە بڕوانامەی خاوەندارێتی ئۆتۆمبێلەکە و تۆماری چاککردنەوە و بەڵگەنامەی فەرمی دەبێت بۆ ئەوەی پرۆسەکە بە ئاسانی بەڕێوەبچێت.",
    },
  },
  {
    id: 5,
    que: {
      en: "Can I sell my car on Ako Sayara?",
      ar: "هل أستطيع بيع سيارتي على اكو سيارة؟",
      ku: "ئایا دەتوانم ئۆتۆمبێلەکەم لە ئاکو سەیارە بفرۆشم؟",
    },
    ans: {
      en: "Yes, you can create a listing with your car's details and photos to attract buyers quickly on Ako Sayara.",
      ar: "نعم، يمكنك إنشاء إعلان يتضمّن تفاصيل سيارتك وصورها لجذب المشترين بسرعة عبر اكو سيارة.",
      ku: "بەڵێ دەتوانیت ڕیکلامێک دروست بکەیت کە وردەکاری و وێنەی ئۆتۆمبێلەکەت لەخۆ بگرێت بۆ ئەوەی بە خێرایی کڕیارەکان لە ڕێگەی ئاکو سەیارەوە ڕابکێشیت.",
    },
  },
];
