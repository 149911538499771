import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../SellFind/table";

export default function SellFindModel() {
  const { t, i18n } = useTranslation();

  return (
    <div
      class="modal fade"
      id="exampleModalFindVin"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered find_vin">
        <div class="modal-content">
          <button
            type="button"
            class="btnclose"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src="../assets/images/close_img.png" align="img" />
          </button>
          <div class="modal-body">
            <div class="find_vin Sellfind_vin">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("How to Find VIN?")}
              </h5>
              <div class="w-100 text-center">
                <img
                  class="singpro"
                  src="../assets/images/singpro.png"
                  alt="img"
                />
              </div>
              <ul>
                <li>{t("Behind the windshield from the driver’s side")}</li>
                <li>{t("On the driver’s door frame")}</li>
              </ul>
              <div className="w-100">

              <img src="../assets/images/find_vin.png" className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
