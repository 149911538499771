import React from "react";
import Skeletons from "../Skeletons";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ImageBox = ({
  value,
  style,
  path,
  onClick,
  singleImageStyle,
  imageHeight,
  imageClasses,
  titleStyles,
  inlineStyles,
  loading,
}) => {
  
  return (
    <div className={style} style={{ inlineStyles }} onClick={onClick}>
      {loading == true ? (
        <Skeletons forPage={"brand-icons"} />
      ) : (
        <div class="set" style={singleImageStyle}>
          <LazyLoadImage
            src={path}
            alt={`Image`}
            effect="opacity" // Optional blur effect while loading
          />
          {/* <img
            src={path}
            className={imageClasses}
            alt="img"
            style={{ objectFit: "contain" }}
          /> */}
          <h6
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              ...titleStyles,
              lineHeight: "unset",
              marginTop: "-2px",
            }}
          >
            {value}
          </h6>
        </div>
      )}
    </div>
  );
};
export default ImageBox;
